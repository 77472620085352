


import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Carousel } from "react-bootstrap";
import "./Home.css";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    const menuItems = document.querySelectorAll(".navbar-nav li a");

    menuItems.forEach((item) => {
      item.addEventListener("click", function () {
        menuItems.forEach((i) => {
          i.parentNode.classList.remove("active");
        });
        this.parentNode.classList.add("active");
      });
    });
  }, []);

  const [interval, setInterval] = useState(2000); // Set the interval for auto-sliding
  const [pause, setPause] = useState(false);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-10" id="Logo">
            <div className="row" >
              <div className="slider_content_back">
               
                  

                  <div className="carousel-inner" role="listbox">
                  {/* <video width="100%" height="100%" controls controlsList="nodownload noremoteplayback" autoPlay muted>
  <source src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/video-gallery/Vanadarshini.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video> */}

<div className="carousel-inner" role="listbox">
<iframe width="930" height="372" src="https://www.youtube.com/embed/TJHKiS87pj4" title="Vanadarshini Forest Department, Kapila Theertham, Tirupati" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>


                    
                  

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="clearfix"></div> */}
        <br />
      </div>
    </div>
  );
};

export default Home;
