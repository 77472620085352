// import React, { useState } from "react";
// import Navbar from "./Navbar";
// import Header from "./Header";
// import DateTab from "./DateTab";
// import MonthTab from "./MonthTab";
// import "./Accomodation.css";

// const BookedReports = () => {
//   const [selectedLocation, setSelectedLocation] = useState("1"); // Default to Talakona

//   const handleLocationChange = (event) => {
//     setSelectedLocation(event.target.value);
//   };

//   return (
//     <div>
//       <Header />
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-2"></div>
//           <div className="col-lg-10 content_back">
//             <iframe
//               title="VANADARSHINI"
//               className="reports-bi"
//               src="https://app.powerbi.com/view?r=eyJrIjoiYWZiMGUxZmItYzk2YS00MGM1LTliOTMtY2FiNTMwNTcwOWM3IiwidCI6IjM5MmFlNDIzLWFiZTktNDIxOS1iMTU5LTBhZTc3YWMxOWZjYSJ9"
//               frameborder="0"
//               allowFullScreen="true"
//             ></iframe>{" "}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BookedReports;

// import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import moment from "moment";
// import Header from "./Header";
// import { saveAs } from "file-saver";
// import * as XLSX from "xlsx";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { useNavigate } from "react-router-dom";
// import "./Accomodation.css";

// const host = "https://api.vanadarshani.com";
// // const host = "http://localhost:5000";

// const BookedReports = () => {
//   const navigate = useNavigate();
//   const [subTotalSum, setSubTotalSum] = useState(0);
//   const [selectedFilter, setSelectedFilter] = useState("bookingDate");
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [checkInStartDate, setCheckInStartDate] = useState(null);
//   const [checkInEndDate, setCheckInEndDate] = useState(null);
//   const [bookings, setBookings] = useState([]);

//   useEffect(() => {
//     if (
//       !localStorage.getItem("access_token") ||
//       localStorage.getItem("access_token") === ""
//     ) {
//       navigate("/Home", "_self");
//     }
//     if (localStorage.getItem("userRole") != 1) {
//       navigate("/Home", "_self");
//     }

//     fetch(`${host}/sum-subtotal`)
//       .then((response) => response.json())
//       .then((data) => setSubTotalSum(data.total))
//       .catch((error) => console.error("Error fetching sum:", error));
//   }, []);

//   const handleFilter = () => {
//     const formattedStartDate = startDate
//       ? moment(startDate).format("YYYY-MM-DD")
//       : "";
//     const formattedEndDate = endDate
//       ? moment(endDate).format("YYYY-MM-DD")
//       : "";
//     const formattedCheckInStartDate = checkInStartDate
//       ? moment(checkInStartDate).format("YYYY-MM-DD")
//       : "";
//     const formattedCheckInEndDate = checkInEndDate
//       ? moment(checkInEndDate).format("YYYY-MM-DD")
//       : "";

//     let url = `${host}/api/bookings`;
//     if (selectedFilter === "bookingDate" && startDate && endDate) {
//       url += `?startBookingDate=${formattedStartDate}&endBookingDate=${formattedEndDate}`;
//     } else if (
//       selectedFilter === "checkInDate" &&
//       checkInStartDate &&
//       checkInEndDate
//     ) {
//       url += `?startCheckInDate=${formattedCheckInStartDate}&endCheckOutDate=${formattedCheckInEndDate}`;
//     }

//     fetch(url)
//       .then((response) => response.json())
//       .then((data) => setBookings(data))
//       .catch((error) => console.error("Error fetching data:", error));
//   };

//   const calculateMonthlyTotals = () => {
//     const totals = {};
//     bookings.forEach((booking) => {
//       const month = moment(booking.checkin_date).format("YYYY-MM");
//       if (!totals[month]) {
//         totals[month] = {
//           room_amount: 0,
//           count: 0,
//         };
//       }
//       totals[month].room_amount += booking.room_amount;
//       totals[month].count++;
//     });
//     return totals;
//   };

//   const downloadExcel = () => {
//     const monthlyTotals = calculateMonthlyTotals();
//     const dataWithTotals = [];
//     let currentMonth = null;

//     bookings.forEach((booking) => {
//       const month = moment(booking.checkin_date).format("YYYY-MM");
//       if (currentMonth !== month) {
//         if (currentMonth && monthlyTotals[currentMonth]) {
//           dataWithTotals.push({
//             order_id: "",
//             customer_name: "",
//             room_name: "",
//             checkin_date: "",
//             room_amount: monthlyTotals[currentMonth].room_amount,
//             // extra_persons: "",
//             // sub_total: "",
//             // status: "",
//             // is_blocked: "",
//             address: "",
//             pan_number: "",
//             aadhaar_number: "",
//             booking_time: "",
//             payment_mode: "",
//             razorpay_order_id: "",
//             razorpay_payment_id: "",
//           });
//         }
//         currentMonth = month;
//       }
//       dataWithTotals.push({
//         order_id: booking.order_id,
//         customer_name: booking.customer_name,
//         room_name: booking.room_name,
//         checkin_date: new Date(booking.checkin_date).toLocaleDateString(),
//         room_amount: booking.room_amount,
//         // extra_persons: booking.extra_persons,
//         // sub_total: booking.sub_total,
//         // status: booking.status,
//         // is_blocked: booking.is_blocked,
//         address: booking.address,
//         pan_number: booking.pan_number,
//         aadhaar_number: booking.aadhaar_number,
//         booking_time: new Date(booking.booking_time).toLocaleString(),
//         payment_mode: booking.payment_mode,
//         razorpay_order_id: booking.razorpay_order_id,
//         razorpay_payment_id: booking.razorpay_payment_id,
//       });
//     });

//     // Append the last month's total
//     if (currentMonth && monthlyTotals[currentMonth]) {
//       dataWithTotals.push({
//         order_id: "",
//         customer_name: "",
//         room_name: "",
//         checkin_date: "",
//         room_amount: monthlyTotals[currentMonth].room_amount,
//         extra_persons: "",
//         sub_total: "",
//         status: "",
//         is_blocked: "",
//         address: "",
//         pan_number: "",
//         aadhaar_number: "",
//         booking_time: "",
//         payment_mode: "",
//         razorpay_order_id: "",
//         razorpay_payment_id: "",
//       });
//     }
//     const worksheet = XLSX.utils.json_to_sheet(dataWithTotals);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Bookings");
//     const excelBuffer = XLSX.write(workbook, {
//       bookType: "xlsx",
//       type: "array",
//     });
//     const data = new Blob([excelBuffer], { type: "application/octet-stream" });
//     saveAs(data, "bookings.xlsx");
//   };

//   const downloadPDF = () => {
//     const doc = new jsPDF({
//       orientation: "landscape",
//       unit: "px",
//       format: "a4",
//     });

//     doc.setProperties({
//       title: "Vanadarshani Reports",
//     });

//     const headingText = "Vanadarshani Reports";
//     const fontSize = 18;
//     const pageWidth = doc.internal.pageSize.getWidth();
//     const textWidth =
//       (doc.getStringUnitWidth(headingText) * fontSize) /
//       doc.internal.scaleFactor;
//     const textX = (pageWidth - textWidth) / 2;
//     doc.setFontSize(fontSize);
//     doc.text(headingText, textX, 40);

//     const subtitleText = `Booked Reports`;
//     const subtitleFontSize = 16;
//     const subtitleWidth =
//       (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
//       doc.internal.scaleFactor;
//     const subtitleX = (pageWidth - subtitleWidth) / 2;
//     doc.setFontSize(subtitleFontSize);
//     doc.text(subtitleText, subtitleX, 60);

//     const tableColumn = [
//       "Order ID",
//       "Customer Name",
//       "Room Name",
//       "Check-in Date",
//       // "Room Amount",
//       ...(selectedFilter === "bookingDate" ? ["Room Amount"] : []),
//       // "Extra Persons",
//       // "Sub Total",
//       // "Status",
//       // "Is Blocked",
//       "Address",
//       "PAN Number",
//       "Aadhaar Number",
//       "Booking Time",
//       "Payment Mode",
//       "Razorpay Order ID",
//       "Razorpay Payment ID",
//     ];
//     const tableRows = [];

//     bookings.forEach((booking) => {
//       const bookingData = [
//         booking.order_id,
//         booking.customer_name,
//         booking.room_name,
//         new Date(booking.checkin_date).toLocaleDateString(),
//         // booking.room_amount,
//         ...(selectedFilter === "bookingDate" ? [booking.room_amount] : []),
//         // booking.extra_persons,
//         // booking.sub_total,
//         // booking.status,
//         // booking.is_blocked,
//         booking.address,
//         booking.pan_number,
//         booking.aadhaar_number,
//         new Date(booking.booking_time).toLocaleString(),
//         booking.payment_mode,
//         booking.razorpay_order_id,
//         booking.razorpay_payment_id,
//       ];
//       tableRows.push(bookingData);
//     });

//     const monthlyTotals = calculateMonthlyTotals();
//     let currentMonth = null;

//     bookings.forEach((booking) => {
//       const month = moment(booking.checkin_date).format("YYYY-MM");
//       if (currentMonth !== month) {
//         if (currentMonth && monthlyTotals[currentMonth]) {
//           const totalRow = [
//             "",
//             "",
//             "",
//             "",
//             monthlyTotals[currentMonth].room_amount,
//             // "",
//             // "",
//             // "",
//             // "",
//             "",
//             "",
//             "",
//             "",
//             "",
//             "",
//             "",
//           ];
//           tableRows.push(totalRow);
//         }
//         currentMonth = month;
//       }
//       const bookingData = [
//         booking.order_id,
//         booking.customer_name,
//         booking.room_name,
//         new Date(booking.checkin_date).toLocaleDateString(),
//         booking.room_amount,
//         // booking.extra_persons,
//         // booking.sub_total,
//         // booking.status,
//         // booking.is_blocked,
//         booking.address,
//         booking.pan_number,
//         booking.aadhaar_number,
//         new Date(booking.booking_time).toLocaleString(),
//         booking.payment_mode,
//         booking.razorpay_order_id,
//         booking.razorpay_payment_id,
//       ];
//       tableRows.push(bookingData);
//     });

//     // Append the last month's total
//     if (currentMonth && monthlyTotals[currentMonth]) {
//       const totalRow = [
//         "",
//         "",
//         "",
//         "",
//         monthlyTotals[currentMonth].room_amount,
//         // "",
//         // "",
//         // "",
//         // "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//       ];
//       tableRows.push(totalRow);
//     }

//     doc.autoTable(tableColumn, tableRows, { startY: 80 });
//     doc.save("bookingreports.pdf");
//   };

//   return (
//     <div>
//       <Header />
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-lg-2"></div>
//           <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back">
//             <div className="row">
//               <b className="text-center" style={{ fontSize: "20px" }}>
//                 Vanadarshani Booked Reports
//               </b>
//             </div>

//             <div className="filters">
//               <label htmlFor="filter">Filter By:</label>
//               <select
//                 id="filter"
//                 value={selectedFilter}
//                 onChange={(e) => setSelectedFilter(e.target.value)}
//               >
//                 <option value="bookingDate">Booking Date</option>
//                 <option value="checkInDate">Check In/out Dates</option>
//               </select>

//               {selectedFilter === "bookingDate" && (
//                 <>
//                   <label>From:</label>
//                   <DatePicker
//                     selected={startDate}
//                     onChange={(date) => setStartDate(date)}
//                     selectsStart
//                     startDate={startDate}
//                     endDate={endDate}
//                     dateFormat="yyyy-MM-dd"
//                   />
//                   <label>To:</label>
//                   <DatePicker
//                     selected={endDate}
//                     onChange={(date) => setEndDate(date)}
//                     selectsEnd
//                     startDate={startDate}
//                     endDate={endDate}
//                     dateFormat="yyyy-MM-dd"
//                   />
//                 </>
//               )}

//               {selectedFilter === "checkInDate" && (
//                 <>
//                   <label>From:</label>
//                   <DatePicker
//                     selected={checkInStartDate}
//                     onChange={(date) => setCheckInStartDate(date)}
//                     selectsStart
//                     startDate={checkInStartDate}
//                     endDate={checkInEndDate}
//                     dateFormat="yyyy-MM-dd"
//                   />
//                   <label>To:</label>
//                   <DatePicker
//                     selected={checkInEndDate}
//                     onChange={(date) => setCheckInEndDate(date)}
//                     selectsEnd
//                     startDate={checkInStartDate}
//                     endDate={checkInEndDate}
//                     dateFormat="yyyy-MM-dd"
//                   />
//                 </>
//               )}
//               <button
//                 type="button"
//                 className="btn-primary"
//                 onClick={handleFilter}
//                 style={{ borderRadius: "20px" }}
//               >
//                 Submit
//               </button>
//             </div>

//             {bookings.length > 0 ? (
//               <>
//                 <div className="download-buttons">
//                   <button
//                     style={{ borderRadius: "20px" }}
//                     className="btn-primary"
//                     onClick={downloadExcel}
//                   >
//                     Download Excel
//                   </button>
//                   <button
//                     style={{ borderRadius: "20px" }}
//                     className="btn-primary"
//                     onClick={downloadPDF}
//                   >
//                     Download PDF
//                   </button>
//                 </div>
//                 <table className="table table-responsive">
//                   <thead>
//                     <tr>
//                       <th>Order ID</th>
//                       <th>Customer Name</th>
//                       <th>Room Name</th>
//                       <th>Check-in Date</th>
//                       {/* <th>Room Amount</th> */}
//                       {selectedFilter === "bookingDate" && <th>Room Amount</th>}
//                       {/* <th>Extra Persons</th>
//                       <th>Sub Total</th>
//                       <th>Status</th>
//                       <th>Is Blocked</th> */}
//                       <th>Address</th>
//                       <th>PAN Number</th>
//                       <th>Aadhaar Number</th>
//                       <th>Booking Time</th>
//                       <th>Payment Mode</th>
//                       <th>Razorpay Order ID</th>
//                       <th>Razorpay Payment ID</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {bookings.map((booking) => (
//                       <tr key={booking.order_id}>
//                         <td>{booking.order_id}</td>
//                         <td>{booking.customer_name}</td>
//                         <td>{booking.room_name}</td>
//                         <td>
//                           {new Date(booking.checkin_date).toLocaleDateString()}
//                         </td>
//                         {/* <td>{booking.room_amount}</td> */}
//                         {selectedFilter === "bookingDate" && (
//                           <td>{booking.room_amount}</td>
//                         )}
//                         {/* <td>{booking.extra_persons}</td>
//                         <td>{booking.sub_total}</td>
//                         <td>{booking.status}</td>
//                         <td>{booking.is_blocked}</td> */}
//                         <td>{booking.address}</td>
//                         <td>{booking.pan_number}</td>
//                         <td>{booking.aadhaar_number}</td>
//                         <td>
//                           {new Date(booking.booking_time).toLocaleString()}
//                         </td>
//                         <td>{booking.payment_mode}</td>
//                         <td>{booking.razorpay_order_id}</td>
//                         <td>{booking.razorpay_payment_id}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </>
//             ) : (
//               <p>No bookings found for the selected dates.</p>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BookedReports;

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Header from "./Header";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import "./Accomodation.css";

const host = "https://api.vanadarshani.com";
// const host = "http://localhost:5000";

const BookedReports = () => {
  const navigate = useNavigate();
  const [subTotalSum, setSubTotalSum] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("bookingDate");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [checkInStartDate, setCheckInStartDate] = useState(null);
  const [checkInEndDate, setCheckInEndDate] = useState(null);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    if (
      !localStorage.getItem("access_token") ||
      localStorage.getItem("access_token") === ""
    ) {
      navigate("/Home", "_self");
    }
    if (localStorage.getItem("userRole") != 1) {
      navigate("/Home", "_self");
    }

    fetch(`${host}/sum-subtotal`)
      .then((response) => response.json())
      .then((data) => setSubTotalSum(data.total))
      .catch((error) => console.error("Error fetching sum:", error));
  }, []);

  const handleFilter = () => {
    const formattedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : "";
    const formattedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : "";
    const formattedCheckInStartDate = checkInStartDate
      ? moment(checkInStartDate).format("YYYY-MM-DD")
      : "";
    const formattedCheckInEndDate = checkInEndDate
      ? moment(checkInEndDate).format("YYYY-MM-DD")
      : "";

    let url = `${host}/api/bookings`;
    if (selectedFilter === "bookingDate" && startDate && endDate) {
      url += `?startBookingDate=${formattedStartDate}&endBookingDate=${formattedEndDate}`;
    } else if (
      selectedFilter === "checkInDate" &&
      checkInStartDate &&
      checkInEndDate
    ) {
      url += `?startCheckInDate=${formattedCheckInStartDate}&endCheckOutDate=${formattedCheckInEndDate}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => setBookings(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  // const calculateMonthlyTotals = () => {
  //   const totals = {};
  //   bookings.forEach((booking) => {
  //     const month = moment(booking.checkin_date).format("YYYY-MM");
  //     if (!totals[month]) {
  //       totals[month] = {
  //         room_amount: 0,
  //         count: 0,
  //       };
  //     }
  //     totals[month].room_amount += booking.room_amount;
  //     totals[month].count++;
  //   });

  //   return totals;
  // };
  const calculateMonthlyTotals = () => {
    const totals = {};
    bookings.forEach((booking) => {
      const month = moment(booking.booking_time).format("YYYY-MM");
      if (!totals[month]) {
        totals[month] = {
          room_amount: 0,
          count: 0,
        };
      }
      totals[month].room_amount += booking.room_amount;
      totals[month].count++;
    });

    return totals;
  };

  // const downloadExcel = () => {
  //   const monthlyTotals = calculateMonthlyTotals();
  //   const dataWithTotals = [];
  //   let currentMonth = null;

  //   bookings.forEach((booking) => {
  //     const month = moment(booking.checkin_date).format("YYYY-MM");
  //     if (currentMonth !== month) {
  //       if (currentMonth && monthlyTotals[currentMonth]) {
  //         dataWithTotals.push({
  //           order_id: "",
  //           customer_name: "",
  //           room_name: "",
  //           checkin_date: "",
  //           room_amount: monthlyTotals[currentMonth].room_amount,
  //           // extra_persons: "",
  //           // sub_total: "",
  //           // status: "",
  //           // is_blocked: "",
  //           address: "",
  //           pan_number: "",
  //           aadhaar_number: "",
  //           booking_time: "",
  //           payment_mode: "",
  //           razorpay_order_id: "",
  //           razorpay_payment_id: "",
  //         });
  //       }
  //       currentMonth = month;
  //     }
  //     dataWithTotals.push({
  //       order_id: booking.order_id,
  //       customer_name: booking.customer_name,
  //       room_name: booking.room_name,
  //       checkin_date: new Date(booking.checkin_date).toLocaleDateString(),
  //       room_amount: booking.room_amount,
  //       // extra_persons: booking.extra_persons,
  //       // sub_total: booking.sub_total,
  //       // status: booking.status,
  //       // is_blocked: booking.is_blocked,
  //       address: booking.address,
  //       pan_number: booking.pan_number,
  //       aadhaar_number: booking.aadhaar_number,
  //       booking_time: new Date(booking.booking_time).toLocaleString(),
  //       payment_mode: booking.payment_mode,
  //       razorpay_order_id: booking.razorpay_order_id,
  //       razorpay_payment_id: booking.razorpay_payment_id,
  //     });
  //   });

  //   // Append the last month's total
  //   if (currentMonth && monthlyTotals[currentMonth]) {
  //     dataWithTotals.push({
  //       order_id: "",
  //       customer_name: "",
  //       room_name: "",
  //       checkin_date: "",
  //       room_amount: monthlyTotals[currentMonth].room_amount,
  //       // extra_persons: "",
  //       // sub_total: "",
  //       // status: "",
  //       // is_blocked: "",
  //       address: "",
  //       pan_number: "",
  //       aadhaar_number: "",
  //       booking_time: "",
  //       payment_mode: "",
  //       razorpay_order_id: "",
  //       razorpay_payment_id: "",
  //     });
  //   }

  //   const worksheet = XLSX.utils.json_to_sheet(dataWithTotals);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Bookings");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  //   saveAs(data, "bookings.xlsx");
  // };

  // const downloadPDF = () => {
  //   const doc = new jsPDF({
  //     orientation: "landscape",
  //     unit: "px",
  //     format: "a4",
  //   });

  //   doc.setProperties({
  //     title: "Vanadarshani Reports",
  //   });

  //   const headingText = "Vanadarshani Reports";
  //   const fontSize = 18;
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const textWidth =
  //     (doc.getStringUnitWidth(headingText) * fontSize) /
  //     doc.internal.scaleFactor;
  //   const textX = (pageWidth - textWidth) / 2;
  //   doc.setFontSize(fontSize);
  //   doc.text(headingText, textX, 40);

  //   const subtitleText = `Booked Reports`;
  //   const subtitleFontSize = 16;
  //   const subtitleWidth =
  //     (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
  //     doc.internal.scaleFactor;
  //   const subtitleX = (pageWidth - subtitleWidth) / 2;
  //   doc.setFontSize(subtitleFontSize);
  //   doc.text(subtitleText, subtitleX, 60);

  //   const tableColumn = [
  //     "Order ID",
  //     "Customer Name",
  //     "Room Name",
  //     "Check-in Date",
  //     "Room Amount",
  //     // "Extra Persons",
  //     // "Sub Total",
  //     // "Status",
  //     // "Is Blocked",
  //     "Address",
  //     "PAN Number",
  //     "Aadhaar Number",
  //     "Booking Time",
  //     "Payment Mode",
  //     "Razorpay Order ID",
  //     "Razorpay Payment ID",
  //   ];
  //   const tableRows = [];

  //   bookings.forEach((booking) => {
  //     const bookingData = [
  //       booking.order_id,
  //       booking.customer_name,
  //       booking.room_name,
  //       new Date(booking.checkin_date).toLocaleDateString(),
  //       booking.room_amount,
  //       // booking.extra_persons,
  //       // booking.sub_total,
  //       // booking.status,
  //       // booking.is_blocked,
  //       booking.address,
  //       booking.pan_number,
  //       booking.aadhaar_number,
  //       new Date(booking.booking_time).toLocaleString(),
  //       booking.payment_mode,
  //       booking.razorpay_order_id,
  //       booking.razorpay_payment_id,
  //     ];
  //     tableRows.push(bookingData);
  //   });

  //   const monthlyTotals = calculateMonthlyTotals();
  //   let currentMonth = null;

  //   bookings.forEach((booking) => {
  //     const month = moment(booking.checkin_date).format("YYYY-MM");
  //     if (currentMonth !== month) {
  //       if (currentMonth && monthlyTotals[currentMonth]) {
  //         const totalRow = [
  //           "",
  //           "",
  //           "",
  //           "",
  //           monthlyTotals[currentMonth].room_amount,
  //           // "",
  //           // "",
  //           // "",
  //           // "",
  //           "",
  //           "",
  //           "",
  //           "",
  //           "",
  //           "",
  //           "",
  //         ];
  //         tableRows.push(totalRow);
  //       }
  //       currentMonth = month;
  //     }
  //     const bookingData = [
  //       booking.order_id,
  //       booking.customer_name,
  //       booking.room_name,
  //       new Date(booking.checkin_date).toLocaleDateString(),
  //       booking.room_amount,
  //       // booking.extra_persons,
  //       // booking.sub_total,
  //       // booking.status,
  //       // booking.is_blocked,
  //       booking.address,
  //       booking.pan_number,
  //       booking.aadhaar_number,
  //       new Date(booking.booking_time).toLocaleString(),
  //       booking.payment_mode,
  //       booking.razorpay_order_id,
  //       booking.razorpay_payment_id,
  //     ];
  //     tableRows.push(bookingData);
  //   });

  //   // Append the last month's total
  //   if (currentMonth && monthlyTotals[currentMonth]) {
  //     const totalRow = [
  //       "",
  //       "",
  //       "",
  //       "",
  //       monthlyTotals[currentMonth].room_amount,
  //       // "",
  //       // "",
  //       // "",
  //       // "",
  //       "",
  //       "",
  //       "",
  //       "",
  //       "",
  //       "",
  //       "",
  //     ];
  //     tableRows.push(totalRow);
  //   }

  //   doc.autoTable(tableColumn, tableRows, { startY: 80 });
  //   doc.save("bookingreports.pdf");
  // };
  // const downloadExcel = () => {
  //   const monthlyTotals = calculateMonthlyTotals();
  //   const dataWithTotals = [];
  //   let currentMonth = null;

  //   bookings.forEach((booking) => {
  //     const month = moment(booking.booking_time).format("YYYY-MM");
  //     if (currentMonth !== month) {
  //       if (currentMonth && monthlyTotals[currentMonth]) {
  //         dataWithTotals.push({
  //           order_id: "",
  //           customer_name: "",
  //           room_name: "",
  //           checkin_date: "",
  //           // Conditionally add "Room Amount" based on selectedFilter
  //           room_amount:
  //             selectedFilter === "bookingDate"
  //               ? monthlyTotals[currentMonth].room_amount
  //               : "", // Empty string if not bookingDate
  //           address: "",
  //           pan_number: "",
  //           aadhaar_number: "",
  //           booking_time: "",
  //           payment_mode: "",
  //           razorpay_order_id: "",
  //           razorpay_payment_id: "",
  //         });
  //       }
  //       currentMonth = month;
  //     }
  //     dataWithTotals.push({
  //       order_id: booking.order_id,
  //       customer_name: booking.customer_name,
  //       room_name: booking.room_name,
  //       checkin_date: new Date(booking.checkin_date).toLocaleDateString(),
  //       // Conditionally add "Room Amount" based on selectedFilter
  //       room_amount:
  //         selectedFilter === "bookingDate" ? booking.room_amount : "", // Empty string if not bookingDate
  //       address: booking.address,
  //       pan_number: booking.pan_number,
  //       aadhaar_number: booking.aadhaar_number,
  //       booking_time: new Date(booking.booking_time).toLocaleString(),
  //       payment_mode: booking.payment_mode,
  //       razorpay_order_id: booking.razorpay_order_id,
  //       razorpay_payment_id: booking.razorpay_payment_id,
  //     });
  //   });

  //   // Append the last month's total
  //   if (currentMonth && monthlyTotals[currentMonth]) {
  //     dataWithTotals.push({
  //       order_id: "",
  //       customer_name: "",
  //       room_name: "",
  //       checkin_date: "",
  //       // Conditionally add "Room Amount" based on selectedFilter
  //       room_amount:
  //         selectedFilter === "bookingDate"
  //           ? monthlyTotals[currentMonth].room_amount
  //           : "", // Empty string if not bookingDate
  //       address: "",
  //       pan_number: "",
  //       aadhaar_number: "",
  //       booking_time: "",
  //       payment_mode: "",
  //       razorpay_order_id: "",
  //       razorpay_payment_id: "",
  //     });
  //   }

  //   const worksheet = XLSX.utils.json_to_sheet(dataWithTotals);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Bookings");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  //   saveAs(data, "bookings.xlsx");
  // };

  const downloadExcel = () => {
    const monthlyTotals = calculateMonthlyTotals();
    const dataWithTotals = [];
    let currentMonth = null;

    bookings.forEach((booking) => {
      const month = moment(booking.checkin_date).format("YYYY-MM");
      if (currentMonth !== month) {
        if (currentMonth && monthlyTotals[currentMonth]) {
          if (selectedFilter === "bookingDate") {
            dataWithTotals.push({
              order_id: "",
              customer_name: "",
              room_name: "",
              checkin_date: "",
              room_amount: monthlyTotals[currentMonth].room_amount,
              address: "",
              pan_number: "",
              aadhaar_number: "",
              booking_time: "",
              payment_mode: "",
              razorpay_order_id: "",
              razorpay_payment_id: "",
            });
          }
        }
        currentMonth = month;
      }
      if (selectedFilter === "bookingDate") {
        dataWithTotals.push({
          order_id: booking.order_id,
          customer_name: booking.customer_name,
          room_name: booking.room_name,
          checkin_date: new Date(booking.checkin_date).toLocaleDateString(),
          room_amount: booking.room_amount,
          address: booking.address,
          pan_number: booking.pan_number,
          aadhaar_number: booking.aadhaar_number,
          booking_time: new Date(booking.booking_time).toLocaleString(),
          payment_mode: booking.payment_mode,
          razorpay_order_id: booking.razorpay_order_id,
          razorpay_payment_id: booking.razorpay_payment_id,
        });
      } else {
        dataWithTotals.push({
          order_id: booking.order_id,
          customer_name: booking.customer_name,
          room_name: booking.room_name,
          checkin_date: new Date(booking.checkin_date).toLocaleDateString(),
          address: booking.address,
          pan_number: booking.pan_number,
          aadhaar_number: booking.aadhaar_number,
          booking_time: new Date(booking.booking_time).toLocaleString(),
          payment_mode: booking.payment_mode,
          razorpay_order_id: booking.razorpay_order_id,
          razorpay_payment_id: booking.razorpay_payment_id,
        });
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(dataWithTotals);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Bookings");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "bookings.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: "a4",
    });

    doc.setProperties({
      title: "Vanadarshani Reports",
    });

    const headingText = "Vanadarshani Reports";
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth =
      (doc.getStringUnitWidth(headingText) * fontSize) /
      doc.internal.scaleFactor;
    const textX = (pageWidth - textWidth) / 2;
    doc.setFontSize(fontSize);
    doc.text(headingText, textX, 40);

    const subtitleText = `Booked Reports`;
    const subtitleFontSize = 16;
    const subtitleWidth =
      (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
      doc.internal.scaleFactor;
    const subtitleX = (pageWidth - subtitleWidth) / 2;
    doc.setFontSize(subtitleFontSize);
    doc.text(subtitleText, subtitleX, 60);

    const tableColumn = [
      "Order ID",
      "Customer Name",
      "Room Name",
      "Check-in Date",
      // Conditionally add "Room Amount" based on selectedFilter
      selectedFilter === "bookingDate" ? "Room Amount" : "",
      // "Extra Persons",
      // "Sub Total",
      // "Status",
      // "Is Blocked",
      "Address",
      "PAN Number",
      "Aadhaar Number",
      "Booking Time",
      "Payment Mode",
      "Razorpay Order ID",
      "Razorpay Payment ID",
    ];
    const tableRows = [];

    const monthlyTotals = calculateMonthlyTotals();
    let currentMonth = null;

    bookings.forEach((booking) => {
      const month = moment(booking.booking_time).format("YYYY-MM");
      if (currentMonth !== month) {
        if (currentMonth && monthlyTotals[currentMonth]) {
          const totalRow = [
            "",
            "",
            "",
            "",
            monthlyTotals[currentMonth].room_amount,
            // "",
            // "",
            // "",
            // "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ];
          tableRows.push(totalRow);
        }
        currentMonth = month;
      }
      const bookingData = [
        booking.order_id,
        booking.customer_name,
        booking.room_name,
        new Date(booking.checkin_date).toLocaleDateString(),
        // Conditionally add room_amount based on selectedFilter
        selectedFilter === "bookingDate" ? booking.room_amount : "",
        // booking.extra_persons,
        // booking.sub_total,
        // booking.status,
        // booking.is_blocked,
        booking.address,
        booking.pan_number,
        booking.aadhaar_number,
        new Date(booking.booking_time).toLocaleString(),
        booking.payment_mode,
        booking.razorpay_order_id,
        booking.razorpay_payment_id,
      ];
      tableRows.push(bookingData);
    });

    // Append the last month's total
    if (currentMonth && monthlyTotals[currentMonth]) {
      const totalRow = [
        "",
        "",
        "",
        "",
        monthlyTotals[currentMonth].room_amount,
        // "",
        // "",
        // "",
        // "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      tableRows.push(totalRow);
    }

    doc.autoTable(tableColumn, tableRows, { startY: 80 });
    doc.save("bookingreports.pdf");
  };

  // const downloadPDF = () => {
  //   const doc = new jsPDF({
  //     orientation: "landscape",
  //     unit: "px",
  //     format: "a4",
  //   });

  //   doc.setProperties({
  //     title: "Vanadarshani Reports",
  //   });

  //   const headingText = "Vanadarshani Reports";
  //   const fontSize = 18;
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const textWidth =
  //     (doc.getStringUnitWidth(headingText) * fontSize) /
  //     doc.internal.scaleFactor;
  //   const textX = (pageWidth - textWidth) / 2;
  //   doc.setFontSize(fontSize);
  //   doc.text(headingText, textX, 40);

  //   const subtitleText = `Booked Reports`;
  //   const subtitleFontSize = 16;
  //   const subtitleWidth =
  //     (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
  //     doc.internal.scaleFactor;
  //   const subtitleX = (pageWidth - subtitleWidth) / 2;
  //   doc.setFontSize(subtitleFontSize);
  //   doc.text(subtitleText, subtitleX, 60);

  //   const tableColumn = [
  //     "Order ID",
  //     "Customer Name",
  //     "Room Name",
  //     "Check-in Date",
  //     "Room Amount",
  //     // "Extra Persons",
  //     // "Sub Total",
  //     // "Status",
  //     // "Is Blocked",
  //     "Address",
  //     "PAN Number",
  //     "Aadhaar Number",
  //     "Booking Time",
  //     "Payment Mode",
  //     "Razorpay Order ID",
  //     "Razorpay Payment ID",
  //   ];
  //   const tableRows = [];

  //   const monthlyTotals = calculateMonthlyTotals();
  //   let currentMonth = null;

  //   bookings.forEach((booking) => {
  //     const month = moment(booking.booking_time).format("YYYY-MM");
  //     if (currentMonth !== month) {
  //       if (currentMonth && monthlyTotals[currentMonth]) {
  //         const totalRow = [
  //           "",
  //           "",
  //           "",
  //           "",
  //           monthlyTotals[currentMonth].room_amount,
  //           // "",
  //           // "",
  //           // "",
  //           // "",
  //           "",
  //           "",
  //           "",
  //           "",
  //           "",
  //           "",
  //           "",
  //         ];
  //         tableRows.push(totalRow);
  //       }
  //       currentMonth = month;
  //     }
  //     const bookingData = [
  //       booking.order_id,
  //       booking.customer_name,
  //       booking.room_name,
  //       new Date(booking.checkin_date).toLocaleDateString(),
  //       booking.room_amount,
  //       // booking.extra_persons,
  //       // booking.sub_total,
  //       // booking.status,
  //       // booking.is_blocked,
  //       booking.address,
  //       booking.pan_number,
  //       booking.aadhaar_number,
  //       new Date(booking.booking_time).toLocaleString(),
  //       booking.payment_mode,
  //       booking.razorpay_order_id,
  //       booking.razorpay_payment_id,
  //     ];
  //     tableRows.push(bookingData);
  //   });

  //   // Append the last month's total
  //   if (currentMonth && monthlyTotals[currentMonth]) {
  //     const totalRow = [
  //       "",
  //       "",
  //       "",
  //       "",
  //       monthlyTotals[currentMonth].room_amount,
  //       // "",
  //       // "",
  //       // "",
  //       // "",
  //       "",
  //       "",
  //       "",
  //       "",
  //       "",
  //       "",
  //       "",
  //     ];
  //     tableRows.push(totalRow);
  //   }

  //   doc.autoTable(tableColumn, tableRows, { startY: 80 });
  //   doc.save("bookingreports.pdf");
  // };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back">
            <div className="row">
              <b className="text-center" style={{ fontSize: "20px" }}>
                Vanadarshani Booked Reports
              </b>
            </div>

            <div className="filters">
              <label htmlFor="filter">Filter By:</label>
              <select
                id="filter"
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
              >
                <option value="bookingDate">Booking Date</option>
                <option value="checkInDate">Check In/out Dates</option>
              </select>

              {selectedFilter === "bookingDate" && (
                <>
                  <label>From:</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy-MM-dd"
                  />
                  <label>To:</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy-MM-dd"
                  />
                </>
              )}

              {selectedFilter === "checkInDate" && (
                <>
                  <label>From:</label>
                  <DatePicker
                    selected={checkInStartDate}
                    onChange={(date) => setCheckInStartDate(date)}
                    selectsStart
                    startDate={checkInStartDate}
                    endDate={checkInEndDate}
                    dateFormat="yyyy-MM-dd"
                  />
                  <label>To:</label>
                  <DatePicker
                    selected={checkInEndDate}
                    onChange={(date) => setCheckInEndDate(date)}
                    selectsEnd
                    startDate={checkInStartDate}
                    endDate={checkInEndDate}
                    dateFormat="yyyy-MM-dd"
                  />
                </>
              )}
              <button
                type="button"
                className="btn-primary"
                onClick={handleFilter}
                style={{ borderRadius: "20px" }}
              >
                Submit
              </button>
            </div>

            {bookings.length > 0 ? (
              <>
                <div className="download-buttons">
                  <button
                    style={{ borderRadius: "20px" }}
                    className="btn-primary"
                    onClick={downloadExcel}
                  >
                    Download Excel
                  </button>
                  <button
                    style={{ borderRadius: "20px" }}
                    className="btn-primary"
                    onClick={downloadPDF}
                  >
                    Download PDF
                  </button>
                </div>
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Customer Name</th>
                      <th>Room Name</th>
                      <th>Check-in Date</th>
                      {selectedFilter === "bookingDate" && <th>Room Amount</th>}
                      {/* <th>Extra Persons</th>
                      <th>Sub Total</th>
                      <th>Status</th>
                      <th>Is Blocked</th> */}
                      <th>Address</th>
                      <th>PAN Number</th>
                      <th>Aadhaar Number</th>
                      <th>Booking Time</th>
                      <th>Payment Mode</th>
                      <th>Razorpay Order ID</th>
                      <th>Razorpay Payment ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.map((booking) => (
                      <tr key={booking.order_id}>
                        <td>{booking.order_id}</td>
                        <td>{booking.customer_name}</td>
                        <td>{booking.room_name}</td>
                        <td>
                          {new Date(booking.checkin_date).toLocaleDateString()}
                        </td>
                        {selectedFilter === "bookingDate" && (
                          <td>{booking.room_amount}</td>
                        )}
                        {/* <td>{booking.extra_persons}</td>
                        <td>{booking.sub_total}</td>
                        <td>{booking.status}</td>
                        <td>{booking.is_blocked}</td> */}
                        <td>{booking.address}</td>
                        <td>{booking.pan_number}</td>
                        <td>{booking.aadhaar_number}</td>
                        <td>
                          {new Date(booking.booking_time).toLocaleString()}
                        </td>
                        <td>{booking.payment_mode}</td>
                        <td>{booking.razorpay_order_id}</td>
                        <td>{booking.razorpay_payment_id}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>No bookings found for the selected dates.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookedReports;
