import React from "react";
import Header from "./Header";

const PrivacyPolicy = () => {
  return (
    <div classname="privacy-bg-color">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div className="col-lg-1O col-md-10 col-sm-10 col-xs-10">
            <div className="privacy-policy">
              <h2>
                <b>Privacy Policy</b>
              </h2>
              <br />
              <ul>
                <li>
                  Vanadarshani is committed to keeping our customers informed
                  about how we use the information we gather from you through
                  the use of each of our website(s) located at, or linked to,
                  the URLs www.vanadarshani.in Please read this privacy policy
                  carefully to understand how we will use and protect your
                  personal information. We will not share your personal
                  information with anyone except as described in this Policy.
                  Changes to this Policy will be announced on each of our Sites
                  at least 10 days in advance, so please check back
                  periodically. By using any of the Sites or obtaining any
                  product or service through any of the Sites, you agree to the
                  collection and use of information as set forth in this Policy.
                  If you do not agree to this Policy, please do not use any of
                  the Sites. This Policy will explain the following regarding
                  use of each of our Sites:
                  <br />
                  <br />
                  <br />
                  <b>
                    1. What information do we collect from you or about you?
                  </b>
                  <br />
                  In order to better provide you with numerous products and
                  services, each of our Sites collects two types of information
                  about its visitors Personally Identifiable Information and Non
                  Personally Identifiable Information. Our primary goal in
                  collecting information from you is to provide you with a
                  smooth, efficient, and customized experience while using our
                  Sites. Personally Identifiable Information: Personally
                  Identifiable Information refers to information that lets us
                  know the specifics of who you are and can be used to identify,
                  contact or locate you. Personally Identifiable Information is
                  requested when you register with us, order a product or
                  service, fill out surveys, participate in contests or
                  sweepstakes, correspond with us, or otherwise volunteer
                  information, for instance, through the use of one of our
                  greeting, hint or reminder services or to request a catalog.
                  Personally Identifiable Information might include your name,
                  mailing address, telephone number, mobile phone number, email
                  address, and other identification and contact information. If
                  you are using a greeting, email a friend, hint or reminder
                  service on one of our Sites, we may ask you to provide the
                  name, mailing address, email address, telephone number, mobile
                  phone number, anniversary date or other personal annuity date
                  of you, a friend, , family members or other third parties, and
                  certain holiday information that may reveal one’s religious
                  background, so that we may send them or you an email, hint or
                  reminder about an event that could be made more special with
                  the gift of our products. We may also collect certain
                  financial information, like your credit card /debit card
                  number With this information, we can fill your service and
                  product orders and better provide you with personalized
                  services.
                  <br /> Non Personally Identifiable Information:"Non Personally
                  Identifiable Information" refers to information that does not
                  identify a specific individual by itself or in combination
                  with other information. We may gather certain information
                  about you such as age, date of birth, gender, anniversary date
                  or other personal annuity date of you, a friend, a loved one,
                  family members or other third parties, certain holiday
                  information that may reveal one's religious background, as
                  well as consumer purchasing information based upon where you
                  shop on any one of our Sites. This information may be compiled
                  and analyzed on both a personal and an aggregated basis. This
                  information may include the website's Uniform Resource Locator
                  (URL) that you just came from, which URL you next go to, what
                  browser you are using, and your Internet Protocol (IP)
                  address. A URL is the global address of documents and other
                  resources on the Internet. An IP address is an identifier for
                  a computer or device on a Transmission Control
                  Protocol/Internet Protocol (TCP/IP) network, such as the
                  Internet. Networks use the TCP/IP protocol to route
                  information based on the IP address of the destination. In
                  other words, an IP address is a number that is automatically
                  assigned to your computer whenever you are surfing the
                  Internet, allowing Internet servers to locate and identify
                  your computer. Computers require IP addresses in order for
                  users to communicate on the Internet, browse and shop.
                  <br />
                  <br />
                  <br />
                  <b>
                    2. Where do we collect information from you and how do we
                    use it?
                  </b>
                  <br />
                  Our primary goal in collecting information is to provide our
                  visitors with a superior customized online experience. Among
                  other things, we use customer contact information to fill
                  bookings, contact prior booking holders to inform them of
                  their previous bookings, to trace false bookings, to remind
                  customers of events through our reminder service(s), to send
                  our customers friends greetings, emails or hints to send our
                  products through a greeting, email a friend or hint service,
                  and to send service emails, newsletters, and promotional
                  emails from us or third parties. We may perform statistical
                  analyses of aggregate or segmented customer behavior. We may
                  also use information to notify consumers about updates to our
                  Sites, for marketing purposes. In addition, we may seek to
                  update you or your recipients personal information in our
                  records through third party services that provide updated
                  names, emails, addresses, phone numbers, birth dates and other
                  information. We may then use this acquired information to
                  enhance our services or marketing efforts to you or your
                  recipients. Contests:We may from time to time conduct contests
                  or other promotions. Customers who choose to participate may
                  be asked to provide Personally Identifiable Information. That
                  information may then be used by us for such purposes as
                  notifying winners and fulfilling promotional obligations as
                  well as providing newsletters and promotional emails (as
                  described below). Newsletters & Promotional Emails: We may
                  offer free electronic newsletters and promotional emails for
                  products and/or services offered on any one or more of our
                  Sites, or from our third party partners, as a service to our
                  prior customers from any of our Sites. We may send you
                  newsletters and emails periodically listing current specials
                  and deals from us or through our third party service partners.
                  There may be opportunities to subscribe to an email list that
                  will send you information about the events on our Sites, but
                  we will only add you to the list with your consent. You may
                  opt out of receiving any such newsletters and promotional
                  emails as described below.
                  <br />
                  <br />
                  <br />
                  Other Information You Provide: If you contact us by telephone,
                  email or letter, we may keep a record of your contact
                  information, correspondence or comments. If you report a
                  problem with any one of our Sites, we may collect such
                  information in a file specific to you. If you provide us
                  feedback through any one of our Sites, by email or by calling
                  one of our customer service representatives, we may ask for
                  your name and email address in order to send you a reply. If
                  other users or third parties send us correspondence about your
                  activities or purchases on any one of our Sites, or if you are
                  the recipient of products sent by another customer, we may
                  keep the information in a file specific to you. If you are
                  using a greeting, hint or reminder service on one of our
                  Sites, we may ask you to provide the name, mailing address,
                  email address, telephone number, mobile phone number, age,
                  date of birth, anniversary date or other personal annuity date
                  of you, a friend, family members or other third parties, and
                  certain holiday information that may reveal one’s background,
                  so that we may send them or you a hint or reminder about an
                  event that could be made more special with the gift of our
                  products. We store this information for the purpose of sending
                  one or more emails in connection with such an event.
                  <br />
                  <br />
                  <br />
                  <b>3. Who is collecting your information?</b>
                  <br />
                  Except as otherwise expressly discussed in this Policy, we are
                  the only entity collecting your information.
                  <br />
                  <br />
                  <b>4. With whom do we share your information?</b>
                  <br />
                  Except as otherwise stated in this Policy, we do not sell,
                  trade or rent your Personally Identifiable Information
                  collected on our Sites to others.
                  <br />
                </li>
                <li>
                  Sehsachala Vanadarshani respects your privacy and committed to
                  protect your personal information that you share with us.
                </li>
                <li>
                  Sehsachala Vanadarshani will use your information internally
                  for office records purpose only, will not be shared to any
                  other purposes.
                </li>
                <li>
                  You can browse our website for information without giving any
                  of your information
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
