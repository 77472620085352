// // import React, { useEffect, useState } from "react";
// // import Header from "./Header";
// // import "./Accomodation.css";
// // import moment from "moment";
// // import "react-datepicker/dist/react-datepicker.css";
// // import { Navigate, useNavigate } from "react-router-dom";
// // import jsPDF from "jspdf";
// // import "jspdf-autotable";

// // const TalokonaTokenReports = () => {
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [recordsPerPage] = useState(20);

// //   const navigate = useNavigate();
// //   const [startDate, setStartDate] = useState(new Date());
// //   const [endDate, setEndDate] = useState(new Date());
// //   const [tokenData, setTokenData] = useState([]);

// //   // let host = "https://api.vanadarshani.com";
// //   let host = "http://localhost:5000";

// //   const getTokenReport = async (e) => {
// //     e.preventDefault();
// //     console.log(startDate, endDate, "checking values");

// //     let response = await fetch(host + "/getTalakonaTokenReports", {
// //       method: "POST",
// //       headers: {
// //         "Content-Type": "application/json",
// //       },
// //       body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
// //     }).then((response) =>
// //       response.json().then((res) => {
// //         setTokenData(res);
// //         console.log(res, tokenData, "token data checking");
// //       })
// //     );
// //   };
// //   const indexOfLastRecord = currentPage * recordsPerPage;
// //   const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
// //   const currentRecords = tokenData.slice(indexOfFirstRecord, indexOfLastRecord);
// //   const paginate = (pageNumber) => setCurrentPage(pageNumber);

// //   const generatePDF = async () => {
// //     console.log("pdf clicked");

// //     try {
// //       // Fetch all token data
// //       const response = await fetch(host + "/getTalakonaTokenReports", {
// //         method: "POST",
// //         headers: {
// //           "Content-Type": "application/json",
// //         },
// //         body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
// //       });
// //       const allTokenData = await response.json();

// //       // Initialize PDF document
// //       const doc = new jsPDF({
// //         orientation: "landscape",
// //         unit: "px",
// //         format: "a4",
// //       });

// //       // Set PDF properties including title
// //       doc.setProperties({
// //         title: "Talokona Token Report",
// //       });

// //       // Add heading with custom font size and centered horizontally
// //       const headingText = "Talokona Token Report";
// //       const fontSize = 20; // Change font size here
// //       const pageWidth = doc.internal.pageSize.getWidth();
// //       const textWidth =
// //         (doc.getStringUnitWidth(headingText) * fontSize) /
// //         doc.internal.scaleFactor;
// //       const textX = (pageWidth - textWidth) / 2;
// //       doc.setFontSize(fontSize);
// //       doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

// //       // Add table with all token data
// //       doc.autoTable({
// //         head: [
// //           [
// //             "Sr No",
// //             "Receipt Number",
// //             "Date",
// //             "Vehicle Number",
// //             "Name",
// //             "Number of Persons",
// //             "Phone",
// //             "City",
// //             "Two Wheelers",
// //             "Three Wheelers",
// //             "Four Wheelers",
// //             "Bus Truck",
// //             "Still Cameras",
// //             "Video Cameras",
// //             "Total",
// //           ],
// //         ],
// //         body: allTokenData.map((rm) => [
// //           rm.id,
// //           rm.receiptnumber,
// //           moment(rm.date).format("yyyy-MM-DD"),
// //           rm.vehicle_number,
// //           rm.name,
// //           rm.number_of_persons,
// //           rm.phone,
// //           rm.city,
// //           rm.two_wheelers,
// //           rm.three_wheelers,
// //           rm.four_wheelers,
// //           rm.bus_truck,
// //           rm.still_camers,
// //           rm.video_camers,
// //           rm.total,
// //         ]),
// //         startY: 30,
// //         styles: { halign: "center" },
// //       });

// //       // Save the PDF
// //       doc.save("talokona_token_report.pdf");
// //     } catch (error) {
// //       console.error("Error generating PDF:", error);
// //     }
// //   };

// //   return (
// //     <div>
// //       <Header />
// //       {/* <div className="container-fluid">
// //         <div className="col-lg-12 row">
// //           <div className="col-lg-12"></div>
// //           <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  content_back"> */}
// //       <div className="container">
// //         <div className="row">
// //           <div className="col-lg-2"></div>
// //           <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back">
// //             <div className="row">
// //               <b>Talakona Token Reports</b>
// //             </div>
// //             <br />
// //             <div className="row">
// //               <div style={{ width: "20%" }}>
// //                 <form className="form-group" onSubmit={getTokenReport}>
// //                   <div className="d-flex align-items-center">
// //                     <div className="col-lg-12 mx-2">
// //                       <label for="selectCheckin_Date">Start Date</label>
// //                       <input
// //                         type="date"
// //                         id="selectCheckin_Date"
// //                         onChange={(e) => setStartDate(e.target.value)}
// //                       />
// //                     </div>
// //                     <br />
// //                     <div className="col-lg-12 mx-2">
// //                       <label for="selectCheckout_Date">End Date</label>
// //                       <input
// //                         style={{ marginLeft: "10px" }}
// //                         type="date"
// //                         id="selectCheckout_Date"
// //                         onChange={(e) => setEndDate(e.target.value)}
// //                       />
// //                     </div>
// //                     <br />
// //                     <button
// //                       type="submit"
// //                       className="btn btn-primary"
// //                       style={{ marginLeft: "100px" }}
// //                     >
// //                       Submit
// //                     </button>
// //                     <button
// //                       type="button"
// //                       className="btn btn-primary mx-4"
// //                       style={{ width: "auto" }}
// //                       onClick={generatePDF}
// //                     >
// //                       Token Download
// //                     </button>
// //                   </div>
// //                 </form>
// //               </div>
// //             </div>
// //             <div className="row" style={{ overflowX: "auto" }}>
// //               <table className="table table-striped responsive-table">
// //                 <thead>
// //                   <tr className="data-row-head">
// //                     <th>Sr No</th>
// //                     <th>Receipt Number</th>
// //                     <th>Date</th>
// //                     <th>Vehicle Nnumber</th>
// //                     <th>Name</th>
// //                     <th>Number of Persons</th>
// //                     <th>Phone</th>
// //                     <th>City</th>
// //                     <th>Two Wheelers</th>
// //                     <th>Three Wheelers</th>
// //                     <th>Four Wheelers</th>
// //                     <th>Bus Truck</th>
// //                     <th>Still Camers</th>
// //                     <th>Video Camers</th>
// //                     <th>Total</th>
// //                   </tr>
// //                 </thead>
// //                 <tbody>
// //                   {currentRecords.map((rm, index) => (
// //                     <tr key={rm.id} className="data-row-data">
// //                       <td style={{ textAlign: "center" }}>{rm.id}</td>
// //                       <td style={{ textAlign: "center" }}>
// //                         {rm.receiptnumber}
// //                       </td>
// //                       <td style={{ textAlign: "center" }}>
// //                         {moment(rm.date).format("yyyy-MM-DD")}{" "}
// //                       </td>
// //                       <td style={{ textAlign: "center" }}>
// //                         {rm.vehicle_number}
// //                       </td>
// //                       <td style={{ textAlign: "center" }}>{rm.name}</td>
// //                       <td style={{ textAlign: "center" }}>
// //                         {rm.number_of_persons}
// //                       </td>
// //                       <td style={{ textAlign: "center" }}>{rm.phone}</td>
// //                       <td style={{ textAlign: "center" }}>{rm.city}</td>
// //                       <td style={{ textAlign: "center" }}>{rm.two_wheelers}</td>
// //                       <td style={{ textAlign: "center" }}>
// //                         {rm.three_wheelers}
// //                       </td>
// //                       <td style={{ textAlign: "center" }}>
// //                         {rm.four_wheelers}
// //                       </td>
// //                       <td style={{ textAlign: "center" }}>{rm.bus_truck}</td>
// //                       <td style={{ textAlign: "center" }}>{rm.still_camers}</td>
// //                       <td style={{ textAlign: "center" }}>{rm.video_camers}</td>
// //                       <td style={{ textAlign: "center" }}>{rm.total}</td>
// //                     </tr>
// //                   ))}
// //                 </tbody>
// //               </table>
// //             </div>
// //             <div className="d-flex justify-content-end">
// //               <ul className="pagination">
// //                 <li
// //                   className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
// //                 >
// //                   <button
// //                     onClick={() => paginate(currentPage - 1)}
// //                     className="page-link"
// //                   >
// //                     Previous
// //                   </button>
// //                 </li>
// //                 <span>{`Page ${currentPage} of ${Math.ceil(
// //                   tokenData.length / recordsPerPage
// //                 )}`}</span>
// //                 <li
// //                   className={`page-item ${
// //                     currentPage === Math.ceil(tokenData.length / recordsPerPage)
// //                       ? "disabled"
// //                       : ""
// //                   }`}
// //                 >
// //                   <button
// //                     onClick={() => paginate(currentPage + 1)}
// //                     className="page-link"
// //                   >
// //                     Next
// //                   </button>
// //                 </li>
// //               </ul>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default TalokonaTokenReports;

// import React, { useEffect, useState } from "react";
// import Header from "./Header";
// import "./Accomodation.css";
// import moment from "moment";
// import "react-datepicker/dist/react-datepicker.css";
// import { Navigate, useNavigate } from "react-router-dom";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import axios from "axios";

// const TalokonaTokenReports = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [recordsPerPage] = useState(20);
//   const [summaryData, setSummaryData] = useState([]);

//   const navigate = useNavigate();
//   const [startDate, setStartDate] = useState(new Date());
//   const [endDate, setEndDate] = useState(new Date());
//   const [tokenData, setTokenData] = useState([]);
//   const [table1, setTable1] = useState(false);
//   const [table2, setTable2] = useState(false);
//   const [table3, setTable3] = useState(false);
//   const [table4, setTable4] = useState(false);
//   const [totalCollection, setTotalCollection] = useState();
//   const [showSummaryButton1, setShowSummaryButton1] = useState(false);
//   const [showSummaryButton2, setShowSummaryButton2] = useState(false);

//   // let host = "https://api.vanadarshani.com";
//   let host = "http://localhost:5000";

//   const getTokenReport = async (e) => {
//     e.preventDefault();
//     console.log(startDate, endDate, "checking values");
//     setTable1(true);
//     setTable2(false);
//     let response = await fetch(host + "/getTalakonaTokenReports", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
//     }).then((response) =>
//       response.json().then((res) => {
//         setTokenData(res);
//         const totalCollection = res.reduce(
//           (sum, rm) => sum + Number(rm.total),
//           0
//         );
//         setTotalCollection(totalCollection);
//         console.log(res, tokenData, "token data checking");
//         console.log(totalCollection, "total collection amount");
//       })
//     );
//   };

//   const getTokenReport1 = async (e) => {
//     e.preventDefault();
//     console.log(startDate, endDate, "checking values");
//     setTable2(true);
//     setTable1(false);
//     let response = await fetch(host + "/getTalakonaTokenReports1", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
//     }).then((response) =>
//       response.json().then((res) => {
//         setTokenData(res);
//         const totalCollection = res.reduce(
//           (sum, rm) => sum + Number(rm.totalprice),
//           0
//         );
//         setTotalCollection(totalCollection);
//         console.log(res, "token data checking");
//         console.log(totalCollection, "total collection amount");
//       })
//     );
//   };

//   const handleSummaryGate1Click = async () => {
//     setTable1(false);
//     setTable2(false);
//     setTable3(true);
//     setTable4(false);
//     try {
//       const response = await fetch(host + "/getTalakonaTokenSummary1", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
//       });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       const summaryData1 = await response.json();
//       setSummaryData(summaryData1);
//       console.log(summaryData, "summary data for Gate No 1");
//     } catch (error) {
//       console.error("There was a problem with fetching summary data:", error);
//     }
//   };

//   // Function to handle summary button click for Gate No 2
//   const handleSummaryGate2Click = async () => {
//     setTable1(false);
//     setTable2(false);
//     setTable3(false);
//     setTable4(true);
//     try {
//       const response = await fetch(host + "/getTalakonaTokenSummary2", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
//       });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       const summaryData2 = await response.json();
//       setSummaryData(summaryData2);
//       console.log(summaryData, "summary data for Gate No 2");
//     } catch (error) {
//       console.error("There was a problem with fetching summary data:", error);
//     }
//   };

//   const indexOfLastRecord = currentPage * recordsPerPage;
//   const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
//   const currentRecords = tokenData.slice(indexOfFirstRecord, indexOfLastRecord);
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const generatePDF = async () => {
//     console.log("pdf clicked");

//     try {
//       // Fetch all token data
//       const response = await fetch(host + "/getTalakonaTokenReports", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
//       });
//       const allTokenData = await response.json();

//       // Calculate the total collection
//       const totalCollection = allTokenData.reduce(
//         (sum, rm) => sum + rm.total,
//         0
//       );

//       // Initialize PDF document
//       const doc = new jsPDF({
//         orientation: "landscape",
//         unit: "px",
//         format: "a4",
//       });

//       // Set PDF properties including title
//       doc.setProperties({
//         title: "Talokona Token Report(Gate No-1)",
//       });

//       // Add heading with custom font size and centered horizontally
//       const headingText = "Talokona Token Report(Gate No-1)";
//       const fontSize = 20; // Change font size here
//       const pageWidth = doc.internal.pageSize.getWidth();
//       const textWidth =
//         (doc.getStringUnitWidth(headingText) * fontSize) /
//         doc.internal.scaleFactor;
//       const textX = (pageWidth - textWidth) / 2;
//       doc.setFontSize(fontSize);
//       doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

//       // Add subtitle with total collection below the main title
//       const subtitleText = `Total Collection: ${totalCollection}`;
//       const subtitleFontSize = 16; // Font size for the subtitle
//       const subtitleWidth =
//         (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
//         doc.internal.scaleFactor;
//       const subtitleX = (pageWidth - subtitleWidth) / 2;
//       doc.setFontSize(subtitleFontSize);
//       doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

//       // Add table with all token data
//       doc.autoTable({
//         head: [
//           [
//             "S No",
//             "Ticket Number",
//             "Date",
//             "Vehicle Number",
//             "Name",
//             "Number of Persons",
//             "Phone",
//             "City",
//             "Two Wheelers",
//             "Three Wheelers",
//             "Four Wheelers",
//             "Bus Truck",
//             "Still Cameras",
//             "Video Cameras",
//             "Total",
//           ],
//         ],
//         body: allTokenData.map((rm) => [
//           rm.id,
//           rm.receiptnumber,
//           moment(rm.created_at).format("YYYY-MM-DD HH:mm:ss"),
//           rm.vehicle_number,
//           rm.name,
//           rm.number_of_persons,
//           rm.phone,
//           rm.city,
//           rm.two_wheelers,
//           rm.three_wheelers,
//           rm.four_wheelers,
//           rm.bus_truck,
//           rm.still_camers,
//           rm.video_camers,
//           rm.total,
//         ]),
//         startY: 50, // Adjust startY to provide space for the subtitle
//         styles: { halign: "center" },
//       });

//       // Save the PDF
//       doc.save("talokona_token_report_gate1.pdf");
//     } catch (error) {
//       console.error("Error generating PDF:", error);
//     }
//   };

//   const generatePDF1 = async () => {
//     console.log("pdf clicked");

//     try {
//       // Fetch all token data
//       const response = await fetch(host + "/getTalakonaTokenReports1", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
//       });
//       const allTokenData = await response.json();

//       // Calculate the total collection
//       const totalCollection = allTokenData.reduce(
//         (sum, rm) => sum + Number(rm.totalprice),
//         0
//       );

//       // Initialize PDF document
//       const doc = new jsPDF({
//         orientation: "landscape",
//         unit: "px",
//         format: "a4",
//       });

//       // Set PDF properties including title
//       doc.setProperties({
//         title: "Talokona Token Report(Gate No-2)",
//       });

//       // Add heading with custom font size and centered horizontally
//       const headingText = "Talokona Token Report(Gate No-2)";
//       const fontSize = 20; // Change font size here
//       const pageWidth = doc.internal.pageSize.getWidth();
//       const textWidth =
//         (doc.getStringUnitWidth(headingText) * fontSize) /
//         doc.internal.scaleFactor;
//       const textX = (pageWidth - textWidth) / 2;
//       doc.setFontSize(fontSize);
//       doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

//       // Add subtitle with total collection below the main title
//       const subtitleText = `Total Collection Amount: ${totalCollection}`;
//       const subtitleFontSize = 16; // Font size for the subtitle
//       const subtitleWidth =
//         (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
//         doc.internal.scaleFactor;
//       const subtitleX = (pageWidth - subtitleWidth) / 2;
//       doc.setFontSize(subtitleFontSize);
//       doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

//       // Add table with all token data
//       doc.autoTable({
//         head: [
//           [
//             "S No",
//             "Ticket Number",
//             "Date",
//             "Adult",
//             "Children",
//             "Local Person",
//             "Safari Adult",
//             "Safari Children",
//             "Offline Booking Quantity",
//             "Offline Booking Amount",
//             "Total Amount",
//           ],
//         ],
//         body: allTokenData.map((rm) => [
//           rm.id,
//           rm.receiptnumbertwo,
//           moment(rm.created_at).format("YYYY-MM-DD HH:mm:ss"),
//           rm.adult,
//           rm.children,
//           rm.localperson,
//           rm.safariadult,
//           rm.safarichildren,
//           rm.offlinebookingquantity,
//           rm.offlinebookingamount,
//           rm.totalprice,
//         ]),
//         startY: 50, // Adjust startY to provide space for the subtitle
//         styles: { halign: "center" },
//       });

//       // Save the PDF
//       doc.save("talokona_token_report_gate2.pdf");
//     } catch (error) {
//       console.error("Error generating PDF:", error);
//     }
//   };

//   const generatePDF2 = async () => {
//     console.log("pdf clicked");

//     try {
//       // Fetch all token data
//       const response = await fetch(host + "/getTalakonaTokenSummary1", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
//       });
//       const allTokenData1 = await response.json();

//       // Calculate the total collection
//       const totalCollection = allTokenData1.reduce(
//         (sum, summary) => sum + summary.total,
//         0
//       );
//       console.log("totallll", totalCollection);

//       // Initialize PDF document
//       const doc = new jsPDF({
//         orientation: "landscape",
//         unit: "px",
//         format: "a4",
//       });

//       // Set PDF properties including title
//       doc.setProperties({
//         title: "Talokona Summary Report(Gate No-1)",
//       });

//       // Add heading with custom font size and centered horizontally
//       const headingText = "Talokona Summary Report(Gate No-1)";
//       const fontSize = 20; // Change font size here
//       const pageWidth = doc.internal.pageSize.getWidth();
//       const textWidth =
//         (doc.getStringUnitWidth(headingText) * fontSize) /
//         doc.internal.scaleFactor;
//       const textX = (pageWidth - textWidth) / 2;
//       doc.setFontSize(fontSize);
//       doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

//       // Add subtitle with total collection below the main title
//       const subtitleText = `Total Collection: ${totalCollection}`;
//       const subtitleFontSize = 16; // Font size for the subtitle
//       const subtitleWidth =
//         (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
//         doc.internal.scaleFactor;
//       const subtitleX = (pageWidth - subtitleWidth) / 2;
//       doc.setFontSize(subtitleFontSize);
//       doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

//       // Add table with all token data
//       doc.autoTable({
//         head: [
//           [
//             "Two Wheelers",
//             "Two Wheelers Amount",
//             "Three Wheelers",
//             "Three Wheelers Amount",
//             "LMV",
//             "LMV Amount",
//             "HMV",
//             "HMV Amount",
//             "Still Cameras",
//             "Still Cameras Amount",
//             "Video Cameras",
//             "Video Cameras Amount",
//             "Total Sum",
//             "Year",
//             "Month",
//             "Day",
//           ],
//         ],
//         body: allTokenData1.map((summary) => [
//           summary.two_wheelers,
//           summary.two_wheelers_amt,
//           summary.three_wheelers,
//           summary.three_wheelers_amt,
//           summary.LMV,
//           summary.lmv_amt,
//           summary.HMV,
//           summary.HMV_AMT,
//           summary.still_camers,
//           summary.still_camers_amt,
//           summary.video_camers,
//           summary.video_camers_amt,
//           summary.total_sum,
//           summary.year,
//           summary.month,
//           summary.day,
//         ]),
//         startY: 50, // Adjust startY to provide space for the subtitle
//         styles: { halign: "center" },
//       });

//       // Save the PDF
//       doc.save("talokona_token_report_gate1.pdf");
//     } catch (error) {
//       console.error("Error generating PDF:", error);
//     }
//   };

//   const generatePDF3 = async () => {
//     console.log("pdf clicked");

//     try {
//       // Fetch all token data
//       const response = await fetch(host + "/getTalakonaTokenSummary2", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
//       });
//       const allTokenData1 = await response.json();

//       // Calculate the total collection
//       const totalCollection = allTokenData1.reduce(
//         (sum, summary1) => sum + summary1.total,
//         0
//       );

//       // Initialize PDF document
//       const doc = new jsPDF({
//         orientation: "landscape",
//         unit: "px",
//         format: "a4",
//       });

//       // Set PDF properties including title
//       doc.setProperties({
//         title: "Talokona Summary Report(Gate No-2)",
//       });

//       // Add heading with custom font size and centered horizontally
//       const headingText = "Talokona Summary Report(Gate No-2)";
//       const fontSize = 20; // Change font size here
//       const pageWidth = doc.internal.pageSize.getWidth();
//       const textWidth =
//         (doc.getStringUnitWidth(headingText) * fontSize) /
//         doc.internal.scaleFactor;
//       const textX = (pageWidth - textWidth) / 2;
//       doc.setFontSize(fontSize);
//       doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

//       // Add subtitle with total collection below the main title
//       const subtitleText = `Total Collection: ${totalCollection}`;
//       const subtitleFontSize = 16; // Font size for the subtitle
//       const subtitleWidth =
//         (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
//         doc.internal.scaleFactor;
//       const subtitleX = (pageWidth - subtitleWidth) / 2;
//       doc.setFontSize(subtitleFontSize);
//       doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

//       // Add table with all token data
//       doc.autoTable({
//         head: [
//           [
//             "Adult",
//             "Adult amount",

//             "Local Persons",
//             "Local Persons amount",
//             "Safari Adult",
//             "safari Adult amount",
//             "Safari Children",
//             "safari children amount",

//             "offline booking quantity sum",

//             "offline booking quantity sum",
//             "Total Amount",

//             "year",
//             "month",
//             "day",
//           ],
//         ],
//         body: allTokenData1.map((summary1) => [
//           summary1.adult,

//           summary1.adult_amt,

//           summary1.localperson,

//           summary1.localperson_amt,

//           summary1.safariadult,

//           summary1.safariadult_AMT,

//           summary1.safarichildren,

//           summary1.safarichildren_amt,

//           summary1.offlinebookingquantity_sum,

//           summary1.offlinebookingamount_sum,

//           summary1.totalprice_sum,

//           summary1.year,

//           summary1.month,

//           summary1.day,
//         ]),
//         startY: 50, // Adjust startY to provide space for the subtitle
//         styles: { halign: "center" },
//       });

//       // Save the PDF
//       doc.save("talokona_token_report_gate1.pdf");
//     } catch (error) {
//       console.error("Error generating PDF:", error);
//     }
//   };

//   return (
//     <div>
//       <div className="col-lg-2 col">
//         <Header />
//       </div>
//       <div className="container-fluid col-lg-10 col ">
//         <div className="col-lg-12 row">
//           <div className="col-lg-12"></div>
//           <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  content_back">
//             <div className="row">
//               <b>Talakona Token Reports</b>
//             </div>
//             <br />
//             <div className="row">
//               <div style={{ width: "20%" }}>
//                 <form className="form-group" onSubmit={getTokenReport}>
//                   <div className="d-flex align-items-center">
//                     <div className="col-lg-12 mx-2">
//                       {" "}
//                       <label>Gate No 1</label>
//                     </div>
//                     <div className="col-lg-12 mx-2">
//                       <label for="selectCheckin_Date">Start Date</label>
//                       <input
//                         style={{ marginLeft: "10px" }}
//                         type="date"
//                         id="selectCheckin_Date"
//                         onChange={(e) => setStartDate(e.target.value)}
//                       />
//                     </div>
//                     <br />
//                     <div className="col-lg-12 mx-2">
//                       <label for="selectCheckout_Date">End Date</label>
//                       <input
//                         style={{ marginLeft: "10px" }}
//                         type="date"
//                         id="selectCheckout_Date"
//                         onChange={(e) => setEndDate(e.target.value)}
//                       />
//                     </div>
//                     <br />
//                     <button type="submit" className="btn btn-primary mx-2">
//                       Submit
//                     </button>
//                     <button
//                       type="button"
//                       className="btn btn-primary mx-4"
//                       style={{ width: "auto" }}
//                       onClick={generatePDF}
//                     >
//                       Token Download
//                     </button>
//                     <br />
//                     <button
//                       type="button"
//                       className="btn btn-primary mx-4"
//                       style={{ width: "auto" }}
//                       onClick={handleSummaryGate1Click} // Call handleSummaryGate1Click when the button is clicked
//                     >
//                       1
//                     </button>
//                     <button
//                       type="button"
//                       className="btn btn-primary mx-4"
//                       style={{ width: "auto" }}
//                       onClick={generatePDF2} // Call handleSummaryGate1Click when the button is clicked
//                     >
//                       dow
//                     </button>
//                   </div>
//                 </form>
//                 <form className="form-group" onSubmit={getTokenReport1}>
//                   <div className="d-flex align-items-center">
//                     <div className="col-lg-12 mx-2">
//                       {" "}
//                       <label>Gate No 2</label>
//                     </div>
//                     <div className="col-lg-12 mx-2">
//                       <label for="selectCheckin_Date">Start Date</label>
//                       <input
//                         style={{ marginLeft: "10px" }}
//                         type="date"
//                         id="selectCheckin_Date"
//                         onChange={(e) => setStartDate(e.target.value)}
//                       />
//                     </div>
//                     <br />
//                     <div className="col-lg-12 mx-2">
//                       <label for="selectCheckout_Date">End Date</label>
//                       <input
//                         style={{ marginLeft: "10px" }}
//                         type="date"
//                         id="selectCheckout_Date"
//                         onChange={(e) => setEndDate(e.target.value)}
//                       />
//                     </div>
//                     <br />
//                     <button type="submit" className="btn btn-primary mx-2">
//                       Submit
//                     </button>
//                     <button
//                       type="button"
//                       className="btn btn-primary mx-4"
//                       style={{ width: "auto" }}
//                       onClick={generatePDF1}
//                     >
//                       Token Download
//                     </button>
//                     <button
//                       type="button"
//                       className="btn btn-primary mx-4"
//                       style={{ width: "auto" }}
//                       onClick={handleSummaryGate2Click} // Call handleSummaryGate2Click when the button is clicked
//                     >
//                       gate2
//                     </button>
//                     <button
//                       type="button"
//                       className="btn btn-primary mx-4"
//                       style={{ width: "auto" }}
//                       onClick={generatePDF3} // Call handleSummaryGate2Click when the button is clicked
//                     >
//                       doc
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//             <div className="row" style={{ overflowX: "auto" }}>
//               {table1 == true ? (
//                 <div>
//                   <div className="row" style={{ overflowX: "auto" }}>
//                     <table className="table table-striped responsive-table">
//                       <thead>
//                         <tr className="data-row-head">
//                           <th>S No</th>
//                           <th>Ticket Number</th>
//                           <th>Date</th>
//                           <th>Vehicle Nnumber</th>
//                           <th>Name</th>
//                           <th>Number of Persons</th>
//                           <th>Phone</th>
//                           <th>City</th>
//                           <th>Two Wheelers</th>
//                           <th>Three Wheelers</th>
//                           <th>Four Wheelers</th>
//                           <th>Bus Truck</th>
//                           <th>Still Camers</th>
//                           <th>Video Camers</th>
//                           <th>Total</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {currentRecords.map((rm, index) => (
//                           <tr key={rm.id} className="data-row-data">
//                             <td style={{ textAlign: "center" }}>{rm.id}</td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.receiptnumber}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {moment(rm.created_at).format(
//                                 "YYYY-MM-DD HH:mm:ss"
//                               )}{" "}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.vehicle_number}
//                             </td>
//                             <td style={{ textAlign: "center" }}>{rm.name}</td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.number_of_persons}
//                             </td>
//                             <td style={{ textAlign: "center" }}>{rm.phone}</td>
//                             <td style={{ textAlign: "center" }}>{rm.city}</td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.two_wheelers}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.three_wheelers}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.four_wheelers}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.bus_truck}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.still_camers}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.video_camers}
//                             </td>
//                             <td style={{ textAlign: "center" }}>{rm.total}</td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                     <p style={{ direction: "rtl" }}>
//                       <strong>Total Collection Amount :</strong>{" "}
//                       {totalCollection}
//                     </p>
//                   </div>
//                   <div className="d-flex justify-content-end">
//                     <ul className="pagination">
//                       <li
//                         className={`page-item ${
//                           currentPage === 1 ? "disabled" : ""
//                         }`}
//                       >
//                         <button
//                           onClick={() => paginate(currentPage - 1)}
//                           className="page-link"
//                         >
//                           Previous
//                         </button>
//                       </li>
//                       <span>{`Page ${currentPage} of ${Math.ceil(
//                         tokenData.length / recordsPerPage
//                       )}`}</span>
//                       <li
//                         className={`page-item ${
//                           currentPage ===
//                           Math.ceil(tokenData.length / recordsPerPage)
//                             ? "disabled"
//                             : ""
//                         }`}
//                       >
//                         <button
//                           onClick={() => paginate(currentPage + 1)}
//                           className="page-link"
//                         >
//                           Next
//                         </button>
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//               ) : (
//                 <div></div>
//               )}

//               {table2 == true ? (
//                 <div>
//                   <div className="row" style={{ overflowX: "auto" }}>
//                     <table className="table table-striped responsive-table">
//                       <thead>
//                         <tr className="data-row-head">
//                           <th>S No</th>
//                           <th>Ticket Number</th>
//                           <th>Date</th>
//                           <th>Adult</th>
//                           <th>Children</th>
//                           <th>Local Persons</th>
//                           <th>Safari Adult</th>
//                           <th>Safari Children</th>
//                           <th>Offline Booking Quantity</th>
//                           <th>Offline Booking Amount'</th>
//                           <th>Total Amount</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {currentRecords.map((rm, index) => (
//                           <tr key={rm.id} className="data-row-data">
//                             <td style={{ textAlign: "center" }}>{rm.id}</td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.receiptnumbertwo}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {moment(rm.created_at).format(
//                                 "YYYY-MM-DD HH:mm:ss"
//                               )}{" "}
//                             </td>
//                             <td style={{ textAlign: "center" }}>{rm.adult}</td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.children}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.localperson}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.safariadult}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.safarichildren}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.offlinebookingquantity}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.offlinebookingamount}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {rm.totalprice}
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                     <p style={{ direction: "rtl" }}>
//                       <strong>Total Collection Amount :</strong>{" "}
//                       {totalCollection}
//                     </p>
//                   </div>
//                   <div className="d-flex justify-content-end">
//                     <ul className="pagination">
//                       <li
//                         className={`page-item ${
//                           currentPage === 1 ? "disabled" : ""
//                         }`}
//                       >
//                         <button
//                           onClick={() => paginate(currentPage - 1)}
//                           className="page-link"
//                         >
//                           Previous
//                         </button>
//                       </li>
//                       <span>{`Page ${currentPage} of ${Math.ceil(
//                         tokenData.length / recordsPerPage
//                       )}`}</span>
//                       <li
//                         className={`page-item ${
//                           currentPage ===
//                           Math.ceil(tokenData.length / recordsPerPage)
//                             ? "disabled"
//                             : ""
//                         }`}
//                       >
//                         <button
//                           onClick={() => paginate(currentPage + 1)}
//                           className="page-link"
//                         >
//                           Next
//                         </button>
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//               ) : (
//                 <div></div>
//               )}
//               {table3 == true ? (
//                 <div>
//                   <div className="row" style={{ overflowX: "auto" }}>
//                     <table className="table table-striped responsive-table">
//                       {/* <thead>
//                         <tr className="data-row-head">
//                           <th>S No</th>
//                           <th>two_wheelers</th>
//                           <th>two_wheelers_amt</th>
//                           <th>three_wheelers</th>
//                           <th>three_wheelers_amt</th>
//                           <th>LMV</th>
//                           <th>lmv_amt</th>
//                           <th>HMV</th>
//                           <th>HMV_AMT</th>
//                           <th>still_camers</th>
//                           <th>still_camers_amt</th>
//                           <th>video_camers</th>
//                           <th>video_camers_amt</th>
//                           <th>total_sum</th>
//                           <th>date</th>
//                         </tr>
//                       </thead> */}

//                       <thead>
//                         <tr>
//                           {/* <th>SNO</th> */}
//                           <th>Two Wheelers</th>
//                           <th>Two Wheelers Amount</th>
//                           <th>Three Wheelers</th>
//                           <th>Three Wheelers Amount</th>
//                           <th>LMV</th>
//                           <th>LMV Amount</th>
//                           <th>HMV</th>
//                           <th>HMV Amount</th>
//                           <th>Still Cameras</th>
//                           <th>Still Cameras Amount</th>
//                           <th>Video Cameras</th>
//                           <th>Video Cameras Amount</th>
//                           <th>Total Sum</th>
//                           <th>Yaer</th>
//                           <th>Month</th>
//                           <th>Day</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {summaryData.map((summary) => (
//                           <tr key={summary.Created_date}>
//                             {/* <td>{}</td> */}
//                             <td>{summary.two_wheelers}</td>
//                             <td>{summary.two_wheelers_amt}</td>
//                             <td>{summary.three_wheelers}</td>
//                             <td>{summary.three_wheelers_amt}</td>
//                             <td>{summary.LMV}</td>
//                             <td>{summary.lmv_amt}</td>
//                             <td>{summary.HMV}</td>
//                             <td>{summary.HMV_AMT}</td>
//                             <td>{summary.still_camers}</td>
//                             <td>{summary.still_camers_amt}</td>
//                             <td>{summary.video_camers}</td>
//                             <td>{summary.video_camers_amt}</td>
//                             <td>{summary.total_sum}</td>
//                             <td>{summary.year}</td>
//                             <td>{summary.month}</td>
//                             <td>{summary.day}</td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                     {/* <p style={{ direction: "rtl" }}>
//                       <strong>Total Collection Amount :</strong>{" "}
//                       {summaryData.totalCollection}
//                     </p> */}
//                   </div>
//                   <div className="d-flex justify-content-end">
//                     <ul className="pagination">
//                       <li
//                         className={`page-item ${
//                           currentPage === 1 ? "disabled" : ""
//                         }`}
//                       >
//                         <button
//                           onClick={() => paginate(currentPage - 1)}
//                           className="page-link"
//                         >
//                           Previous
//                         </button>
//                       </li>
//                       <span>{`Page ${currentPage} of ${Math.ceil(
//                         tokenData.length / recordsPerPage
//                       )}`}</span>
//                       <li
//                         className={`page-item ${
//                           currentPage ===
//                           Math.ceil(tokenData.length / recordsPerPage)
//                             ? "disabled"
//                             : ""
//                         }`}
//                       >
//                         <button
//                           onClick={() => paginate(currentPage + 1)}
//                           className="page-link"
//                         >
//                           Next
//                         </button>
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//               ) : (
//                 <div></div>
//               )}

//               {table4 == true ? (
//                 <div>
//                   <div className="row" style={{ overflowX: "auto" }}>
//                     <table className="table table-striped responsive-table">
//                       <thead>
//                         <tr className="data-row-head">
//                           <th>Adult</th>
//                           <th>Adult amount</th>
//                           {/* <th>Children</th> */}
//                           <th>Local Persons</th>
//                           <th>Local Persons amount</th>
//                           <th>Safari Adult</th>
//                           <th>safari Adult amount</th>
//                           <th>Safari Children</th>
//                           <th>safari children amount</th>
//                           {/* <th>Offline Booking Quantity</th> */}
//                           <th>offline booking quantity sum</th>
//                           {/* <th>Offline Booking Amount</th> */}
//                           <th>offline booking quantitysum</th>
//                           <th>Total Amount</th>
//                           {/* <th>created_at</th> */}
//                           <th>year</th>
//                           <th>month</th>
//                           <th>day</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {summaryData.map((summary1) => (
//                           <tr key={summary1.id} className="data-row-data">
//                             {/* <td style={{ textAlign: "center" }}>
//                               {moment(summary1.created_at).format(
//                                 "YYYY-MM-DD HH:mm:ss"
//                               )}{" "}
//                             </td> */}
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.adult}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.adult_amt}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.localperson}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.localperson_amt}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.safariadult}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.safariadult_AMT}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.safarichildren}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.safarichildren_amt}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.offlinebookingquantity_sum}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.offlinebookingamount_sum}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.totalprice_sum}
//                             </td>
//                             {/* <td style={{ textAlign: "center" }}>
//                               {summary1.created_at}
//                             </td> */}
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.year}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.month}
//                             </td>
//                             <td style={{ textAlign: "center" }}>
//                               {summary1.day}
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                     {/* <p style={{ direction: "rtl" }}>
//                       <strong>Total Collection Amount :</strong>{" "}
//                       {totalCollection}
//                     </p> */}
//                   </div>
//                   <div className="d-flex justify-content-end">
//                     <ul className="pagination">
//                       <li
//                         className={`page-item ${
//                           currentPage === 1 ? "disabled" : ""
//                         }`}
//                       >
//                         <button
//                           onClick={() => paginate(currentPage - 1)}
//                           className="page-link"
//                         >
//                           Previous
//                         </button>
//                       </li>
//                       <span>{`Page ${currentPage} of ${Math.ceil(
//                         tokenData.length / recordsPerPage
//                       )}`}</span>
//                       <li
//                         className={`page-item ${
//                           currentPage ===
//                           Math.ceil(tokenData.length / recordsPerPage)
//                             ? "disabled"
//                             : ""
//                         }`}
//                       >
//                         <button
//                           onClick={() => paginate(currentPage + 1)}
//                           className="page-link"
//                         >
//                           Next
//                         </button>
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//               ) : (
//                 <div></div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TalokonaTokenReports;

import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./Accomodation.css";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Navigate, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import { useHistory } from "react-router-dom";

const TalokonaTokenReports = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [summaryData, setSummaryData] = useState([]);

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tokenData, setTokenData] = useState([]);
  const [table1, setTable1] = useState(false);
  const [table2, setTable2] = useState(false);
  const [table3, setTable3] = useState(false);
  const [table4, setTable4] = useState(false);
  const [totalCollection, setTotalCollection] = useState();
  const [showSummaryButton1, setShowSummaryButton1] = useState(false);
  const [showSummaryButton2, setShowSummaryButton2] = useState(false);

  useEffect(() => {
    // Redirect to login if access token is missing or user is not authorized
    if (
      !localStorage.getItem("access_token") ||
      localStorage.getItem("access_token") === "" ||
      localStorage.getItem("userRole") != 1
    ) {
      navigate("/Home", "_self");
    }
  }, [navigate]);
  const handleGate1Click = () => {
    navigate("/talakona-gate-one"); // Replace '/gate1' with the actual path you want to navigate to
  };
  const handleGate2Click = () => {
    navigate("/talakona-gate-two"); // Replace '/gate1' with the actual path you want to navigate to
  };

  let host = "https://api.vanadarshani.com";
  // let host = "http://localhost:5000";

  const getTokenReport = async (e) => {
    e.preventDefault();
    console.log(startDate, endDate, "checking values");
    setTable1(true);
    setTable2(false);
    let response = await fetch(host + "/getTalakonaTokenReports", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
    }).then((response) =>
      response.json().then((res) => {
        setTokenData(res);
        const totalCollection = res.reduce(
          (sum, rm) => sum + Number(rm.total),
          0
        );
        setTotalCollection(totalCollection);
        console.log(res, tokenData, "token data checking");
        console.log(totalCollection, "total collection amount");
      })
    );
  };

  const getTokenReport1 = async (e) => {
    e.preventDefault();
    console.log(startDate, endDate, "checking values");
    setTable2(true);
    setTable1(false);
    let response = await fetch(host + "/getTalakonaTokenReports1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
    }).then((response) =>
      response.json().then((res) => {
        setTokenData(res);
        const totalCollection = res.reduce(
          (sum, rm) => sum + Number(rm.totalprice),
          0
        );
        setTotalCollection(totalCollection);
        console.log(res, "token data checking");
        console.log(totalCollection, "total collection amount");
      })
    );
  };

  const handleSummaryGate1Click = async () => {
    setTable1(false);
    setTable2(false);
    setTable3(true);
    setTable4(false);
    try {
      const response = await fetch(host + "/getTalakonaTokenSummary1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const summaryData1 = await response.json();
      setSummaryData(summaryData1);
      console.log(summaryData, "summary data for Gate No 1");
    } catch (error) {
      console.error("There was a problem with fetching summary data:", error);
    }
  };

  // Function to handle summary button click for Gate No 2
  const handleSummaryGate2Click = async () => {
    setTable1(false);
    setTable2(false);
    setTable3(false);
    setTable4(true);
    try {
      const response = await fetch(host + "/getTalakonaTokenSummary2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const summaryData2 = await response.json();
      setSummaryData(summaryData2);
      console.log(summaryData, "summary data for Gate No 2");
    } catch (error) {
      console.error("There was a problem with fetching summary data:", error);
    }
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = tokenData.slice(indexOfFirstRecord, indexOfLastRecord);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const generatePDF = async () => {
    console.log("pdf clicked");

    try {
      // Fetch all token data
      const response = await fetch(host + "/getTalakonaTokenReports", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
      });
      const allTokenData = await response.json();

      // Calculate the total collection
      const totalCollection = allTokenData.reduce(
        (sum, rm) => sum + rm.total,
        0
      );

      // Initialize PDF document
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "a4",
      });

      // Set PDF properties including title
      doc.setProperties({
        title: "Talokona Token Report(Gate No-1)",
      });

      // Add heading with custom font size and centered horizontally
      const headingText = "Talokona Token Report(Gate No-1)";
      const fontSize = 20; // Change font size here
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth =
        (doc.getStringUnitWidth(headingText) * fontSize) /
        doc.internal.scaleFactor;
      const textX = (pageWidth - textWidth) / 2;
      doc.setFontSize(fontSize);
      doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

      // Add subtitle with total collection below the main title
      const subtitleText = `Total Collection: ${totalCollection}`;
      const subtitleFontSize = 16; // Font size for the subtitle
      const subtitleWidth =
        (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
        doc.internal.scaleFactor;
      const subtitleX = (pageWidth - subtitleWidth) / 2;
      doc.setFontSize(subtitleFontSize);
      doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

      // Add table with all token data
      doc.autoTable({
        head: [
          [
            "S No",
            "Ticket Number",
            "Date",
            "Vehicle Number",
            "Name",
            "Number of Persons",
            "Phone",
            "City",
            "Two Wheelers",
            "Three Wheelers",
            "Four Wheelers",
            "Bus Truck",
            "Still Cameras",
            "Video Cameras",
            "Total",
          ],
        ],
        body: allTokenData.map((rm) => [
          rm.id,
          rm.receiptnumber,
          moment(rm.created_at).format("YYYY-MM-DD HH:mm:ss"),
          rm.vehicle_number,
          rm.name,
          rm.number_of_persons,
          rm.phone,
          rm.city,
          rm.two_wheelers,
          rm.three_wheelers,
          rm.four_wheelers,
          rm.bus_truck,
          rm.still_camers,
          rm.video_camers,
          rm.total,
        ]),
        startY: 50, // Adjust startY to provide space for the subtitle
        styles: { halign: "center" },
      });

      // Save the PDF
      doc.save("talokona_token_report_gate1.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const generatePDF1 = async () => {
    console.log("pdf clicked");

    try {
      // Fetch all token data
      const response = await fetch(host + "/getTalakonaTokenReports1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
      });
      const allTokenData = await response.json();

      // Calculate the total collection
      const totalCollection = allTokenData.reduce(
        (sum, rm) => sum + Number(rm.totalprice),
        0
      );

      // Initialize PDF document
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "a4",
      });

      // Set PDF properties including title
      doc.setProperties({
        title: "Talokona Token Report(Gate No-2)",
      });

      // Add heading with custom font size and centered horizontally
      const headingText = "Talokona Token Report(Gate No-2)";
      const fontSize = 20; // Change font size here
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth =
        (doc.getStringUnitWidth(headingText) * fontSize) /
        doc.internal.scaleFactor;
      const textX = (pageWidth - textWidth) / 2;
      doc.setFontSize(fontSize);
      doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

      // Add subtitle with total collection below the main title
      const subtitleText = `Total Collection Amount: ${totalCollection}`;
      const subtitleFontSize = 16; // Font size for the subtitle
      const subtitleWidth =
        (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
        doc.internal.scaleFactor;
      const subtitleX = (pageWidth - subtitleWidth) / 2;
      doc.setFontSize(subtitleFontSize);
      doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

      // Add table with all token data
      doc.autoTable({
        head: [
          [
            "S No",
            "Ticket Number",
            "Date",
            "Adult",
            "Children",
            "Local Person",
            "Safari Adult",
            "Safari Children",
            "Offline Booking Quantity",
            "Offline Booking Amount",
            "Total Amount",
          ],
        ],
        body: allTokenData.map((rm) => [
          rm.id,
          rm.receiptnumbertwo,
          moment(rm.created_at).format("YYYY-MM-DD HH:mm:ss"),
          rm.adult,
          rm.children,
          rm.localperson,
          rm.safariadult,
          rm.safarichildren,
          rm.offlinebookingquantity,
          rm.offlinebookingamount,
          rm.totalprice,
        ]),
        startY: 50, // Adjust startY to provide space for the subtitle
        styles: { halign: "center" },
      });

      // Save the PDF
      doc.save("talokona_token_report_gate2.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const generatePDF2 = async () => {
    console.log("pdf clicked");

    try {
      // Fetch all token data
      const response = await fetch(host + "/getTalakonaTokenSummary1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
      });
      const allTokenData1 = await response.json();

      // Calculate the total collection
      const totalCollection = allTokenData1.reduce(
        (sum, summary) => sum + summary.total,
        0
      );
      console.log("totallll", totalCollection);

      // Initialize PDF document
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "a4",
      });

      // Set PDF properties including title
      doc.setProperties({
        title: "Talokona Summary Report(Gate No-1)",
      });

      // Add heading with custom font size and centered horizontally
      const headingText = "Talokona Summary Report(Gate No-1)";
      const fontSize = 20; // Change font size here
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth =
        (doc.getStringUnitWidth(headingText) * fontSize) /
        doc.internal.scaleFactor;
      const textX = (pageWidth - textWidth) / 2;
      doc.setFontSize(fontSize);
      doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

      // Add subtitle with total collection below the main title
      const subtitleText = `Total Collection: ${totalCollection}`;
      const subtitleFontSize = 16; // Font size for the subtitle
      const subtitleWidth =
        (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
        doc.internal.scaleFactor;
      const subtitleX = (pageWidth - subtitleWidth) / 2;
      doc.setFontSize(subtitleFontSize);
      doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

      // Add table with all token data
      doc.autoTable({
        head: [
          [
            "Two Wheelers",
            "Two Wheelers Amount",
            "Three Wheelers",
            "Three Wheelers Amount",
            "LMV",
            "LMV Amount",
            "HMV",
            "HMV Amount",
            "Still Cameras",
            "Still Cameras Amount",
            "Video Cameras",
            "Video Cameras Amount",
            "Total Sum",
            "Year",
            "Month",
            "Day",
          ],
        ],
        body: allTokenData1.map((summary) => [
          summary.two_wheelers,
          summary.two_wheelers_amt,
          summary.three_wheelers,
          summary.three_wheelers_amt,
          summary.LMV,
          summary.lmv_amt,
          summary.HMV,
          summary.HMV_AMT,
          summary.still_camers,
          summary.still_camers_amt,
          summary.video_camers,
          summary.video_camers_amt,
          summary.total_sum,
          summary.year,
          summary.month,
          summary.day,
        ]),
        startY: 50, // Adjust startY to provide space for the subtitle
        styles: { halign: "center" },
      });

      // Save the PDF
      doc.save("talokona_token_report_gate1.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const generatePDF3 = async () => {
    console.log("pdf clicked");

    try {
      // Fetch all token data
      const response = await fetch(host + "/getTalakonaTokenSummary2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
      });
      const allTokenData1 = await response.json();

      // Calculate the total collection
      const totalCollection = allTokenData1.reduce(
        (sum, summary1) => sum + summary1.total,
        0
      );

      // Initialize PDF document
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "a4",
      });

      // Set PDF properties including title
      doc.setProperties({
        title: "Talokona Summary Report(Gate No-2)",
      });

      // Add heading with custom font size and centered horizontally
      const headingText = "Talokona Summary Report(Gate No-2)";
      const fontSize = 20; // Change font size here
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth =
        (doc.getStringUnitWidth(headingText) * fontSize) /
        doc.internal.scaleFactor;
      const textX = (pageWidth - textWidth) / 2;
      doc.setFontSize(fontSize);
      doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

      // Add subtitle with total collection below the main title
      const subtitleText = `Total Collection: ${totalCollection}`;
      const subtitleFontSize = 16; // Font size for the subtitle
      const subtitleWidth =
        (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
        doc.internal.scaleFactor;
      const subtitleX = (pageWidth - subtitleWidth) / 2;
      doc.setFontSize(subtitleFontSize);
      doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

      // Add table with all token data
      doc.autoTable({
        head: [
          [
            "Adult",
            "Adult amount",

            "Local Persons",
            "Local Persons amount",
            "Safari Adult",
            "safari Adult amount",
            "Safari Children",
            "safari children amount",

            "offline booking quantity sum",

            "offline booking quantity sum",
            "Total Amount",

            "year",
            "month",
            "day",
          ],
        ],
        body: allTokenData1.map((summary1) => [
          summary1.adult,

          summary1.adult_amt,

          summary1.localperson,

          summary1.localperson_amt,

          summary1.safariadult,

          summary1.safariadult_AMT,

          summary1.safarichildren,

          summary1.safarichildren_amt,

          summary1.offlinebookingquantity_sum,

          summary1.offlinebookingamount_sum,

          summary1.totalprice_sum,

          summary1.year,

          summary1.month,

          summary1.day,
        ]),
        startY: 50, // Adjust startY to provide space for the subtitle
        styles: { halign: "center" },
      });

      // Save the PDF
      doc.save("talokona_token_report_gate1.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div>
      <div className="col-lg-2 col">
        <Header />
      </div>
      <div className="container-fluid col-lg-10 col ">
        <div className="col-lg-12 row">
          <div className="col-lg-12"></div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  content_back">
            <div className="row">
              <b style={{ textAlign: "center", fontSize: "20px" }}>
                Talakona Token Reports
              </b>
            </div>
            <br />
            <div
              className="row"
              style={{
                display: "flex",

                justifyContent: "space-between",
              }}
            >
              <button
                className="btn btn-primary m-2"
                onClick={handleGate1Click}
              >
                Gate 1
              </button>
              <button
                className="btn btn-secondary m-2"
                onClick={handleGate2Click}
              >
                Gate 2
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalokonaTokenReports;
