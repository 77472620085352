// // import React, { useEffect, useState, useRef } from "react";

// // import Header from "./Header";
// // import "./Accomodation.css";
// // import DatePicker from "react-multi-date-picker";
// // import DateObject from "react-date-object";
// // import moment from "moment";
// // import { Navigate, useNavigate } from "react-router-dom";
// // import "react-datepicker/dist/react-datepicker.css";
// // import AccomodationImages from "./AccomodationImages";

// // const Accomodation = () => {
// //   const navigate = useNavigate();
// //   const userRole = localStorage.getItem("userRole");
// //   const [selectedRooms, setSelectedRooms] = useState([]);
// //   const datePickerRef = useRef();

// //   let host = "https://api.vanadarshani.com";
// //   //  let host = "http://localhost:5000";
// //   const [startDate, setStartDate] = useState(new Date());
// //   let localStoreNextDates = JSON.parse(localStorage.getItem("nextDates"));
// //   if (localStoreNextDates == undefined) {
// //     localStoreNextDates = [
// //       new DateObject().add(0, "days"),
// //       new DateObject().add(0, "days"),
// //     ];
// //   } else {
// //     localStoreNextDates = [
// //       new Date(localStoreNextDates[0]),
// //       new Date(localStoreNextDates[1]),
// //     ];
// //   }
// //   const [nextDates, setNextDates] = useState(localStoreNextDates);

// //   const [roomsData, setData] = React.useState([]);
// //   const [availableCalenderRoomsRes, setAvailableCalenderRoomsRes] =
// //     React.useState([]);
// //   // let selectedRooms = [];
// //   let selectedRoomsTmp = [];
// //   let selectedRoomDetails = {};

// //   const roomBooking = (roomId) => {
// //     const selectedRoom = roomsData.find((e) => e.room_id === roomId);
// //     const isChecked = selectedRooms.some((room) => room.room_id === roomId);

// //     setSelectedRooms((prevSelectedRooms) => {
// //       const updatedRooms = isChecked
// //         ? prevSelectedRooms.filter((room) => room.room_id !== roomId)
// //         : [...prevSelectedRooms, selectedRoom];

// //       const bookingDetailsData = {
// //         locationId: document.getElementById("locationIds").value,
// //         date: moment(startDate).format("yyyy-MM-DD"),
// //         daysStay: document.getElementById("daysStay").value,
// //         selectedRooms: updatedRooms,
// //         selectedDateRange: nextDates,
// //         fromDate: moment(nextDates[0]).format("yyyy-MM-DD"),
// //         toDate: moment(nextDates[0]).format("yyyy-MM-DD"),
// //       };

// //       localStorage.setItem(
// //         "bookingDetailsData",
// //         JSON.stringify(bookingDetailsData)
// //       );

// //       return updatedRooms;
// //     });
// //   };

// //   const handleCheckAvailability = async () => {
// //     await checkAvailability();
// //   };

// //   const handleEnterKey = (event) => {
// //     if (event.key === "Enter") {
// //       if (document.activeElement.id === "daysStay") {
// //         changeDaysStay();
// //       } else {
// //         handleCheckAvailability();
// //       }
// //     }
// //   };

// //   useEffect(() => {
// //     checkAvailability();
// //     populateAvailableRoomsInCalender();
// //     document.addEventListener("keydown", handleEnterKey);
// //     return () => {
// //       document.removeEventListener("keydown", handleEnterKey);
// //     };
// //   }, []);

// //   const changeDaysStay = () => {
// //     let nextDate = new Date();
// //     let currentDayNo = nextDate.getDate();
// //     let nextDates = [];
// //     nextDates.push(new Date());
// //     for (
// //       let d = 1;
// //       d < parseInt(document.getElementById("daysStay").value);
// //       d++
// //     ) {
// //       nextDate = new Date();
// //       nextDate.setDate(currentDayNo + d);
// //       nextDates.push(nextDate);
// //     }
// //     setNextDates([
// //       new DateObject().add(0, "days"),
// //       new DateObject().add(
// //         parseInt(document.getElementById("daysStay").value) - 1,
// //         "days"
// //       ),
// //     ]);
// //     //setStartDate([new DateObject().add(4, "days")]);

// //     let tmpBookingDetailsData = JSON.parse(
// //       localStorage.getItem("bookingDetailsData")
// //     );
// //     if (tmpBookingDetailsData == undefined || tmpBookingDetailsData === "{}") {
// //       tmpBookingDetailsData = {};
// //     }
// //     tmpBookingDetailsData["daysStay"] =
// //       document.getElementById("daysStay").value;
// //     localStorage.setItem(
// //       "bookingDetailsData",
// //       JSON.stringify(tmpBookingDetailsData)
// //     );
// //     setData(roomsData);
// //   };

// //   const populateAvailableRoomsInCalender = (dateParam) => {
// //     let tmpDates = [];
// //     nextDates.map((d) => {
// //       tmpDates.push(moment(d).format("yyyy-MM-DD"));
// //     });
// //     let dateQueryParam =
// //       dateParam != undefined ? dateParam : tmpDates.toString();

// //     let response = fetch(
// //       host +
// //         "/get-calendar-rooms?location=" +
// //         document.getElementById("locationIds").value +
// //         "&date=" +
// //         dateQueryParam +
// //         "&time=" +
// //         Math.round(new Date().getTime() / 1000),
// //       {
// //         method: "GET",
// //         headers: {
// //           "Content-Type": "application/json",
// //         },
// //       }
// //     ).then((response) =>
// //       response.json().then((availableCalenderRoomsRes) => {
// //         setAvailableCalenderRoomsRes(availableCalenderRoomsRes);
// //       })
// //     );
// //   };

// //   const checkAvailability = async (selectedDates) => {
// //     let response = await fetch(
// //       host +
// //         "/get-rooms?location=" +
// //         document.getElementById("locationIds").value +
// //         "&time=" +
// //         Math.round(new Date().getTime() / 1000),
// //       {
// //         method: "GET",
// //         headers: {
// //           "Content-Type": "application/json",
// //         },
// //       }
// //     ).then((response) =>
// //       response.json().then((roomsRes) => {
// //         setData(roomsRes);
// //         let tmpDates = [];
// //         if (selectedDates !== undefined) {
// //           try {
// //             selectedDates.map((d) => {
// //               tmpDates.push(moment(d).format("yyyy-MM-DD"));
// //             });
// //           } catch (e) {}
// //         }
// //         if (tmpDates.length == 0) {
// //           nextDates.map((d) => {
// //             tmpDates.push(moment(d).format("yyyy-MM-DD"));
// //           });
// //         }
// //         response = fetch(
// //           host +
// //             "/get-booked-rooms?date=" +
// //             tmpDates.toString() +
// //             "&location=" +
// //             document.getElementById("locationIds").value +
// //             "&time=" +
// //             Math.round(new Date().getTime() / 1000),
// //           {
// //             method: "GET",
// //             headers: {
// //               "Content-Type": "application/json",
// //             },
// //           }
// //         ).then((response) =>
// //           response.json().then((res) => {
// //             if (res != null) {
// //               let tmpRoomsData = [];
// //               roomsRes.forEach((element) => {
// //                 let data = res.find((item) => {
// //                   return item.room_id == element.room_id;
// //                 });
// //                 if (data != null) {
// //                   element.roomStatus = "Not Available";
// //                 } else {
// //                   element.roomStatus = "Available";
// //                 }
// //                 tmpRoomsData.push(element);
// //               });
// //               setData(tmpRoomsData);
// //             }
// //           })
// //         );
// //       })
// //     );
// //   };

// //   useEffect(() => {
// //     checkAvailability();
// //     populateAvailableRoomsInCalender();
// //   }, []);

// //   const renderDayContents = (date) => {
// //     {
// //       let month = date.month <= 9 ? "0" + date.month : date.month;
// //       let calDay = date.day;
// //       let dateDay =
// //         date.year + "-" + month + "-" + (calDay <= 9 ? "0" + calDay : calDay);
// //       const availableCount = availableCalenderRoomsRes[dateDay] || 0;
// //       const notAvailableCount = roomsData.length - availableCount;

// //       return {
// //         children: (
// //           <div
// //             style={{
// //               display: "flex",
// //               flexDirection: "column",
// //               padding: "0 10px",
// //               fontSize: "11px",
// //             }}
// //           >
// //             <div style={{ fontSize: "15px", height: "20px" }}>{date.day}</div>

// //             <div
// //               className="callend_available_green"
// //               style={{ textAlign: "center" }}
// //             >
// //               {availableCount > 0 ? `${availableCount} Ava` : ""}
// //               <br />
// //               {/* {availableCount > 0 && notAvailableCount > 0 ? "+" : ""} */}
// //             </div>
// //             <div
// //               className="callend_available_red"
// //               style={{ textAlign: "center" }}
// //             >
// //               {notAvailableCount > 0 ? `${notAvailableCount} booked` : ""}
// //             </div>
// //           </div>
// //         ),
// //       };
// //     }
// //   };

// //   const repopulateCalendarRoomsData = (date) => {
// //     let toDate = new Date(date);
// //     toDate.setDate(
// //       toDate.getDate() + parseInt(document.getElementById("daysStay").value - 1)
// //     );

// //     let tmpDates = [new Date(date), toDate];

// //     //setStartDate(tmpDates);
// //     setNextDates(tmpDates);
// //     let tmpBookingDetailsData = JSON.parse(
// //       localStorage.getItem("bookingDetailsData")
// //     );
// //     if (tmpBookingDetailsData == undefined || tmpBookingDetailsData === "{}") {
// //       tmpBookingDetailsData = {};
// //     }
// //     localStorage.setItem(
// //       "bookingDetailsData",
// //       JSON.stringify(tmpBookingDetailsData)
// //     );
// //     populateAvailableRoomsInCalender(moment(toDate).format("yyyy-MM-DD"));
// //     localStorage.setItem("nextDates", JSON.stringify(tmpDates));
// //     datePickerOnclose();
// //     checkAvailability(tmpDates);
// //     datePickerRef.current.closeCalendar();
// //   };
// //   //prevent onclose calendar actions and populating selected date range
// //   const datePickerOnclose = () => {
// //     let localStoreNextDates = JSON.parse(localStorage.getItem("nextDates"));
// //     if (localStoreNextDates != undefined) {
// //       localStoreNextDates = [
// //         new Date(localStoreNextDates[0]),
// //         new Date(localStoreNextDates[1]),
// //       ];
// //       setNextDates(localStoreNextDates);
// //     }
// //   };
// //   const onChangeLocation = () => {
// //     populateAvailableRoomsInCalender(moment(nextDates[0]).format("yyyy-MM-DD"));
// //     checkAvailability();
// //   };

// //   //[{ "accommodation_type": "Loghut", "room_number": "Loghut-3 (First floor)", "price": "Rs. 1500/- each Room", "persons": "2", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/accomodation/talakona/talakona-room-booking-online-2.gif" }, { "accommodation_type": "Loghut", "room_number": "Loghut-4 (First floor)", "price": "Rs. 1500/- each Room", "persons": "2", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/accomodation/talakona/talakona-room-booking-online-2.gif" }, { "accommodation_type": "Loghut", "room_number": "Loghut-5 (First floor)", "price": "Rs. 1250/- each Room", "persons": "2", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/accomodation/talakona/talakona-room-booking-online-2.gif" }, { "accommodation_type": "Loghut", "room_number": "Loghut-5 (First floor)", "price": "Rs. 1250/- each Room", "persons": "2", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/accomodation/talakona/talakona-room-booking-online-2.gif" }, { "accommodation_type": "Dormitory", "room_number": "Dormitory-1", "price": "Rs. 5000/- each Room", "persons": "10", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/i1.JPG" }, { "accommodation_type": "Dormitory", "room_number": "Dormitory-1", "price": "Rs. 5000/- each Room", "persons": "10", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/i1.JPG" }]
// //   return (
// //     <div>
// //       <Header />
// //       <div className="container">
// //         <div className="row">
// //           <div className="col-lg-2"></div>
// //           <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back">
// //             <AccomodationImages />
// //             <br /> <b></b>
// //             <div className="row">
// //               <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
// //               <h1
// //                 className="text-center"
// //                 style={{ fontSize: "25px", color: "green" }}
// //               >
// //                 ACCOMMODATION
// //               </h1>

// //               <div className="container">
// //                 <div className="">
// //                   <br />
// //                   <p>
// //                     Accommodation at Talakona is available for four Loghuts and
// //                     two Dormitories run by CBET Talakona.
// //                   </p>
// //                 </div>
// //               </div>
// //               <div>
// //                 <div className="container-row">
// //                   <div style={{ width: "20%" }}>
// //                     <b>Select Location</b>
// //                     <select
// //                       id="locationIds"
// //                       onChange={onChangeLocation}
// //                       onKeyDown={handleEnterKey}
// //                     >
// //                       <option value="1">Talakona</option>
// //                       <option value="2">Mamandur</option>
// //                     </select>
// //                   </div>
// //                   <div style={{ width: "11%" }}>
// //                     <b>Days Stay</b>
// //                     <select
// //                       id="daysStay"
// //                       onChange={() => {
// //                         changeDaysStay();
// //                       }}
// //                     >
// //                       <option>1</option>
// //                       <option>2</option>
// //                       <option>3</option>
// //                       <option>4</option>
// //                       <option>5</option>
// //                       <option>6</option>
// //                       <option>7</option>
// //                       <option>8</option>
// //                       <option>9</option>
// //                       <option>10</option>
// //                     </select>
// //                   </div>
// //                   <div style={{ width: "37%" }}>
// //                     <b>Select Start Date</b>

// //                     {/* <DatePicker
// //                       ref={datePickerRef}
// //                       onClose={() => {
// //                         datePickerOnclose();
// //                       }}
// //                       numberOfMonths={2}
// //                       range
// //                       value={nextDates}
// //                       onChange={(date) => {
// //                         repopulateCalendarRoomsData(date);
// //                       }}
// //                       mapDays={({ date }) => renderDayContents(date)}
// //                       onMonthChange={(date) => {
// //                         repopulateCalendarRoomsData(date);
// //                       }}
// //                       minDate={moment(new Date()).format("yyyy-MM-DD")}
// //                       onKeyDown={handleEnterKey}
// //                     /> */}

// //                     <DatePicker
// //                       ref={datePickerRef}
// //                       onClose={() => {
// //                         datePickerOnclose();
// //                       }}
// //                       numberOfMonths={2}
// //                       range
// //                       value={nextDates}
// //                       onChange={(date) => {
// //                         repopulateCalendarRoomsData(date);
// //                       }}
// //                       mapDays={({ date }) => renderDayContents(date)}
// //                       onMonthChange={(date) => {
// //                         repopulateCalendarRoomsData(date);
// //                       }}
// //                       minDate={moment(new Date()).format("yyyy-MM-DD")}
// //                       onKeyDown={handleEnterKey}
// //                       editable={false} // Add this line to disable editing
// //                     />
// //                   </div>

// //                   <div style={{ width: "20%" }}>
// //                     <button
// //                       id="check_availability_button"
// //                       className="btnclass"
// //                       onClick={checkAvailability}
// //                       style={{ marginLeft: "-24%" }}
// //                     >
// //                       Check Availability
// //                     </button>
// //                   </div>
// //                 </div>
// //                 <br />
// //                 <div className="selected-rooms-list">
// //                   <h4>Selected Rooms:</h4>
// //                   <ul>
// //                     {selectedRooms.map((room) => (
// //                       <li key={room.room_id}>
// //                         <strong>Room Name:</strong> {room.room_name},{" "}
// //                         <strong>Price:</strong> Rs. {room.amount}/- per day
// //                       </li>
// //                     ))}
// //                   </ul>
// //                 </div>

// //                 <div
// //                   className="container"
// //                   style={{
// //                     display: "flex",
// //                     justifyContent: "end",
// //                     width: "100%",
// //                   }}
// //                 >
// //                   <div>
// //                     <button
// //                       className="booking_button btn btn2"
// //                       value="Book Rooms"
// //                       style={{ marginLeft: "20px" }}
// //                       onClick={() => {
// //                         const isLoggedIn = !!localStorage.getItem("token-info");

// //                         if (isLoggedIn) {
// //                           if (selectedRooms.length == 0) {
// //                             alert("Please selected atleast one room");
// //                             return;
// //                           }
// //                           navigate("/roomBookingPreview", "_self");
// //                         } else {
// //                           if (selectedRooms.length === 0) {
// //                             alert("Please select at least one room");
// //                           } else {
// //                             navigate("/Registration", "_self");
// //                           }
// //                         }
// //                       }}
// //                     >
// //                       Book Rooms
// //                     </button>

// //                     {userRole == 1 && (
// //                       <button
// //                         className="blocking_button  "
// //                         value="Book Rooms"
// //                         style={{ marginLeft: "20px" }}
// //                         onClick={() => {
// //                           const isLoggedIn =
// //                             !!localStorage.getItem("token-info");

// //                           if (isLoggedIn) {
// //                             if (selectedRooms.length == 0) {
// //                               alert("Please selected atleast one room");
// //                               return;
// //                             }
// //                             navigate("/roomBlockingPreview", "_self");
// //                           } else {
// //                             if (selectedRooms.length === 0) {
// //                               alert("Please select at least one room");
// //                             } else {
// //                               navigate("/Registration", "_self");
// //                             }
// //                           }
// //                         }}
// //                       >
// //                         Block Rooms
// //                       </button>
// //                     )}
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="">
// //               <div>
// //                 <h4>Accommodation details:</h4>
// //               </div>
// //             </div>
// //             <br />
// //             {roomsData.length == 0 && (
// //               <div className="message_text">No Rooms Available </div>
// //             )}
// //             <div className="container">
// //               <div>
// //                 {roomsData.map((e, i) => (
// //                   <div
// //                     className={
// //                       e.roomStatus === "Available"
// //                         ? "sr_card js-sr-card "
// //                         : "sr_card_red js-sr-card "
// //                     }
// //                   >
// //                     <div className="sr_card_photo">
// //                       <a href="#">
// //                         <img
// //                           alt=""
// //                           height="150"
// //                           width="200"
// //                           src={e.image_url}
// //                         />
// //                       </a>
// //                     </div>
// //                     <div className="sr_card_content">
// //                       <span>
// //                         <b>Accommodation Type</b>{" "}
// //                         &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
// //                         {e.accommodation_type}
// //                       </span>
// //                       <br />
// //                       <span>
// //                         <b>Room Number/Name</b>
// //                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
// //                         {e.room_name}
// //                       </span>
// //                       <br />
// //                       <span>
// //                         <b>Price per Room per Day</b> &nbsp;&nbsp;: Rs.
// //                         {e.amount}/- each Room
// //                       </span>
// //                       <br />

// //                       <br />
// //                       <br />
// //                       {e.roomStatus === "Available" && (
// //                         <div>
// //                           <span style={{ color: "green" }}>
// //                             Click here to select
// //                           </span>
// //                           &nbsp;&nbsp;&nbsp;&nbsp;
// //                           <input
// //                             className="largerCheckbox"
// //                             value="test"
// //                             type="checkbox"
// //                             id={"roomSelectCheckbox_" + e.room_id}
// //                             onChange={() => roomBooking(e.room_id)}
// //                           />
// //                         </div>
// //                       )}
// //                       {e.roomStatus === "Not Available" && (
// //                         <div style={{ color: "red" }}> {e.roomStatus}</div>
// //                       )}
// //                     </div>
// //                   </div>
// //                 ))}
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Accomodation;

import React, { useEffect, useState, useRef } from "react";

import Header from "./Header";
import "./Accomodation.css";
import DatePicker from "react-multi-date-picker";
import DateObject from "react-date-object";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import AccomodationImages from "./AccomodationImages";

const Accomodation = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [display, setDisplay] = useState("");
  const [placeId, setPlaceId] = useState("1");
  const [payId, setPayId] = useState("");
  const datePickerRef = useRef();

  let host = "https://api.vanadarshani.com";
  // let host = "http://localhost:5000";
  const [startDate, setStartDate] = useState(new Date());
  let localStoreNextDates = JSON.parse(localStorage.getItem("nextDates"));
  if (localStoreNextDates == undefined) {
    localStoreNextDates = [
      new DateObject().add(0, "days"),
      new DateObject().add(0, "days"),
    ];
  } else {
    localStoreNextDates = [
      new Date(localStoreNextDates[0]),
      new Date(localStoreNextDates[1]),
    ];
  }
  const [nextDates, setNextDates] = useState(localStoreNextDates);

  const [roomsData, setData] = React.useState([]);
  const [availableCalenderRoomsRes, setAvailableCalenderRoomsRes] =
    React.useState([]);
  // let selectedRooms = [];
  let selectedRoomsTmp = [];
  let selectedRoomDetails = {};

  const roomBooking = (roomId) => {
    const selectedRoom = roomsData.find((e) => e.room_id === roomId);
    const isChecked = selectedRooms.some((room) => room.room_id === roomId);

    setSelectedRooms((prevSelectedRooms) => {
      const updatedRooms = isChecked
        ? prevSelectedRooms.filter((room) => room.room_id !== roomId)
        : [...prevSelectedRooms, selectedRoom];

      const bookingDetailsData = {
        locationId: localStorage.getItem("newLocID"),
        date: moment(startDate).format("yyyy-MM-DD"),
        daysStay: document.getElementById("daysStay").value,
        selectedRooms: updatedRooms,
        selectedDateRange: nextDates,
        fromDate: moment(nextDates[0]).format("yyyy-MM-DD"),
        toDate: moment(nextDates[0]).format("yyyy-MM-DD"),
      };

      localStorage.setItem(
        "bookingDetailsData",
        JSON.stringify(bookingDetailsData)
      );

      return updatedRooms;
    });
  };

  const handleCheckAvailability = async () => {
    await checkAvailability();
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      if (document.activeElement.id === "daysStay") {
        changeDaysStay();
      } else {
        handleCheckAvailability();
      }
    }
  };

  useEffect(() => {
    checkAvailability();
    //checkAvailabilityButton();
    populateAvailableRoomsInCalender();
    document.addEventListener("keydown", handleEnterKey);
    return () => {
      document.removeEventListener("keydown", handleEnterKey);
    };
  }, []);

  const changeDaysStay = () => {
    let nextDate = new Date();
    let currentDayNo = nextDate.getDate();
    let nextDates = [];
    nextDates.push(new Date());
    for (
      let d = 1;
      d < parseInt(document.getElementById("daysStay").value);
      d++
    ) {
      nextDate = new Date();
      nextDate.setDate(currentDayNo + d);
      nextDates.push(nextDate);
    }
    setNextDates([
      new DateObject().add(0, "days"),
      new DateObject().add(
        parseInt(document.getElementById("daysStay").value) - 1,
        "days"
      ),
    ]);
    //setStartDate([new DateObject().add(4, "days")]);

    let tmpBookingDetailsData = JSON.parse(
      localStorage.getItem("bookingDetailsData")
    );
    if (tmpBookingDetailsData == undefined || tmpBookingDetailsData === "{}") {
      tmpBookingDetailsData = {};
    }
    tmpBookingDetailsData["daysStay"] =
      document.getElementById("daysStay").value;
    localStorage.setItem(
      "bookingDetailsData",
      JSON.stringify(tmpBookingDetailsData)
    );
    setData(roomsData);
  };

  const populateAvailableRoomsInCalender = (dateParam) => {
    // let talaId = "1";
    let tmpDates = [];
    nextDates.map((d) => {
      tmpDates.push(moment(d).format("yyyy-MM-DD"));
    });
    let dateQueryParam =
      dateParam != undefined ? dateParam : tmpDates.toString();

    let response = fetch(
      host +
        "/get-calendar-rooms?location=" +
        localStorage.getItem("newLocID") +
        "&date=" +
        dateQueryParam +
        "&time=" +
        Math.round(new Date().getTime() / 1000),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) =>
      response.json().then((availableCalenderRoomsRes) => {
        setAvailableCalenderRoomsRes(availableCalenderRoomsRes);
      })
    );
  };

  const populateAvailableRoomsInCalenderMamandur = (dateParam) => {
    // let mamandurId = "2";
    let tmpDates = [];
    nextDates.map((d) => {
      tmpDates.push(moment(d).format("yyyy-MM-DD"));
    });
    let dateQueryParam =
      dateParam != undefined ? dateParam : tmpDates.toString();

    let response = fetch(
      host +
        "/get-calendar-rooms?location=" +
        localStorage.getItem("newLocID") +
        "&date=" +
        dateQueryParam +
        "&time=" +
        Math.round(new Date().getTime() / 1000),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) =>
      response.json().then((availableCalenderRoomsRes) => {
        setAvailableCalenderRoomsRes(availableCalenderRoomsRes);
      })
    );
  };

  const checkAvailability = async (selectedDates) => {
    let response = await fetch(
      host +
        "/get-rooms?location=" +
        localStorage.getItem("newLocID") +
        "&time=" +
        Math.round(new Date().getTime() / 1000),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) =>
      response.json().then((roomsRes) => {
        setData(roomsRes);
        let tmpDates = [];
        if (selectedDates !== undefined) {
          try {
            selectedDates.map((d) => {
              tmpDates.push(moment(d).format("yyyy-MM-DD"));
            });
          } catch (e) {}
        }
        if (tmpDates.length == 0) {
          nextDates.map((d) => {
            tmpDates.push(moment(d).format("yyyy-MM-DD"));
          });
        }
        response = fetch(
          host +
            "/get-booked-rooms?date=" +
            tmpDates.toString() +
            "&location=" +
            localStorage.getItem("newLocID") +
            "&time=" +
            Math.round(new Date().getTime() / 1000),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((response) =>
          response.json().then((res) => {
            if (res != null) {
              let tmpRoomsData = [];
              roomsRes.forEach((element) => {
                let data = res.find((item) => {
                  return item.room_id == element.room_id;
                });
                if (data != null) {
                  element.roomStatus = "Not Available";
                } else {
                  element.roomStatus = "Available";
                }
                tmpRoomsData.push(element);
              });
              setData(tmpRoomsData);
            }
          })
        );
      })
    );
  };

  const checkAvailabilityTalakonaBtn = async (selectedDates) => {
    //setPlaceId()
    // console.log(placeId, "placeIDDDDDD..............");
    // var talaId = "1";
    let response = await fetch(
      host +
        "/get-rooms?location=" +
        localStorage.getItem("newLocID") +
        "&time=" +
        Math.round(new Date().getTime() / 1000),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) =>
      response.json().then((roomsRes) => {
        setData(roomsRes);
        let tmpDates = [];
        if (selectedDates !== undefined) {
          try {
            selectedDates.map((d) => {
              tmpDates.push(moment(d).format("yyyy-MM-DD"));
            });
          } catch (e) {}
        }
        if (tmpDates.length == 0) {
          nextDates.map((d) => {
            tmpDates.push(moment(d).format("yyyy-MM-DD"));
          });
        }
        response = fetch(
          host +
            "/get-booked-rooms?date=" +
            tmpDates.toString() +
            "&location=" +
            localStorage.getItem("newLocID") +
            "&time=" +
            Math.round(new Date().getTime() / 1000),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((response) =>
          response.json().then((res) => {
            if (res != null) {
              let tmpRoomsData = [];
              roomsRes.forEach((element) => {
                let data = res.find((item) => {
                  return item.room_id == element.room_id;
                });
                if (data != null) {
                  element.roomStatus = "Not Available";
                } else {
                  element.roomStatus = "Available";
                }
                tmpRoomsData.push(element);
              });
              setData(tmpRoomsData);
            }
          })
        );
      })
    );
  };

  const checkAvailabilityButton = async (selectedDates) => {
    //setPlaceId()
    // console.log(placeId, "placeIDDDDDD..............");
    // var mamandurId = "2";
    let response = await fetch(
      host +
        "/get-rooms?location=" +
        localStorage.getItem("newLocID") +
        "&time=" +
        Math.round(new Date().getTime() / 1000),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) =>
      response.json().then((roomsRes) => {
        setData(roomsRes);
        let tmpDates = [];
        if (selectedDates !== undefined) {
          try {
            selectedDates.map((d) => {
              tmpDates.push(moment(d).format("yyyy-MM-DD"));
            });
          } catch (e) {}
        }
        if (tmpDates.length == 0) {
          nextDates.map((d) => {
            tmpDates.push(moment(d).format("yyyy-MM-DD"));
          });
        }
        response = fetch(
          host +
            "/get-booked-rooms?date=" +
            tmpDates.toString() +
            "&location=" +
            localStorage.getItem("newLocID") +
            "&time=" +
            Math.round(new Date().getTime() / 1000),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((response) =>
          response.json().then((res) => {
            if (res != null) {
              let tmpRoomsData = [];
              roomsRes.forEach((element) => {
                let data = res.find((item) => {
                  return item.room_id == element.room_id;
                });
                if (data != null) {
                  element.roomStatus = "Not Available";
                } else {
                  element.roomStatus = "Available";
                }
                tmpRoomsData.push(element);
              });
              setData(tmpRoomsData);
            }
          })
        );
      })
    );
  };

  useEffect(() => {
    checkAvailability();
    // checkAvailabilityButton();
    populateAvailableRoomsInCalender();
  }, []);

  const renderDayContents = (date) => {
    {
      let month = date.month <= 9 ? "0" + date.month : date.month;
      let calDay = date.day;
      let dateDay =
        date.year + "-" + month + "-" + (calDay <= 9 ? "0" + calDay : calDay);
      const availableCount = availableCalenderRoomsRes[dateDay] || 0;
      const notAvailableCount = roomsData.length - availableCount;

      return {
        children: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0 10px",
              fontSize: "11px",
            }}
          >
            <div style={{ fontSize: "15px", height: "20px" }}>{date.day}</div>

            <div
              className="callend_available_green"
              style={{ textAlign: "center" }}
            >
              {availableCount > 0 ? `${availableCount} Ava` : ""}
              <br />
              {/* {availableCount > 0 && notAvailableCount > 0 ? "+" : ""} */}
            </div>
            <div
              className="callend_available_red"
              style={{ textAlign: "center" }}
            >
              {notAvailableCount > 0 ? `${notAvailableCount} booked` : ""}
            </div>
          </div>
        ),
      };
    }
  };

  const repopulateCalendarRoomsData = (date) => {
    let toDate = new Date(date);
    toDate.setDate(
      toDate.getDate() + parseInt(document.getElementById("daysStay").value - 1)
    );

    let tmpDates = [new Date(date), toDate];

    //setStartDate(tmpDates);
    setNextDates(tmpDates);
    let tmpBookingDetailsData = JSON.parse(
      localStorage.getItem("bookingDetailsData")
    );
    if (tmpBookingDetailsData == undefined || tmpBookingDetailsData === "{}") {
      tmpBookingDetailsData = {};
    }
    localStorage.setItem(
      "bookingDetailsData",
      JSON.stringify(tmpBookingDetailsData)
    );
    populateAvailableRoomsInCalender(moment(toDate).format("yyyy-MM-DD"));
    populateAvailableRoomsInCalenderMamandur(
      moment(toDate).format("yyyy-MM-DD")
    );
    localStorage.setItem("nextDates", JSON.stringify(tmpDates));
    datePickerOnclose();
    // checkAvailability(tmpDates);
    checkAvailabilityButton(tmpDates);
    checkAvailabilityTalakonaBtn(tmpDates);
    datePickerRef.current.closeCalendar();
  };
  //prevent onclose calendar actions and populating selected date range
  const datePickerOnclose = () => {
    let localStoreNextDates = JSON.parse(localStorage.getItem("nextDates"));
    if (localStoreNextDates != undefined) {
      localStoreNextDates = [
        new Date(localStoreNextDates[0]),
        new Date(localStoreNextDates[1]),
      ];
      setNextDates(localStoreNextDates);
    }
  };
  const onChangeLocation = () => {
    populateAvailableRoomsInCalender(moment(nextDates[0]).format("yyyy-MM-DD"));
    //  populateAvailableRoomsInCalender(moment(nextDates[0]).format("yyyy-MM-DD"));
    checkAvailability();
  };

  const onChangeLocationButton = (id) => {
    localStorage.setItem("newLocID", id);
    // alert(id);
    // setPayId(id);
    //alert(id);
    setDisplay("2");
    populateAvailableRoomsInCalenderMamandur(
      moment(nextDates[0]).format("yyyy-MM-DD")
    );
    // alert(payId);

    checkAvailabilityButton();
  };

  const onChangeTalaLocationButton = (id) => {
    // localStorage.clear();
    localStorage.setItem("newLocID", id);
    setDisplay("1");
    // alert(id);
    // setPayId(id);
    //alert(id);
    populateAvailableRoomsInCalender(moment(nextDates[0]).format("yyyy-MM-DD"));

    //  alert(payId);

    checkAvailabilityTalakonaBtn();
  };

  //[{ "accommodation_type": "Loghut", "room_number": "Loghut-3 (First floor)", "price": "Rs. 1500/- each Room", "persons": "2", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/accomodation/talakona/talakona-room-booking-online-2.gif" }, { "accommodation_type": "Loghut", "room_number": "Loghut-4 (First floor)", "price": "Rs. 1500/- each Room", "persons": "2", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/accomodation/talakona/talakona-room-booking-online-2.gif" }, { "accommodation_type": "Loghut", "room_number": "Loghut-5 (First floor)", "price": "Rs. 1250/- each Room", "persons": "2", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/accomodation/talakona/talakona-room-booking-online-2.gif" }, { "accommodation_type": "Loghut", "room_number": "Loghut-5 (First floor)", "price": "Rs. 1250/- each Room", "persons": "2", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/accomodation/talakona/talakona-room-booking-online-2.gif" }, { "accommodation_type": "Dormitory", "room_number": "Dormitory-1", "price": "Rs. 5000/- each Room", "persons": "10", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/i1.JPG" }, { "accommodation_type": "Dormitory", "room_number": "Dormitory-1", "price": "Rs. 5000/- each Room", "persons": "10", "additional_chargers": "Note: For an extra person, there is an additional charge of Rs. 100/- (only one person).", "img": "http://www.vanadarshani.in/images/i1.JPG" }]
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back">
            <AccomodationImages />
            <br /> <b></b>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
              <h1
                className="text-center"
                style={{ fontSize: "25px", color: "green" }}
              >
                ACCOMMODATION
              </h1>
              <div className="container">
                <div className="">
                  <br />
                  <p>Accommodation is available at Talakona and Mamandur.</p>
                </div>
              </div>
              <div className="location-buttons-container">
                <button
                  className="location-button talakona"
                  onClick={() => onChangeTalaLocationButton("1")}
                >
                  Talakona
                </button>
                <button
                  className="location-button mamandur"
                  onClick={() => onChangeLocationButton("2")}
                >
                  Mamandur
                </button>
              </div>
              <br />
              <br />
              <div className="pt-10">
                <div className="container-row">
                  <div style={{ width: "20%", display: "none" }}>
                    <b>Select Location</b>

                    <select
                      id="locationIds"
                      onChange={onChangeLocation}
                      onKeyDown={handleEnterKey}
                    >
                      <option value="1">Talakona</option>
                      <option value="2">Mamandur</option>
                    </select>
                  </div>
                  <div style={{ width: "11%" }}>
                    <b>Days Stay</b>
                    <select
                      id="daysStay"
                      onChange={() => {
                        changeDaysStay();
                      }}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                  <div style={{ width: "37%" }}>
                    <b>Select Start Date</b>

                    {/* <DatePicker
                      ref={datePickerRef}
                      onClose={() => {
                        datePickerOnclose();
                      }}
                      numberOfMonths={2}
                      range
                      value={nextDates}
                      onChange={(date) => {
                        repopulateCalendarRoomsData(date);
                      }}
                      mapDays={({ date }) => renderDayContents(date)}
                      onMonthChange={(date) => {
                        repopulateCalendarRoomsData(date);
                      }}
                      minDate={moment(new Date()).format("yyyy-MM-DD")}
                      onKeyDown={handleEnterKey}
                    /> */}

                    <DatePicker
                      ref={datePickerRef}
                      onClose={() => {
                        datePickerOnclose();
                      }}
                      numberOfMonths={2}
                      range
                      value={nextDates}
                      onChange={(date) => {
                        repopulateCalendarRoomsData(date);
                      }}
                      mapDays={({ date }) => renderDayContents(date)}
                      onMonthChange={(date) => {
                        repopulateCalendarRoomsData(date);
                      }}
                      minDate={moment(new Date()).format("yyyy-MM-DD")}
                      onKeyDown={handleEnterKey}
                      editable={false} // Add this line to disable editing
                    />
                  </div>

                  <div style={{ width: "20%" }}>
                    <button
                      id="check_availability_button"
                      className="btnclass"
                      onClick={checkAvailability}
                      style={{ marginLeft: "-24%" }}
                    >
                      Check Availability
                    </button>
                  </div>
                </div>
                <br />
                <div className="selected-rooms-list">
                  <h4>Selected Rooms:</h4>
                  <ul>
                    {selectedRooms.map((room) => (
                      <li key={room.room_id}>
                        <strong>Room Name:</strong> {room.room_name},{" "}
                        <strong>Price:</strong> Rs. {room.amount}/- per day
                      </li>
                    ))}
                  </ul>
                </div>

                <div
                  className="container"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "100%",
                  }}
                >
                  <div>
                    <button
                      className="booking_button btn btn2"
                      value="Book Rooms"
                      style={{ marginLeft: "20px" }}
                      onClick={() => {
                        const isLoggedIn = !!localStorage.getItem("token-info");

                        if (isLoggedIn) {
                          if (selectedRooms.length == 0) {
                            alert("Please selected atleast one room");
                            return;
                          }
                          navigate("/roomBookingPreview", "_self");
                        } else {
                          if (selectedRooms.length === 0) {
                            alert("Please select at least one room");
                          } else {
                            navigate("/Registration", "_self");
                          }
                        }
                      }}
                    >
                      Book Rooms
                    </button>

                    {userRole == 1 && (
                      <button
                        className="blocking_button  "
                        value="Book Rooms"
                        style={{ marginLeft: "20px" }}
                        onClick={() => {
                          const isLoggedIn =
                            !!localStorage.getItem("token-info");

                          if (isLoggedIn) {
                            if (selectedRooms.length == 0) {
                              alert("Please selected atleast one room");
                              return;
                            }
                            navigate("/roomBlockingPreview", "_self");
                          } else {
                            if (selectedRooms.length === 0) {
                              alert("Please select at least one room");
                            } else {
                              navigate("/Registration", "_self");
                            }
                          }
                        }}
                      >
                        Block Rooms
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div></div>
              <div className="" style={{ color: "green" }}>
                {display === "1" && <h2>Accommodation details For Talakona</h2>}
                {display === "2" && <h2>Accommodation details For Mamandur</h2>}
              </div>
            </div>
            <br />
            {roomsData.length == 0 && (
              <div className="message_text">No Rooms Available </div>
            )}
            <div className="container">
              <div>
                {roomsData.map((e, i) => (
                  <div
                    className={
                      e.roomStatus === "Available"
                        ? "sr_card js-sr-card "
                        : "sr_card_red js-sr-card "
                    }
                  >
                    <div className="sr_card_photo">
                      <a href="#">
                        <img
                          alt=""
                          height="150"
                          width="200"
                          src={e.image_url}
                        />
                      </a>
                    </div>
                    <div className="sr_card_content">
                      <span>
                        <b>Accommodation Type</b>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                        {e.accommodation_type}
                      </span>
                      <br />
                      <span>
                        <b>Room Number/Name</b>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                        {e.room_name}
                      </span>
                      <br />
                      <span>
                        <b>Price per Room per Day</b> &nbsp;&nbsp;: Rs.
                        {e.amount}/- each Room
                      </span>
                      <br />

                      <br />
                      <br />
                      {e.roomStatus === "Available" && (
                        <div>
                          <span style={{ color: "green" }}>
                            Click here to select
                          </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            className="largerCheckbox"
                            value="test"
                            type="checkbox"
                            id={"roomSelectCheckbox_" + e.room_id}
                            onChange={() => roomBooking(e.room_id)}
                          />
                        </div>
                      )}
                      {e.roomStatus === "Not Available" && (
                        <div style={{ color: "red" }}> {e.roomStatus}</div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accomodation;
