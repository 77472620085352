import React from "react";
import Navbar from "../components/Navbar";
import Header from "./Header";
import { Link } from "react-router-dom";
import PulibonuImages from "./PulibonuImages";

const Pulibonu = () => {
  return (
    <div>
    
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div className="col-lg-1O col-md-10 col-sm-10 col-xs-10 content_back">
          <PulibonuImages/>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                
                <div className="row1">
                  <br />
                
                  <div className="col-lg-12">
                    <h1
                      className="text-center"
                      style={{ fontSize: "28px", color: "green" }}
                    >
                      JUNGLE SAFARI-KALYANI DAM-PULIBONU
                    </h1>
                  </div>
                  {/* <div className="col-lg-4">
                    <div className="online_booking">
                      <Link to="/Accomodation">
                        <img
                          src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/vana-images/roombooking_new.jpg"
                          className="img-responsive"
                          alt="Online Booking"
                          style={{ width: "100%", height: "auto" }}
                        />
                      </Link>
                    </div>
                  </div> */}
                  <div className="AttractionItems">
                    <br />
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="row">
                        <h4>Objective &nbsp;:</h4>
                        <p>
                          Jungle safari is provided by Forest department, Tirupati District to create awareness among the public
                          towards wildlife conservation education.
                        </p>
                        <h4>Location&nbsp;:</h4>
                        <p>
                          Rangampet, Chandragiri mandal, Tirupati district near
                          Kalyani Dam.
                        </p>

                        <h4>Distance&nbsp;:</h4>
                        <p>15 KM from Tirupati town.</p>

                        <h4>Activities&nbsp;:</h4>
                        <p>
                          Jungle safari: The safari trip covers a 15 km distance
                          in the forest covering Kalyani Dam, Naucharam Cheruvu.
                          Each trip costs Rs.2000/- per trip (Max 10 persons).
                        </p>
                        <h4>Timing&nbsp;:</h4>
                        <p>Morning 6 AM to Evening 5 PM</p>

                        <h4>Contact Numbers&nbsp;:</h4>
                        <ul>
                          <li>Deputy Range Officer, Nagapatla: 9177802062</li>
                          <li>Forest Range Officer, Bhakarapet: 8978080419</li>
                        </ul>

                        <br />
                        <br />
                        {/* <div className="online_booking">
                          <Link to="/accomodation">
                            <img
                              src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/vana-images/roombooking_new.jpg"
                              className="img-responsive"
                              alt="Online Booking"
                            />
                          </Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="clearfix">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pulibonu;
