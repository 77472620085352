// // import React, { useState } from "react";
// // import axios from "axios";
// // import { Link, useNavigate } from "react-router-dom";
// // import Navbar from "../components/Navbar";
// // import Header from "../components/Header";
// // import "./Login.css";

// // const ForgotPassword = () => {
// //   const [mobile_number, setMobile_number] = useState("");
// //   const [otp, setOtp] = useState("");
// //   const [newPassword, setNewPassword] = useState("");
// //   const [confirmPassword, setConfirmPassword] = useState("");
// //   const [step, setStep] = useState(1); // 1: Enter mobile number, 2: Enter OTP, 3: Reset password
// //   const [userId, setUserId] = useState(null);
// //   const [error, setError] = useState("");
// //   const navigate = useNavigate();

// //   const handleSendOtp = async () => {
// //     try {
// //       const response = await axios.post(
// //         "https://api.vanadarshani.com/userotplogin",
// //         {
// //           mobile_number,
// //         }
// //       );

// //       if (response.data.status === 200) {
// //         setStep(2);
// //         setUserId(response.data.data);
// //       } else {
// //         alert("Invalid Mobile Number");
// //       }
// //     } catch (error) {
// //       console.error("Error sending OTP:", error.message);
// //     }
// //   };

// //   const handleVerifyOtp = async () => {
// //     try {
// //       const response = await axios.post(
// //         "https://api.vanadarshani.com/userverifyotp",
// //         {
// //           otp,
// //           username: userId,
// //           mobile_number,
// //         }
// //       );

// //       if (response.data.status === 200) {
// //         setStep(3);
// //       } else {
// //         alert("OTP not matched");
// //       }
// //     } catch (error) {
// //       console.error("Error verifying OTP:", error.message);
// //     }
// //   };

// //   const handleUpdatePassword = async () => {
// //     try {
// //       if (!newPassword || !confirmPassword) {
// //         alert("Please enter both new password and confirm password.");
// //         return;
// //       }

// //       if (newPassword !== confirmPassword) {
// //         alert("Passwords do not match.");
// //         return;
// //       }

// //       const passwordRegex =
// //         /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

// //       if (!passwordRegex.test(newPassword)) {
// //         setError(
// //           "New password must be at least 6 characters long and contain alphabets, numbers, and special characters."
// //         );
// //         return;
// //       }
// //       const response = await axios.post(
// //         "https://api.vanadarshani.com/updateuserpassword",
// //         {
// //           username: mobile_number,
// //           password: newPassword,
// //         }
// //       );

// //       if (response.data.status === 200) {
// //         alert("Password updated successfully!");
// //         navigate("/login");
// //       } else {
// //         alert("Error updating password.");
// //       }
// //     } catch (error) {
// //       console.error("Error updating password:", error.message);
// //     }
// //   };

// //   return (
// //     <div>
// //       <Header />

// //       <div className="container">
// //         <div className="row">
// //           <div className="col-lg-2"></div>
// //           <div
// //             className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back"
// //             style={{ backgroundColor: "#e0ff97" }}
// //           >
// //             <h1
// //               className="text-center"
// //               style={{ fontSize: "25px", color: "green" }}
// //             >
// //               {step === 1
// //                 ? "Forgot Password"
// //                 : step === 2
// //                 ? "Verify OTP"
// //                 : "Update Password"}
// //             </h1>
// //             <div className="container mt-5">
// //               {step === 1 && (
// //                 <form className="for-password">
// //                   <div className="mb-3">
// //                     <label>Mobile Number</label>
// //                     <br />
// //                     <input
// //                       type="text"
// //                       value={mobile_number}
// //                       onChange={(e) => setMobile_number(e.target.value)}
// //                     />
// //                   </div>
// //                   <br />
// //                   <button
// //                     type="button"
// //                     className="btns1 btn2"
// //                     onClick={handleSendOtp}
// //                   >
// //                     Send OTP
// //                   </button>
// //                 </form>
// //               )}
// //               {step === 2 && (
// //                 <form className="for-password">
// //                   <div className="mb-3">
// //                     <label>Enter OTP</label>
// //                     <br />
// //                     <input
// //                       type="text"
// //                       value={otp}
// //                       onChange={(e) => setOtp(e.target.value)}
// //                     />
// //                   </div>
// //                   <button
// //                     type="button"
// //                     className="btns1  btn1"
// //                     onClick={handleVerifyOtp}
// //                   >
// //                     Verify OTP
// //                   </button>
// //                 </form>
// //               )}
// //               {step === 3 && (
// //                 <form className="for-password">
// //                   <div className="mb-3">
// //                     <label>New Password:</label>
// //                     <br />
// //                     <input
// //                       type="password"
// //                       value={newPassword}
// //                       onChange={(e) => setNewPassword(e.target.value)}
// //                     />
// //                   </div>
// //                   <div className="mb-3">
// //                     <label>Confirm Password:</label>
// //                     <br />
// //                     <input
// //                       type="password"
// //                       value={confirmPassword}
// //                       onChange={(e) => setConfirmPassword(e.target.value)}
// //                     />
// //                   </div>
// //                   <br />
// //                   <button
// //                     type="button"
// //                     className="btns1 btn3"
// //                     onClick={handleUpdatePassword}
// //                   >
// //                     Update Password
// //                   </button>
// //                 </form>
// //               )}
// //               {step !== 3 && (
// //                 <div className="mt-3">
// //                   <br />
// //                   <p
// //                     className="mt-3"
// //                     style={{ textAlign: "center", marginLeft: "-30px" }}
// //                   >
// //                     Remember your password? <Link to="/login">Login Here</Link>
// //                   </p>
// //                 </div>
// //               )}
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default ForgotPassword;

// import React, { useState } from "react";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
// import Navbar from "../components/Navbar";
// import Header from "../components/Header";
// import "./Login.css";

// const ForgotPassword = () => {
//   const [username, setUsername] = useState("");
//   const [mobile_number, setMobile_number] = useState("");
//   const [otp, setOtp] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [step, setStep] = useState(1); // 1: Enter mobile number, 2: Enter OTP, 3: Reset password
//   const [userId, setUserId] = useState(null);
//   const [error, setError] = useState("");
//   const navigate = useNavigate();

//   const handleSendOtp = async () => {
//     try {
//       const response = await axios.post(
//         "https://api.vanadarshani.com/userotplogin",
//         {
//           mobile_number,
//           username,
//         }
//       );

//       if (response.data.status === 200) {
//         setStep(2);
//         setUserId(response.data.data.userId); // Assuming `userId` is correctly returned from API
//       } else {
//         alert("Invalid Mobile Number or Username");
//       }
//     } catch (error) {
//       console.error("Error sending OTP:", error.message);
//     }
//   };

//   const handleVerifyOtp = async () => {
//     try {
//       const response = await axios.post(
//         "https://api.vanadarshani.com/userverifyotp",
//         {
//           otp,
//           username,
//           mobile_number,
//         }
//       );

//       if (response.data.status === 200) {
//         setStep(3);
//       } else {
//         alert("OTP not matched");
//       }
//     } catch (error) {
//       console.error("Error verifying OTP:", error.message);
//     }
//   };

//   // const handleUpdatePassword = async () => {
//   //   try {
//   //     if (!newPassword || !confirmPassword) {
//   //       alert("Please enter both new password and confirm password.");
//   //       return;
//   //     }

//   //     if (newPassword !== confirmPassword) {
//   //       alert("Passwords do not match.");
//   //       return;
//   //     }

//   //     const passwordRegex =
//   //       /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

//   //     if (!passwordRegex.test(newPassword)) {
//   //       setError(
//   //         "New password must be at least 6 characters long and contain alphabets, numbers, and special characters."
//   //       );
//   //       return;
//   //     }
//   //     const response = await axios.post(
//   //       "https://api.vanadarshani.com/updateuserpassword",
//   //       {
//   //         username,
//   //         password: newPassword,
//   //       }
//   //     );

//   //     if (response.data.status === 200) {
//   //       alert("Password updated successfully!");
//   //       navigate("/login");
//   //     } else {
//   //       alert("Error updating password.");
//   //     }
//   //   } catch (error) {
//   //     console.error("Error updating password:", error.message);
//   //   }
//   // };

//   const handleUpdatePassword = async () => {
//     try {
//       if (!newPassword || !confirmPassword) {
//         alert("Please enter both new password and confirm password.");
//         return;
//       }

//       if (newPassword !== confirmPassword) {
//         alert("Passwords do not match.");
//         return;
//       }

//       const passwordRegex =
//         /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

//       if (!passwordRegex.test(newPassword)) {
//         setError(
//           "New password must be at least 6 characters long and contain alphabets, numbers, and special characters."
//         );
//         return;
//       }
//       const response = await axios.post(
//         "https://api.vanadarshani.com/updateuserpassword",
//         {
//           username: mobile_number,
//           password: newPassword,
//         }
//       );

//       if (response.data.status === 200) {
//         alert("Password updated successfully!");
//         navigate("/login");
//       } else {
//         alert("Error updating password.");
//       }
//     } catch (error) {
//       console.error("Error updating password:", error.message);
//     }
//   };
//   return (
//     <div>
//       <Header />

//       <div className="container">
//         <div className="row">
//           <div className="col-lg-2"></div>
//           <div
//             className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back"
//             style={{ backgroundColor: "#e0ff97" }}
//           >
//             <h1
//               className="text-center"
//               style={{ fontSize: "25px", color: "green" }}
//             >
//               {step === 1
//                 ? "Forgot Password"
//                 : step === 2
//                 ? "Verify OTP"
//                 : "Update Password"}
//             </h1>
//             <div className="container mt-5">
//               {step === 1 && (
//                 <form className="for-password">
//                   <div className="mb-3">
//                     <label>Username</label>
//                     <br />
//                     <input
//                       type="text"
//                       value={username}
//                       onChange={(e) => setUsername(e.target.value)}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label>Mobile Number</label>
//                     <br />
//                     <input
//                       type="text"
//                       value={mobile_number}
//                       onChange={(e) => setMobile_number(e.target.value)}
//                     />
//                   </div>
//                   <br />
//                   <button
//                     type="button"
//                     className="btns1 btn2"
//                     onClick={handleSendOtp}
//                   >
//                     Send OTP
//                   </button>
//                 </form>
//               )}
//               {step === 2 && (
//                 <form className="for-password">
//                   <div className="mb-3">
//                     <label>Enter OTP</label>
//                     <br />
//                     <input
//                       type="text"
//                       value={otp}
//                       onChange={(e) => setOtp(e.target.value)}
//                     />
//                   </div>
//                   <button
//                     type="button"
//                     className="btns1  btn1"
//                     onClick={handleVerifyOtp}
//                   >
//                     Verify OTP
//                   </button>
//                 </form>
//               )}
//               {step === 3 && (
//                 <form className="for-password">
//                   <div className="mb-3">
//                     <label>New Password:</label>
//                     <br />
//                     <input
//                       type="password"
//                       value={newPassword}
//                       onChange={(e) => setNewPassword(e.target.value)}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label>Confirm Password:</label>
//                     <br />
//                     <input
//                       type="password"
//                       value={confirmPassword}
//                       onChange={(e) => setConfirmPassword(e.target.value)}
//                     />
//                   </div>
//                   <br />
//                   <button
//                     type="button"
//                     className="btns1 btn3"
//                     onClick={handleUpdatePassword}
//                   >
//                     Update Password
//                   </button>
//                 </form>
//               )}
//               {step !== 3 && (
//                 <div className="mt-3">
//                   <br />
//                   <p
//                     className="mt-3"
//                     style={{ textAlign: "center", marginLeft: "-30px" }}
//                   >
//                     Remember your password? <Link to="/login">Login Here</Link>
//                   </p>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ForgotPassword;

import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import "./Login.css";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1); // 1: Enter mobile number, 2: Enter OTP, 3: Reset password
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSendOtp = async () => {
    try {
      const response = await axios.post(
        "https://api.vanadarshani.com/userotplogin",
        {
          mobile_number,
          username,
        }
      );

      if (response.data.status === 200) {
        setStep(2);
        setUserId(response.data.data.userId); // Assuming `userId` is correctly returned from API
      } else {
        alert("Invalid Mobile Number or Username");
      }
    } catch (error) {
      console.error("Error sending OTP:", error.message);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        "https://api.vanadarshani.com/userverifyotp",
        {
          otp,
          username,
          mobile_number,
        }
      );

      if (response.data.status === 200) {
        setStep(3);
      } else {
        alert("OTP not matched");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error.message);
    }
  };

  // const handleUpdatePassword = async () => {
  //   console.log("handleUpdatePassword called"); // Add this log
  //   try {
  //     if (!newPassword || !confirmPassword) {
  //       alert("Please enter both new password and confirm password.");
  //       return;
  //     }

  //     if (newPassword !== confirmPassword) {
  //       alert("Passwords do not match.");
  //       return;
  //     }

  //     const passwordRegex =
  //       /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

  //     if (!passwordRegex.test(newPassword)) {
  //       setError(
  //         "New password must be at least 6 characters long and contain alphabets, numbers, and special characters."
  //       );
  //       return;
  //     }

  //     console.log("Sending update password request:", {
  //       username,
  //       password: newPassword,
  //     });

  //     const response = await axios.post(
  //       "https://api.vanadarshani.com/updateuserpassword",
  //       {
  //         username,
  //         password: newPassword,
  //       }
  //     );

  //     console.log("Update password response:", response); // Add this log

  //     if (response.data.status === 200) {
  //       alert("Password updated successfully!");
  //       navigate("/login");
  //     } else {
  //       alert("Error updating password.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating password:", error.message);
  //   }
  // };
  // const handleUpdatePassword = async () => {
  //   console.log("handleUpdatePassword called"); // Add this log
  //   try {
  //     if (!newPassword || !confirmPassword) {
  //       alert("Please enter both new password and confirm password.");
  //       return;
  //     }

  //     if (newPassword !== confirmPassword) {
  //       alert("Passwords do not match.");
  //       return;
  //     }

  //     const passwordRegex =
  //       /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

  //     if (!passwordRegex.test(newPassword)) {
  //       setError(
  //         "New password must be at least 6 characters long and contain alphabets, numbers, and special characters."
  //       );
  //       return;
  //     }

  //     console.log("Sending update password request:", {
  //       username,
  //       password: newPassword,
  //     });

  //     const response = await axios.post(
  //       "https://api.vanadarshani.com/updateuserpassword",
  //       {
  //         username,
  //         password: newPassword,
  //       }
  //     );

  //     console.log("Update password response:", response); // Add this log

  //     if (response.data.status === 200) {
  //       alert("Password updated successfully!");
  //       navigate("/login");
  //     } else {
  //       alert("Error updating password.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating password:", error.message);
  //   }
  // };

  const handleUpdatePassword = async () => {
    console.log("handleUpdatePassword called"); // Add this log
    try {
      if (!newPassword || !confirmPassword) {
        alert("Please enter both new password and confirm password.");
        return;
      }

      if (newPassword !== confirmPassword) {
        alert("Passwords do not match.");
        return;
      }

      const passwordRegex =
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

      if (!passwordRegex.test(newPassword)) {
        setError(
          "New password must be at least 6 characters long and contain alphabets, numbers, and special characters."
        );
        return;
      }

      console.log("Sending update password request:", {
        username,
        password: newPassword,
      });

      const response = await axios.post(
        "https://api.vanadarshani.com/updateuserpassword",
        {
          username,
          password: newPassword,
        }
      );

      console.log("Update password response:", response); // Add this log

      if (response.data.status === 200) {
        alert("Password updated successfully!");
        navigate("/login");
      } else {
        alert("Error updating password.");
      }
    } catch (error) {
      console.error("Error updating password:", error.message);
    }
  };

  return (
    <div>
      <Header />

      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div
            className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back"
            style={{ backgroundColor: "#e0ff97" }}
          >
            <h1
              className="text-center"
              style={{ fontSize: "25px", color: "green" }}
            >
              {step === 1
                ? "Forgot Password"
                : step === 2
                ? "Verify OTP"
                : "Update Password"}
            </h1>
            <div className="container mt-5">
              {step === 1 && (
                <form className="for-password">
                  <div className="mb-3">
                    <label>Username</label>
                    <br />
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Mobile Number</label>
                    <br />
                    <input
                      type="text"
                      value={mobile_number}
                      onChange={(e) => setMobile_number(e.target.value)}
                    />
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btns1 btn2"
                    onClick={handleSendOtp}
                  >
                    Send OTP
                  </button>
                </form>
              )}
              {step === 2 && (
                <form className="for-password">
                  <div className="mb-3">
                    <label>Enter OTP</label>
                    <br />
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btns1  btn1"
                    onClick={handleVerifyOtp}
                  >
                    Verify OTP
                  </button>
                </form>
              )}
              {step === 3 && (
                <form className="for-password">
                  <div className="mb-3">
                    <label>New Password:</label>
                    <br />
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Confirm Password:</label>
                    <br />
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btns1 btn3"
                    onClick={handleUpdatePassword}
                  >
                    Update Password
                  </button>
                </form>
              )}
              {step !== 3 && (
                <div className="mt-3">
                  <br />
                  <p
                    className="mt-3"
                    style={{ textAlign: "center", marginLeft: "-30px" }}
                  >
                    Remember your password? <Link to="/login">Login Here</Link>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
