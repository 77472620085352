// import React, { useState } from "react";

// import Header from "./Header";
// import { Navigate, useNavigate } from "react-router-dom";
// import "./Accomodation.css";

// const Reports = () => {
//   const [selectedLocation, setSelectedLocation] = useState("1"); // Default to Talakona
//   const navigate = useNavigate();
//   const handleLocationChange = (event) => {
//     setSelectedLocation(event.target.value);
//   };
//   const refresh = () => {
//     const newTab = window.open(
//       "https://prod-08.centralindia.logic.azure.com/workflows/7dd258a29ade4efa9522583431d1f47b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=JEOqZ0ssLUFQJij_D0VK4ueDY0aRqJpI1Ez3T0oT53w",
//       "_blank"
//     );

//     setTimeout(function () {
//       if (newTab) {
//         newTab.close();
//         window.location.reload(true);
//         navigate("/reports");
//       } else {
//         alert("Power Bi not working");
//       }
//     }, 10000);
//     // navigate("/reports", "_self");
//   };

//   return (
//     <div>
//       <Header />
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-2"></div>
//           <div className="col-lg-10 content_back">
//             <div className="row" style={{ marginTop: "-47px" }}>
//               <div className="col"></div>
//               <div
//                 className="col"
//                 style={{ display: "flex", justifyContent: "flex-end" }}
//               >
//                 <span
//                   style={{
//                     fontSize: "xx-small",
//                     marginTop: "auto",
//                     display: "list-item",
//                   }}
//                 >
//                   Hint: please wait for 5 sec after clicking the button for data
//                   refresh
//                 </span>
//                 <img
//                   src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/tree.png"
//                   onClick={refresh}
//                   style={{ width: "42px" }}
//                   title="Click to Refresh Data"
//                 />
//               </div>
//             </div>
//             <iframe
//               title="VANADARSHINI"
//               className="reports-bi"
//               src="https://app.powerbi.com/view?r=eyJrIjoiMjkzZWEzMjEtMzNkYS00MDI3LThhZWItYTZkZDkwMGM2NmM1IiwidCI6IjM5MmFlNDIzLWFiZTktNDIxOS1iMTU5LTBhZTc3YWMxOWZjYSJ9&pageName=ReportSection8624beb7162a4e44b5c5"
//               frameborder="0"
//               allowFullScreen="true"
//             ></iframe>{" "}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Reports;

import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Header from "./Header";
import DateTab from "./DateTab";
import MonthTab from "./MonthTab";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState("1"); // Default to Talakona

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };
  useEffect(() => {
    if (
      !localStorage.getItem("access_token") ||
      localStorage.getItem("access_token") === ""
    ) {
      navigate("/Home", "_self");
    }
    if (localStorage.getItem("userRole") != 1) {
      navigate("/Home", "_self");
    }
  }, []);

  return (
    <div>
      <Navbar />
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10 content_back">
            {/* Location Dropdown */}
            <div className="container-row">
              <div style={{ width: "20%" }}>
                <div className="mb-3">
                  <label htmlFor="locationDropdown" className="form-label">
                    Select Location:
                  </label>
                  <select
                    className="form-select"
                    id="locationDropdown"
                    value={selectedLocation}
                    onChange={handleLocationChange}
                  >
                    <option value="1">Talakona</option>
                    <option value="2">Mamandur</option>
                  </select>
                </div>
              </div>
            </div>
            <br />
            <br />

            {/* Add Tabs */}
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="date-tab"
                  data-toggle="tab"
                  href="#date"
                >
                  Daily Reports
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="month-tab"
                  data-toggle="tab"
                  href="#month"
                >
                  Monthly Reports
                </a>
              </li>
            </ul>
            <br />
            <br />
            {/* Tab Content */}
            <div className="tab-content">
              <div className="tab-pane fade show active" id="date">
                {/* Pass selected location to DateTab component */}
                <DateTab selectedLocation={selectedLocation} />
              </div>
              <div className="tab-pane fade" id="month">
                {/* Pass selected location to MonthTab component */}
                <MonthTab selectedLocation={selectedLocation} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
