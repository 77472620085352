import React from "react";
import "./Style.css";
import Header from "./Header";
const TermsConditions = () => {
  return (
    <div className="container">
      <Header />
    
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div className="col-lg-1O col-md-10 col-sm-10 col-xs-10">
            <div className="terms-cond">
              <h2>Terms & Conditions Of Seshachala Vanadarshani</h2>
              <br />
              <ul>
                <li>Check-in Time : Afternoon 12PM and after (IST)</li>

                <li>Check-out Time : Morning 11AM (IST)</li>
                <li>
                Two persons allow for one room, maximum room limit 3 members.
                </li>
                <li>Alcohol is strictly prohibited.</li>
                <li>No room service will be provided.</li>
                <li>Please carry valid identity proof</li>
                <li>Co-operate with CBET for better service.</li>
                <li>The Visitors Should not Argue with the CBET Members.</li>
                <li>
                  Any Violation of rules of A.P. Forest Act 1967 and Wildlife
                  Protection Act 1972 will be charged with provisions prescribed
                  in the said Acts.
                </li>
                <li>
                  If Visitors want to order the Food Arrangements in Talakona
                  they should contact 9347455419.
                </li>
                <li>
                  If the Visitors want to know any details of Talakona they may
                  contact 8978080419.
                </li>
                <li>
                  If the Visitors want to order the Food Arrangements and any
                  Details about Mamandur Visitors may contact 9618350557
                </li>
              </ul>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
