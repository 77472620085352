import React from "react";
import { Link } from "react-router-dom";

const VisitorCounter = () => {
  return (
    <div style={{ textAlign: "center", padding: "10px 0px 10px 0px" }}>
      <Link
        to="http://www.hitwebcounter.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="http://hitwebcounter.com/counter/counter.php?page=6353813&style=0006&nbdigits=5&type=page&initCount=0"
          title="install tracking codes"
          alt="install tracking codes"
          border="0"
        />
      </Link>
      <br />
      <a
        href="http://www.hitwebcounter.com"
        title="Visitors Total"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          fontFamily: "Geneva, Arial, Helvetica",
          fontSize: "9px",
          color: "#9C9795",
          textDecoration: "none",
        }}
      >
        <strong>Visitors Total</strong>
      </a>
    </div>
  );
};

export default VisitorCounter;
