import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Home.css"; // Import your custom styles if needed
import { Link } from "react-router-dom";

const UbbalamaduguImages = () => {
    const [imageUrls, setImageUrls] = useState([
    
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/ubbalamadugu-images/ubbalamadugu-1.jpeg",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/ubbalamadugu-images/ubbalamadugu-2.jpeg",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/ubbalamadugu-images/ubbalamadugu-3.jpeg",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/ubbalamadugu-images/ubbalamadugu-4.jpeg",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/ubbalamadugu-images/ubbalamadugu-5.jpeg",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/ubbalamadugu-images/ubbalamadugu-6.jpeg",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/ubbalamadugu-images/ubbalamadugu-7.jpeg",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/ubbalamadugu-images/ubbalamadugu-8.jpeg",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/ubbalamadugu-images/ubbalamadugu-9.jpeg",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/ubbalamadugu-images/ubbalamadugu-10.jpeg",
    ]);
  
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12" id="Logo">
              <div className="row">
                <div className="slider_content_back">
                  <Carousel showThumbs={false} width="100%" autoPlay interval={3000} infiniteLoop>
                    {imageUrls.map((url, idx) => (
                      <div key={idx}>
                        <img
                          src={url}
                          alt={`Image ${idx + 1}`}
                          className="img-responsive img-rounded"
                          style={{ maxHeight: "400px", width: "100%", objectFit: "cover" }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <br />
        </div>
      </div>
    );
  };
  
  export default UbbalamaduguImages;