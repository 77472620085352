// import React, { useState } from "react";
// import axios from "axios";
// import Navbar from "../components/Navbar";
// import Header from "./Header";
// import { Link, useNavigate } from "react-router-dom";

// const LoginPage = () => {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [otp, setOtp] = useState("");
//   const [mobilenumber, setMobilenumber] = useState("");
//   const [otpSent, setOtpSent] = useState(false);
//   const navigate = useNavigate();
//   //  let host = "http://localhost:5000";
//   let host = "https://api.vanadarshani.com";

//   const handleLogin = async () => {
//     if (username === "" || password === "") {
//       alert("Please Enter Username and password");
//     } else {
//       try {
//         const response = await axios.post(host + "/loginuser", {
//           username,
//           password,
//         });

//         if (response.status === 200 && response.data.status === 200) {
//           const otpResponse = await axios.post(host + "/userotplogin", {
//             mobile_number: response.data.data[0].mobile_number,
//           });

//           if (otpResponse.data.status === 200) {
//             setOtpSent(true);
//             alert("OTP sent to registered mobile number");
//           } else {
//             alert("Failed to send OTP");
//           }
//         } else {
//           alert("Invalid password or user not found");
//         }
//       } catch (error) {
//         console.error("Login failed:", error.message);
//       }
//     }
//   };

//   const handleVerifyOtp = async () => {
//     try {
//       const otpVerifyResponse = await axios.post(host + "/userverifyotp", {
//         username,
//         otp,
//         mobile_number: mobilenumber, // Adjust this accordingly based on your backend
//       });

//       if (otpVerifyResponse.data.status === 200) {
//         alert("OTP Verified Successfully");

//         // Continue with the login process if needed
//         const response = await axios.post(host + "/loginuser", {
//           username,
//           password,
//         });

//         if (response.status === 200 && response.data.status === 200) {
//           const userData = {
//             username,
//             password,
//             name: response.data.data[0].name,
//           };

//           localStorage.setItem("token-info", JSON.stringify(userData));
//           localStorage.setItem("userRole", response.data.data[0].user_type);
//           localStorage.setItem(
//             "access_token",
//             response.data.data[0].access_token
//           );

//           let bookingDetailsData = JSON.parse(
//             localStorage.getItem("bookingDetailsData")
//           );

//           if (
//             bookingDetailsData == undefined ||
//             bookingDetailsData.selectedRooms === null
//           ) {
//             navigate("/Accomodation", "_self");
//           } else {
//             navigate("/roomBookingPreview", "_self");
//           }
//         } else {
//           alert("Invalid password or user not found");
//         }
//       } else {
//         alert("Failed to verify OTP");
//       }
//     } catch (otpVerifyError) {
//       console.error("Error verifying OTP:", otpVerifyError.message);
//     }
//   };

//   return (
//     <div>
//       <Header />

//       <div className="container" style={{ paddingTop: "3%" }}>
//         <div className="row">
//           <div className="col-lg-2"></div>
//           <div
//             className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back"
//             style={{ backgroundColor: "#e0ff97" }}
//           >
//             <h1
//               className="text-center"
//               style={{ fontSize: "25px", color: "green" }}
//             >
//               Login
//             </h1>
//             <div className="container mt-5">
//               <form className="login-form">
//                 <div className="mb-3">
//                   <label>Username :&nbsp;</label>
//                   <input
//                     type="text"
//                     value={username}
//                     onChange={(e) => setUsername(e.target.value)}
//                   />
//                 </div>
//                 <br />
//                 <div className="mb-3">
//                   <label>Password :&nbsp;</label>
//                   <input
//                     type="password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                   />
//                 </div>
//                 <br />
//                 {otpSent && (
//                   <div>
//                     <label>Enter OTP :&nbsp;</label>
//                     <input
//                       type="text"
//                       value={otp}
//                       onChange={(e) => setOtp(e.target.value)}
//                     />
//                   </div>
//                 )}
//                 <br />

//                 <button
//                   type="button"
//                   className="btnss btn-primarys login-button"
//                   onClick={otpSent ? handleVerifyOtp : handleLogin}
//                 >
//                   {otpSent ? "Verify OTP" : "Login"}
//                 </button>
//                 <div className="mt-3" style={{ fontSize: "18px" }}>
//                   Not registered? <Link to="/registration">Register now</Link>
//                   <br />
//                   <br />
//                   <div className="mt-3" style={{ marginLeft: "20px" }}>
//                     <Link to="/forgot-password">Forgot Password?</Link>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoginPage;

// import React, { useState } from "react";
// import axios from "axios";
// import Navbar from "../components/Navbar";
// import Header from "./Header";
// import './Login.css';
// import { Link, useNavigate } from 'react-router-dom';;

// const LoginPage = () => {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   let host = "https://api.vanadarshani.com";
// //  let host = "http://localhost:5000";

//   const navigate = useNavigate();

//   const handleLogin = async () => {
//     if (username === '' || password === '') {
//       alert('Please Enter Username and password');
//     } else {
//       try {
//         const response = await axios.post(host+"/loginuser", {
//           username,
//           password,
//         });

//         console.log("API Response:", response);
//         console.log("API Data:", response.data);

//         if (response.status === 200 && response.data.status === 200) {
//           const userData = {
//             username,
//             password,
//           };
//           localStorage.setItem("token-info", JSON.stringify(userData));
//           localStorage.setItem("userRole",response.data.data[0].user_type);
//           localStorage.setItem("access_token",response.data.data[0].access_token);
//           let bookingDetailsData = JSON.parse(
//             localStorage.getItem("bookingDetailsData")
//           );

//           if (bookingDetailsData == undefined || bookingDetailsData.selectedRooms === null) {
//             navigate("/Accomodation", "_self");
//           } else {
//             navigate("/roomBookingPreview", "_self");
//           }
//         } else {
//           alert("Invalid password or user not found");
//         }

//         console.log("Login attempt completed");
//       } catch (error) {
//         console.error("Login failed:", error.message);
//       }
//     };
//   }

//   return (
//     <div>
//       <Navbar />
//       <Header />

//       <div class="container">
//         <div class="row">
//           <div class="col-lg-2"></div>
//           <div
//             class="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back"
//             style={{ backgroundColor: "#e0ff97" }}
//           >
//             <h1
//                 className="text-center"
//                 style={{ fontSize: "25px", color: "green" }}
//               >
//                 Login
//               </h1>
//             <div className="container mt-5">
//               <form className="login-form">
//                 {/ <h2>Login</h2> /}
//                 <div className="mb-3">
//                   <label>Username &nbsp;&nbsp;:</label>&nbsp;&nbsp;
//                   <input
//                     type="text"
//                     value={username}
//                     onChange={(e) => setUsername(e.target.value)}
//                   />
//                 </div>
//                 <br />
//                 <div className="mb-3">
//                   <label>Password &nbsp;&nbsp;:</label>&nbsp;&nbsp;&nbsp;
//                   <input
//                     type="password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                   />
//                 </div>
//                 <br />
//                 <button
//                   type="button"
//                   className="btnss btn-primarys login-button"
//                   onClick={handleLogin}

//                 >
//                   Login
//                 </button>
//                 <p className="mt-3" style={{fontSize:"18px"}}>
//                   Not registered? <Link to="/registration">Register now</Link>
//                   <br/>
//                     <br />
//                     <div className="mt-3" style={{marginLeft:"20px"}}>
//   <Link to="/forgot-password">Forgot Password?</Link>
//   </div>
//                 </p>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoginPage;

import React, { useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import Header from "./Header";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [otpStatus, setOtpStatus] = useState(false);
  const [otp, setOtp] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  // const [name, setName] = useState("");
  let host = "https://api.vanadarshani.com";
  // let host = "http://localhost:5000";
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (username === "" || password === "") {
      alert("Please Enter Username and password");
    } else {
      try {
        const response = await axios.post(host + "/loginuser", {
          username,
          password,
        });

        console.log("API Response:", response);
        console.log("API Data:", response.data);

        if (response.status === 200 && response.data.status === 200) {
          const userData = {
            username,
            password,
            name: response.data.data[0].name, // Store the user's name
          };
          localStorage.setItem("token-info", JSON.stringify(userData));
          localStorage.setItem("userRole", response.data.data[0].user_type);
          localStorage.setItem(
            "access_token",
            response.data.data[0].access_token
          );
          let bookingDetailsData = JSON.parse(
            localStorage.getItem("bookingDetailsData")
          );

          if (response.data.data[0].user_type === 0) {
            if (
              bookingDetailsData == undefined ||
              bookingDetailsData.selectedRooms === null
            ) {
              navigate("/Accomodation", "_self");
            } else {
              navigate("/roomBookingPreview", "_self");
            }
          } else {
            console.log("Admin OTP Required");
            try {
              const respOtpStatus = await axios.post(host + "/userotplogin", {
                mobile_number: response.data.data[0].mobile_number,
              });
              if (respOtpStatus.data.status === 200) {
                // setOtpStatus(prevOtpStatus => !prevOtpStatus);
                setOtpStatus(true);
                console.log(otpStatus, "otpStatus true");
              }
              // console.log(otpStatus,"otpStatus true");
            } catch (error) {
              console.error("Login failed:", error.message);
            }
          }
          // if (
          //   bookingDetailsData == undefined ||
          //   bookingDetailsData.selectedRooms === null
          // ) {
          //   navigate("/Accomodation", "_self");
          // } else {
          //   navigate("/roomBookingPreview", "_self");
          // }
        } else {
          alert("Invalid password or user not found");
        }

        console.log("Login attempt completed");
      } catch (error) {
        console.error("Login failed:", error.message);
      }
    }
  };

  // const handleLogin1 = async () => {
  //   if (otp === "") {
  //     alert("Please Enter OTP");
  //   } else {
  //     try {
  //       const response = await axios.post(host + "/userverifyotp", {
  //         username,
  //         otp,
  //       });

  //       if (response.data.status === 200) {
  //         const userData = {
  //           username,
  //           password,
  //           name: response.data.data[0].name, // Store the user's name
  //         };
  //         // console.log(response.data.data[0], response.data.data);
  //         localStorage.setItem("token-info", JSON.stringify(userData));
  //         localStorage.setItem("userRole", response.data.data[0].user_type);
  //         localStorage.setItem(
  //           "access_token",
  //           response.data.data[0].access_token
  //         );
  //         let bookingDetailsData = JSON.parse(
  //           localStorage.getItem("bookingDetailsData")
  //         );

  //         if (
  //           bookingDetailsData == undefined ||
  //           bookingDetailsData.selectedRooms === null
  //         ) {
  //           navigate("/Accomodation", "_self");
  //         } else {
  //           navigate("/roomBookingPreview", "_self");
  //         }
  //       }
  //       // if (
  //       //   bookingDetailsData == undefined ||
  //       //   bookingDetailsData.selectedRooms === null
  //       // ) {
  //       //   navigate("/Accomodation", "_self");
  //       // } else {
  //       //   navigate("/roomBookingPreview", "_self");
  //       // }
  //       else {
  //         alert("Invalid OTP");
  //       }

  //       console.log("Login attempt completed");
  //     } catch (error) {
  //       console.error("Login failed:", error.message);
  //     }
  //   }
  // };
  const handleVerifyOtp = async () => {
    try {
      const otpVerifyResponse = await axios.post(host + "/userverifyotp", {
        username,
        otp,
        mobile_number: mobilenumber, // Adjust this accordingly based on your backend
      });

      if (otpVerifyResponse.data.status === 200) {
        alert("OTP Verified Successfully");

        // Continue with the login process
        const response = await axios.post(host + "/loginuser", {
          // Assuming this retrieves user data
          username,
          password,
        });

        if (response.status === 200 && response.data.status === 200) {
          const userData = {
            username,
            password,
            name: response.data.data[0].name,
          };

          localStorage.setItem("token-info", JSON.stringify(userData));
          localStorage.setItem("userRole", response.data.data[0].user_type);
          localStorage.setItem(
            "access_token",
            response.data.data[0].access_token
          );

          let bookingDetailsData = JSON.parse(
            localStorage.getItem("bookingDetailsData")
          );

          if (
            bookingDetailsData == undefined ||
            bookingDetailsData.selectedRooms === null
          ) {
            navigate("/Accomodation", "_self");
          } else {
            navigate("/roomBookingPreview", "_self");
          }
        } else {
          alert("Invalid password or user not found");
        }
      } else {
        alert("Failed to verify OTP");
      }
    } catch (otpVerifyError) {
      console.error("Error verifying OTP:", otpVerifyError.message);
    }
  };

  return (
    <div>
      <Navbar />
      <Header />

      <div class="container">
        <div class="row">
          <div class="col-lg-2"></div>
          {otpStatus === false ? (
            <div
              class="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back"
              style={{ backgroundColor: "#e0ff97" }}
            >
              <h1
                className="text-center"
                style={{ fontSize: "25px", color: "green" }}
              >
                Login
              </h1>
              <div className="container mt-5">
                <form className="login-form">
                  <div className="mb-3">
                    <label>Username &nbsp;&nbsp;:</label>&nbsp;&nbsp;
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <br />
                  <div className="mb-3">
                    <label>Password &nbsp;&nbsp;:</label>&nbsp;&nbsp;&nbsp;
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btnss btn-primarys login-button"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                  <p className="mt-3" style={{ fontSize: "18px" }}>
                    Not registered? <Link to="/registration">Register now</Link>
                    <br />
                    <br />
                    <div className="mt-3" style={{ marginLeft: "20px" }}>
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                  </p>
                </form>
              </div>
            </div>
          ) : (
            <div
              class="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back"
              style={{ backgroundColor: "#e0ff97" }}
            >
              <h1
                className="text-center"
                style={{ fontSize: "25px", color: "green" }}
              >
                Login
              </h1>
              <div className="container mt-5">
                <form className="login-form">
                  <div className="mb-3">
                    <label>Username &nbsp;&nbsp;:</label>&nbsp;&nbsp;
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      disabled // Add the disabled attribute here
                    />
                  </div>
                  <br />
                  <div className="mb-3">
                    <label>Enter OTP &nbsp;&nbsp;:</label>&nbsp;&nbsp;&nbsp;
                    <input
                      type="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btnss btn-primarys login-button"
                    onClick={handleVerifyOtp}
                  >
                    OTP Verify
                  </button>
                  <p className="mt-3" style={{ fontSize: "18px" }}>
                    Not registered? <Link to="/registration">Register now</Link>
                    <br />
                    <br />
                    <div className="mt-3" style={{ marginLeft: "20px" }}>
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                  </p>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
