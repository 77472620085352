import React from 'react';

import Header from "./Header";
import './Table.css';
import AareWaterfallsImages from './AareWaterfallsImages';

const AareWaterfalls = () => {
  return (
    <div>

 <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back">
          
          
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
<AareWaterfallsImages/>
                <div className="links_class">
                  <br />
                 
                </div>
               
                <br />
                <h1
                  className="text-center"
                  style={{ fontSize: "30px", color: "green" }}
                >
                 AARE WATERFALLS
                 
                </h1>
                
              
                <h1
                  className="text-center"
                  style={{ fontSize: "20px", color: "green" }}
                >
                <br/>
                AARE WATERFALLS CBET (COMMUNITY BASED ECO- TOURISM) <br/>
                <br/>
             
                </h1>
                <br />
                <div className="AttractionItems">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="row" style={{lineHeight:"28px",textAlign:"justify",justifyContent:"initial",marginLeft:"20px"}}>
                  <p>
          Aare Waterfalls are situated in Siddulaiah kona Forest locality in Compartment No: 241
          of Aare Reserve Forest of Vagattur beat, Pichatur Section of Puttur Range. There are
          three (3) mini waterfalls, some water ponds, and a water stream with sufficient water in all seasons. The waterfalls gained popularity in the past 8 years, attracting visitors from Tamilnadu and Karnataka states.
        </p>
        <p>
          Aare Village Panchayatdars have provided a Vehicle Parking at Aare Minor Irrigation Tank adjoining the Reserve Forest boundary line. They collect vehicle parking fees through the highest bidder from visitors and send them to the waterfalls. However, it 
          has been reported by the Forest Beat Officer, Vagattur, that there is illegal trespassing in the Reserve Forest
          by visitors.
        </p>
        <p>
          The District Forest Officer, Tirupati (T) Division, Tirupati, has permitted the 
          collection of toll fees as per reference Rc.No. 316/2018/G6, dt. –Nil-. The toll fees rates are as follows:
        </p>
        
        <table className="entry-fee-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Fee (Rs)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Entry fees (Local)</td>
              <td>20.00</td>
            </tr>
            <tr>
              <td>Entry Fees (Non – Local)</td>
              <td>50.00</td>
            </tr>
            <tr>
              <td>Parking fees (Two Wheeler)</td>
              <td>50.00</td>
            </tr>
            <tr>
              <td>Parking fees (Three Wheeler)</td>
              <td>100.00</td>
            </tr>
            <tr>
              <td>Parking fees (Four wheeler – Lite vehicles)</td>
              <td>300.00</td>
            </tr>
            <tr>
              <td>Parking fees (Four wheeler – Heavy vehicles)</td>
              <td>500.00</td>
            </tr>
          </tbody>
        </table>

<br/>
<br/>
<div></div>


       

        <h4>Eco-Tourism Concept</h4>
        <p>
          A community-based Eco-Tourism concept is proposed at Aare Waterfalls to incorporate safety measures. Six protection watchers will be appointed to ensure the safety of tourists. The objective is to turn the ecosystem into a sustainable source of livelihood through well-managed tourism. The tourism center abuts the Siddulaiah Swamy Temple inside the hills and aims to educate visitors on the importance of the forest and wildlife.
        </p>

     


<br/>
       
<p></p>

    <h4 className='headings'>CBET Aare - Basic Details</h4>
      <p>
        Located in Compartment No: 241, Kunna kalathur of Aare RF, Vagathur
        Beat, Pichatur section of Puttur Range.
      </p>
      <p>Location&nbsp;:&nbsp; N.13.47167, 79.77352 (Compartment No&nbsp;: 241)</p>
      <p>Distance from Puttur&nbsp;:&nbsp; 37 km</p>
      <p>Distance from Pichattur&nbsp;:&nbsp; 17 km</p>
      <p>Distance from Chennai&nbsp;:&nbsp; 97 km</p>
      <p>Distance from Bangalore&nbsp;:&nbsp; 296 km</p>
      <p>
        Proposed to highlight the conservation of Nature, environmental
        education, and ecological amelioration. Abundant supply of water through
        ever-flowing freshwater streams in the hills.
      </p>
    </div>
     
    <div>
        <h4 className='headings'>Objectives</h4>
        <ul>
          <li>Conserve the native flora and fauna.</li>
          <li>Impart environmental education to the visitors.</li>
        </ul>
      </div>

     

             
    <div>
        <h4 className='headings'>Local Communities Involved</h4>
        <p>
          The project is maintained by VSS of Kunnam Kalathur/Aare RF/Vagathur
          Beat/Pichattur Section of Puttur Range. A 15-member executive body was
          elected from the VSS to manage the CBET. Any proposals of the CBET are
          approved through resolutions with the signatures of all the members
          of CBET. A Chairman elected for the committee and the concerned Forest
          Beat Officer is the member secretary.
        </p>
        
      </div>
   
                    
                  </div>
                 
                </div>
                <div className="clearfix">&nbsp;</div>
               
              </div>
            </div>
          </div>
        </div>
      </div>

    

    </div>
  )
}

export default AareWaterfalls