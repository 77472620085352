import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./Home.css"; 

const AccomodationImages = () => {
  const [imageUrls, setImageUrls] = useState([
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/talakona+waterfalls+Images/entrance-1.jpg",

    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/talakona+waterfalls+Images/dormitory11.jpg",

    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/talakona+waterfalls+Images/loghut-firstfloor1.jpg",

    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/talakona+waterfalls+Images/loghut2.jpg",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/talakona+waterfalls+Images/playground.jpg",

    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/talakona+waterfalls+Images/playground3.jpg",
    // "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/talakona+waterfalls+Images/visitors+room.jpg",
  ]);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12" id="Logo">
            <div className="row">
              <div className="slider_content_back">
                <Carousel
                  showThumbs={false}
                  width="100%"
                  autoPlay
                  interval={3000}
                  infiniteLoop
                >
                  {imageUrls.map((url, idx) => (
                    <div key={idx}>
                      <img
                        src={url}
                        alt={`Image ${idx + 1}`}
                        className="img-responsive img-rounded"
                        style={{
                          maxHeight: "400px",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
        <br />
      </div>
    </div>
  );
};

export default AccomodationImages;
