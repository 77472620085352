import React from 'react';

const CancellationPolicy = () => {
  return (
    <div>
      <h2>Cancellation Policy For Refund</h2>
      <ul style={{ fontSize: "21px" }}>
              <li>Cancellation will be accepted only 48 hours before the <strong>Check-In</strong> .</li>
              <li>Cancellation requests received from 48 hours to <strong>Check-In</strong> we not be accepted.</li>
              <li>50% of the booking amount will be refunded if the cancellation is made within the specified timelines. GST & other charges will not be refunded.</li>
              <li>Refunds will be processed after the cancellation is approved.</li>
              <li>Customers acknowledge and agree to this policy upon making a booking.</li>
            </ul>
    </div>
  );
};

export default CancellationPolicy;
