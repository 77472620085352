import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";

function App() {
  const [isSidebarClosed, setIsSidebarClosed] = useState(
    window.innerWidth < 768
  );
  const userRole = localStorage.getItem("userRole") == 1 ? "Admin" : "Customer";
  const sidebarRef = React.createRef();

  const navigate = useNavigate();
  const handleLogout = () => {
    // Perform any logout logic you need, e.g., clearing localStorage
    localStorage.removeItem("token-info");
    localStorage.removeItem("userRole");
    localStorage.removeItem("access_token");
    localStorage.removeItem("bookingDetailsData");

    navigate("/login", "_self");
  };

  const isLoggedIn = localStorage.getItem("token-info") !== null;
  const name = isLoggedIn
    ? JSON.parse(localStorage.getItem("token-info")).name
    : null;

  const toggleSidebar = () => {
    setIsSidebarClosed(!isSidebarClosed);
  };

  const handleMouseEnter = () => {
    if (isSidebarClosed) {
      sidebarRef.current.classList.remove("close");
    }
  };

  const handleMouseLeave = () => {
    if (isSidebarClosed) {
      sidebarRef.current.classList.add("close");
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsSidebarClosed(true);
    } else {
      setIsSidebarClosed(false);
    }
  }, []);

  return (
    <div>
      <nav className="navbar navbar-pad">
        <div
          id="Logo"
          style={{ width: "100%", height: "100px", position: "absolute" }}
        >
          <div
            id="Logo1"
            style={{ position: "absolute", left: 0, top: 0, paddingTop: "1%" }}
          >
            <img
              src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/logos/logo.png"
              alt="Your Logo"
              className="img-responsive"
            />
          </div>

          <div
            className="logo"
            style={{
              position: "absolute",
              top: "0",
              left: "65px",
              padding: "40px",
            }}
          >
            <i className="bx bx-menu pl-2" onClick={toggleSidebar}></i>
            Vanadarshani
          </div>

          <img
            src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/header.jpeg"
            alt="Your Logo"
            className="img-responsive"
            // style={{
            //   width: "100%",
            //   height: "100%",
            //   objectFit: "cover",
            //   marginLeft: "-30px",
            // }}
            style={{
              width: "141%",
              height: "109%",

              marginLeft: "-33px",
              paddingTop: "19px",
            }}
          />
        </div>
        <div
          className="navbar-right"
          style={{
            position: "absolute",
            marginLeft: "300px",
            paddingTop: "20px",
          }}
        >
          {isLoggedIn ? (
            <div style={{ paddingTop: "20px" }}>
              <span className="navbar-text">Welcome, {name} </span>
              <button
                className="btnnn btn1"
                onClick={handleLogout}
                style={{
                  textAlign: "center",
                  backgroundColor: "#1ca52b",
                  paddingTop: "1px",
                }}
              >
                Logout
              </button>
            </div>
          ) : (
            <Link className="btnnn1 btn1" to="/login">
              Login
            </Link>
          )}
        </div>
      </nav>

      <nav
        className={`sidebar ${isSidebarClosed ? "close" : ""}`}
        ref={sidebarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="menu_content">
          <ul
            className="menu_items"
            style={{
              backgroundImage:
                'url("https://vanadarshini-images.s3.ap-south-1.amazonaws.com/vana-images/hoh-river-valley.jpg")',
              paddingLeft: "2px",
            }}
          >
            <li className="menu_item">
              <Link to="/Home" className="nav_link">
                <span className="navlink_icon">
                  <i className="bx bx-home"></i>
                </span>
                <span className="navlink">Home</span>
              </Link>
            </li>
            {localStorage.getItem("userRole") == 1 && (
              <li className="menu_item">
                <Link to="/ManageRooms" className="nav_link">
                  <span className="navlink_icon">
                    <i className="bx bx-edit"></i>
                  </span>
                  <span className="navlink">Manage Rooms</span>
                </Link>
              </li>
            )}
            {/* <li className="menu_item">
              {localStorage.getItem("userRole") == 1 && (
                <Link to="/Reports" className="nav_link">
                  <span className="navlink_icon">
                    <i className="bx bx-chart"></i>{" "}
                    
                  </span>
                  <span className="navlink">Reports</span>
                </Link>
              )}
            </li> */}

            <li className="menu_item">
              {localStorage.getItem("userRole") == 1 && (
                <Link to="/Booked-Reports" className="nav_link">
                  <span className="navlink_icon">
                    <i className="bx bx-book-open"></i>
                  </span>
                  <span className="navlink">Booked Reports</span>
                </Link>
              )}
            </li>

            <li className="menu_item">
              {localStorage.getItem("userRole") == 1 && (
                <Link to="/Blocked-Reports" className="nav_link">
                  <span className="navlink_icon">
                    <i className="bx bx-block"></i>
                  </span>
                  <span className="navlink">Blocked Reports</span>
                </Link>
              )}
            </li>
            <li className="menu_item">
              {localStorage.getItem("userRole") == 1 && (
                <Link to="/Cancelled-Reports" className="nav_link">
                  <span className="navlink_icon">
                    <i className="bx bx-block"></i>
                  </span>
                  <span className="navlink">Cancelled Reports</span>
                </Link>
              )}
            </li>

            <li className="menu_item">
              {localStorage.getItem("userRole") == 1 && (
                <Link to="/Talakona-token-reports" className="nav_link">
                  <span className="navlink_icon">
                    <i className="bx bx-download"></i>
                  </span>
                  <span className="navlink">Talakona Token Reports</span>
                </Link>
              )}
            </li>
            <li className="menu_item">
              {localStorage.getItem("userRole") == 1 && (
                <Link to="/papavinasanam-reports" className="nav_link">
                  <span className="navlink_icon">
                    <i className="bx bx-download"></i>
                  </span>
                  <span className="navlink">papavinasanam 
                  <br/>Token Reports</span>
                </Link>
              )}
            </li>

            <li className="menu_item">
              <Link to="/Accomodation" className="nav_link">
                <span className="navlink_icon">
                  <i className="bx bx-building-house"></i>
                </span>
                <span className="navlink">Accommodation</span>
              </Link>
            </li>
            {localStorage.getItem("access_token") != undefined && (
              <li className="menu_item">
                <Link to="/MyBookings" className="nav_link">
                  <span className="navlink_icon">
                    <i className="bx bx-book-reader"></i>
                  </span>
                  <span className="navlink">My Bookings</span>
                </Link>
              </li>
            )}
            <li className="menu_item">
              <Link to="/Wild-Life" className="nav_link">
                <span className="navlink_icon">
                  <i className="bx bxs-cat"></i>
                </span>
                <span className="navlink">Wildlife Gallery</span>
              </Link>
            </li>

            <li className="menu_item">
              <Link to="/How-to-Reach" className="nav_link">
                <span className="navlink_icon">
                  <i className="bx bx-map"></i>
                </span>
                <span className="navlink">How To Reach</span>
              </Link>
            </li>

            <li className="menu_item">
              <Link to="/Contact" className="nav_link">
                <span className="navlink_icon">
                  <i className="bx bx-phone-call"></i>
                </span>
                <span className="navlink">Contact</span>
              </Link>
            </li>

            <li className="menu_item">
              {isLoggedIn ? (
                <>
                  <Link to="/" className="nav_link">
                    <span className="navlink_icon">
                      <i className="bx bx-user"></i>
                    </span>
                    <span className="navlink">{name}</span>
                  </Link>
                  <Link
                    className="nav_link"
                    onClick={handleLogout}
                    style={{
                      textAlign: "center",
                      backgroundColor: "#1ca52b",
                      paddingTop: "2px",
                    }}
                  >
                    <span className="navlink_icon">
                      <i className="bx bx-log-out"></i>
                    </span>
                    <span className="navlink">Logout</span>
                  </Link>
                </>
              ) : (
                <Link to="/login" className="nav_link">
                  <span className="navlink_icon">
                    <i className="bx bx-log-in"></i>
                  </span>
                  <span className="navlink">Login</span>
                </Link>
              )}
            </li>
          </ul>

          <div
            className={`collapse_content ${
              isSidebarClosed ? "close" : "hoverable"
            }`}
          >
            <div className="collapse expand_sidebar">
              <span> Expand</span>
              <i className="bx bx-chevron-right" onClick={toggleSidebar}></i>
            </div>
            <div className="collapse collapse_sidebar" onClick={toggleSidebar}>
              <span> Collapse</span>
              <i className="bx bx-chevron-left"></i>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default App;
