import React, { useEffect, useState } from "react";

import Header from "./Header";
import "./Accomodation.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ConcellationRefund from "./ConcellationRefund";
import "./BillingDetails.css";

const RoomBookingPreview = () => {
  // let host = "http://localhost:5000";
  let host = "https://api.vanadarshani.com";
  const navigate = useNavigate();

  const [panValidation, setPanValidation] = React.useState(true);
  const [proofValidation, setProofValidation] = React.useState(true);
  const [proofType, setProofType] = React.useState("pan");
  const [aadhaar, setAadhaar] = useState("");

  const handleAadhaarChange = (e) => {
    setAadhaar(e.target.value);
    billingRequest.aadhaar = e.target.value;
  };

  const handleProofTypeChange = (e) => {
    setProofType(e.target.value);
    setProofValidation(true); // Reset validation when changing proof type
    console.log("Proof Type:", e.target.value);
    // Add a console.log to check proofType and the Aadhaar input
    console.log("Aadhaar Value:", aadhaar);
  };

  const validateProof = (proofValue, proofType) => {
    if (proofType === "aadhaar") {
      const aadhaarRegex = /^\d{12}$/;
      return aadhaarRegex.test(proofValue);
    } else if (proofType === "pan") {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
      return panRegex.test(proofValue);
    }
    return false;
  };

  const handleProofInput = (e) => {
    const proofValue = e.target.value.toUpperCase();
    console.log("Aadhaar Value:", proofValue);
    setProofValidation(validateProof(proofValue, proofType));
  };

  const validatePanCard = (panCard) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    return panRegex.test(panCard);
  };

  const handlePanInput = (e) => {
    const panValue = e.target.value.toUpperCase();
    setPanValidation(validatePanCard(panValue));
  };

  localStorage.removeItem("bookingDetailsDataFinal");
  if (
    localStorage.getItem("access_token") == undefined ||
    localStorage.getItem("access_token") === ""
  ) {
    localStorage.removeItem("bookingDetailsData");
  }
  if (localStorage.getItem("bookingDetailsData") == undefined) {
    navigate("/Accomodation", "_self");
  }
  let bookingDetailsData = JSON.parse(
    localStorage.getItem("bookingDetailsData")
  );
  const [grandAmountFinal, setGrandAmountFinal] = React.useState(0);
  const [bookingDetailsDataFinal, setBookingDetailsDataFinal] = React.useState(
    []
  );
  const [paymentEncyData, setPaymentEncyData] = React.useState("");

  let sumGrandTotalAmount = 0;
  let bookingDetailsDataFinalTmp = [];
  let seqNo = 1;
  let srNo = 0;
  let grandTotal = 0;
  let billingRequest = JSON.parse("{}");
  let captchSumValue = 0;

  bookingDetailsData.selectedRooms.map((e, i) => {
    for (let i = 0; i < bookingDetailsData.daysStay; i++) {
      let roomObj = JSON.parse(JSON.stringify(e));
      let tmpDate = new Date(bookingDetailsData.fromDate);
      tmpDate.setDate(tmpDate.getDate() + i);
      roomObj.bookingDate = tmpDate;
      roomObj.rmSeqId = seqNo;
      roomObj.selectedPersons = roomObj.persons;
      roomObj.grandTotalVal = roomObj.amount;
      roomObj.finalGrandTotalVal = roomObj.amount;
      bookingDetailsDataFinalTmp.push(roomObj);

      sumGrandTotalAmount = sumGrandTotalAmount + e.amount;
      seqNo++;
    }
  });

  useEffect(() => {
    setGrandAmountFinal(sumGrandTotalAmount);
    setBookingDetailsDataFinal(bookingDetailsDataFinalTmp);
  }, []);

  if (
    localStorage.getItem("bookingDetailsDataFinal") !== undefined &&
    localStorage.getItem("bookingDetailsDataFinal") !== null
  ) {
    bookingDetailsDataFinal = JSON.parse(
      localStorage.getItem("bookingDetailsDataFinal")
    );
    setBookingDetailsDataFinal(bookingDetailsDataFinal);
  }
  const handleMobileNumberInput = (e) => {
    const maxLength = 10;
    let inputValue = e.target.value;

    // Trim input to the maximum length
    if (inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
      e.target.value = inputValue;
    }
  };

  const generateRandomNumber = () => {
    const min = 1;
    const max = 9;
    let captchVal = Math.floor(Math.random() * (max - min + 1)) + min;
    captchSumValue = captchSumValue + captchVal;
    return captchVal;
  };

  const populateSubtotalDisplay = (roomId, persons, amount, rmSeqId) => {
    sumGrandTotalAmount = 0;

    let grandTotalValTmp =
      persons >= document.getElementById("daysStay_" + rmSeqId).value
        ? amount
        : (document.getElementById("daysStay_" + rmSeqId).value - persons) *
            100 +
          amount;
    document.getElementById("daysStay_subtotal_" + rmSeqId).innerHTML =
      grandTotalValTmp;

    bookingDetailsDataFinal.map((e, i) => {
      if (e.rmSeqId === rmSeqId) {
        e.selectedPersons = parseInt(
          document.getElementById("daysStay_" + rmSeqId).value
        );
        e.finalGrandTotalVal = grandTotalValTmp;
      }

      sumGrandTotalAmount =
        sumGrandTotalAmount +
        (e.finalGrandTotalVal === undefined ? e.amount : e.finalGrandTotalVal);
    });

    setBookingDetailsDataFinal(bookingDetailsDataFinal);
    setGrandAmountFinal(sumGrandTotalAmount);

    localStorage.setItem(
      "bookingDetailsDataFinal",
      JSON.stringify(bookingDetailsDataFinal)
    );
  };

  const proceedToPayValidation = () => {
    if (
      document.getElementById("customer_name").value === undefined ||
      document.getElementById("customer_name").value === ""
    ) {
      alert("Please provide the valid customer name");
      return false;
    }
    if (
      document.getElementById("customer_address").value === undefined ||
      document.getElementById("customer_address").value === ""
    ) {
      alert("Please provide the valid customer address");
      return false;
    }

    const mobileNumber = document.getElementById("customer_contactno").value;

    if (
      mobileNumber === undefined ||
      mobileNumber === "" ||
      mobileNumber.length !== 10 ||
      isNaN(mobileNumber)
    ) {
      alert("Please provide a valid 10-digit mobile number");
      return;
    }

    if (
      captchSumValue !== parseInt(document.getElementById("captch_value").value)
    ) {
      alert(
        "Given nuber is invalid, please provide the valid sum value of the given numbers"
      );
      return false;
    }

    if (!document.getElementById("termsandconditions").checked) {
      alert("Please click the checkbox to accept the terms and conditions.");
      return false;
    }
    return true;
  };

  const bookNow = (roomId, persons, amount) => {
    if (!proceedToPayValidation()) {
      return;
    }

    billingRequest.locationId = bookingDetailsData.locationId;
    billingRequest.name = document.getElementById("customer_name").value;
    billingRequest.address = document.getElementById("customer_address").value;
    billingRequest.contactno =
      document.getElementById("customer_contactno").value;
    billingRequest.email = document.getElementById("customer_email").value;

    billingRequest.pan =
      proofType === "pan" ? document.getElementById("customer_pan").value : "";
    billingRequest.aadhaar =
      proofType === "aadhaar"
        ? document.getElementById("customer_aadhaar").value
        : "";

    billingRequest.termsAndConditions =
      document.getElementById("termsandconditions").checked;
    billingRequest.rooms = bookingDetailsData;
    billingRequest.roomData = bookingDetailsDataFinal;
    localStorage.setItem("billingRequest", JSON.stringify(billingRequest));

    fetch(
      host +
        "/proceed-room-booking?access_token=" +
        localStorage.getItem("access_token"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(billingRequest),
      }
    )
      .then((data) => {
        if (data.status === 403) {
          alert(
            "We regret to inform you that we are currently unable to process your request. Kindly try again later"
          );
          navigate("/Login");
          return;
        }
        if (data.status === 401) {
          alert("session expired. Please log in and try again.");
          navigate("/login");

          return;
        }
        console.log("Form submitted:", data);
        navigate("/bookingSummary", "_self");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const proceedToPay = () => {
    let validationResult = proceedToPayValidation();
    if (validationResult == false) {
      return;
    }
    billingRequest.locationId = bookingDetailsData.locationId;
    billingRequest.name = document.getElementById("customer_name").value;
    billingRequest.address = document.getElementById("customer_address").value;
    billingRequest.contactno =
      document.getElementById("customer_contactno").value;
    billingRequest.email = document.getElementById("customer_email").value;

    billingRequest.pan =
      proofType === "pan" ? document.getElementById("customer_pan").value : "";
    billingRequest.aadhaar =
      proofType === "aadhaar"
        ? document.getElementById("customer_aadhaar").value
        : "";
    billingRequest.termsAndConditions =
      document.getElementById("termsandconditions").checked;
    billingRequest.rooms = bookingDetailsData;
    billingRequest.roomData = bookingDetailsDataFinal;
    localStorage.setItem("billingRequest", JSON.stringify(billingRequest));
    console.log(billingRequest, "billingRequest");
    fetch(
      host +
        "/proceed-room-booking?access_token=" +
        localStorage.getItem("access_token"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(billingRequest),
      }
    )
      .then((data) => {
        if (data.status == 403) {
          alert(
            "We regret to inform you that we are currently unable to process your request. Kindly try again later"
          );
          navigate("/Accomodation");
          return;
        }
        if (data.status === 401) {
          alert("session expired. Please log in and try again.");
          navigate("/login");

          return;
        }
        console.log("Form submitted:", data);
        data.json().then((res) => {
          console.log(res, "response9999999999999999");
          document.getElementById("encRequest").value = res.data;
          document.getElementById("order_id").value = res.data;
          document.getElementById("amount_id").value = res.amount;

          console.log("res", res.amount);
          console.log("res1", res.data);

          document.getElementById("key_id").value = res.apiKey;

          document.redirect.submit();
        });
        //navigate("/bookingSummary", "_self");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back">
            <div className="center_title">Cottage Booking Details</div>

            <div className="col-md-3 center"></div>
            <div className="col-md-6 center billing_details_form">
              <form>
                <div className="form-group">
                  <label for="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control billing_details_form-control"
                    id="customer_name"
                    aria-describedby="Name"
                    placeholder="Name"
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">Address</label>
                  <textarea
                    type="email"
                    name="email"
                    style={{ minHeight: 100 }}
                    className="form-control billing_details_form-control"
                    id="customer_address"
                    aria-describedby="Address"
                    placeholder="Address"
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">Contact No.</label>
                  <input
                    type="number"
                    name="mobileNumber"
                    className="form-control billing_details_form-control"
                    id="customer_contactno"
                    aria-describedby="Contact No"
                    placeholder="Contact No"
                    onInput={(e) => handleMobileNumberInput(e)}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">E-mail</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control billing_details_form-control"
                    id="customer_email"
                    aria-describedby="E-Mail"
                    placeholder="E-Mail"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Proof Type</label>
                  <select
                    className="form-control billing_details_form-control"
                    id="proof_type"
                    value={proofType}
                    onChange={handleProofTypeChange}
                  >
                    <option value="pan">PAN</option>
                    <option value="aadhaar">Aadhaar</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Proof ({proofType.toUpperCase()})
                  </label>

                  <input
                    type="text"
                    name={proofType}
                    className={`form-control billing_details_form-control ${
                      !proofValidation ? "is-invalid" : ""
                    }`}
                    id={`customer_${proofType}`}
                    aria-describedby={`Proof (${proofType.toUpperCase()})`}
                    placeholder={`Proof (${proofType.toUpperCase()})`}
                    onBlur={handleProofInput}
                  />

                  {!proofValidation && (
                    <div className="invalid-feedback">
                      Invalid {proofType.toUpperCase()} number
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label for="exampleInputEmail1">Verify Human</label>

                  <div>
                    <div style={{ float: "left" }}>
                      {generateRandomNumber()}+{generateRandomNumber()} =
                    </div>
                    <div style={{ float: "left" }}>
                      <input
                        type="text"
                        name="captch"
                        className="form-control form-control-captcha"
                        id="captch_value"
                        aria-describedby=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group" style={{ float: "left" }}>
                  <input
                    className="largerCheckbox "
                    type="checkbox"
                    id="termsandconditions"
                  />{" "}
                  I agree to the above specified{" "}
                  <a href="/terms-conditions">Terms and Conditions</a>
                </div>
              </form>
              <br />
            </div>
            <div className="col-md-3 center"></div>

            <table className="table table-striped">
              <th>S.No</th>
              <th>Room Name</th>
              <th>Date</th>
              <th>Amount</th>
              <th>No.of.Persons</th>
              <th>Subtotal</th>

              {bookingDetailsDataFinal.map((e, i) => {
                grandTotal = grandTotal + e.amount;
                srNo++;
                return (
                  <tr>
                    <td>{srNo}</td>
                    <td>{e.room_name} </td>
                    <td>{moment(e.bookingDate).format("yyyy-MM-DD")}</td>
                    <td>{e.amount}</td>
                    <td>
                      {e.accommodation_type !== "Dormitory" && (
                        <select
                          id={"daysStay_" + e.rmSeqId}
                          onChange={() =>
                            populateSubtotalDisplay(
                              e.room_id,
                              e.persons,
                              e.amount,
                              e.rmSeqId
                            )
                          }
                        >
                          {/* <option selected = { e.selectedPersons === 1 ?true : false}  key={1}>1</option> */}
                          <option
                            selected={e.selectedPersons === 2 ? true : false}
                            key={2}
                          >
                            2
                          </option>
                        </select>
                      )}
                      {e.accommodation_type === "Dormitory" && e.persons}
                    </td>
                    <td id={"daysStay_subtotal_" + e.rmSeqId}>
                      {" "}
                      {e.grandTotalVal}{" "}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan="4"></td>
                <td>Grand Total</td>
                <td>{grandAmountFinal}</td>
              </tr>
            </table>
            <button
              className="back_button float_left"
              onClick={() => navigate("/Accomodation", "_self")}
            >
              Back
            </button>
            <button
              className="booking_button float_right"
              onClick={() => proceedToPay()}
            >
              Proceed to Pay
            </button>

            <div className="container" style={{ paddingTop: "10%" }}>
              <ConcellationRefund />
            </div>
          </div>
        </div>
        <form
          id="nonseamless"
          method="post"
          name="redirectold"
          action="https://test.ccavenue.com/transaction.do?command=initiateTransaction"
        >
          <input type="hidden" id="encRequest" name="encRequest" value="" />

          <input
            type="hidden"
            name="access_code"
            id="access_code"
            value="AVDP44KL13CA85PDAC"
          />
        </form>

        <form
          method="POST"
          name="redirect"
          action="https://api.razorpay.com/v1/checkout/embedded"
        >
          <input type="hidden" name="key_id" id="key_id" value="" />
          <input type="hidden" id="order_id" name="order_id" value="" />
          <input type="hidden" id="amount_id" name="amount" value="" />
          {/* <input
            type="hidden"
            name="callback_url"
            value="http://localhost:5000/razorpay-response"
          /> */}
          <input
            type="hidden"
            name="callback_url"
            value="https://api.vanadarshani.com/razorpay-response"
          />
        </form>
      </div>
    </div>
  );
};
export default RoomBookingPreview;
