import React from "react";
import Header from "./Header";

const GeneralRules = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div className="col-lg-1O col-md-10 col-sm-10 col-xs-10">
            <div className="general-rules">
              <h2>
                <b>General Rules</b>
              </h2>
              <br />
              <ul className="h2-general">
                <li>Room Booking is not Transferable.</li>
                <li>
                  There will be no refund for any unforeseen calamities which
                  may occur during the event.
                </li>
                <li>
                  Credit/Debit card transaction fee (2%) to be borne by
                  customer.
                </li>
              </ul>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralRules;
