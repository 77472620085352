import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Header from "./Header";
import "./Header.css";
import "./Accomodation.css";
import "./ManageRooms.css";
import { Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
const ManageRooms = () => {
  let host = "https://api.vanadarshani.com";
  //   let  host = "http://localhost:5000";
  const navigate = useNavigate();
  const [roomData, setRoomData] = useState([]);
  const [statusCheck, setStatusCheck] = useState(false);
  const [isShow, invokeModal] = React.useState(false);
  const [editableRoom, setEditableRoom] = React.useState({});
  if (
    localStorage.getItem("access_token") == undefined ||
    localStorage.getItem("access_token") === ""
  ) {
    navigate("/Home", "_self");
  }
  if (localStorage.getItem("userRole") != 1) {
    navigate("/Home", "_self");
  }
  const initModal = () => {
    return invokeModal(!false);
  };
  const closeInitModal = () => {
    return invokeModal(false);
  };

  //populate available rooms by location
  const getRoomsMaster = () => {
    fetch(
      host +
        "/get-rooms?location=" +
        document.getElementById("locationIds").value +
        "&time=" +
        Math.round(new Date().getTime() / 1000),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      response.json().then((rooms) => {
        setRoomData(rooms);
      });
    });
  };

  const updateRoomStatus = (e, roomId) => {
    let updateRequest = {};
    updateRequest.locationId = document.getElementById("locationIds").value;
    updateRequest.roomId = roomId;
    updateRequest.status = e.target.checked;
    fetch(host + "/update-room-status", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateRequest),
    }).then((response) => {
      alert(response.json());
      getRoomsMaster();
    });
  };
  const editRoomDetails = (roomId) => {
    roomData.map((e) => {
      if (e.room_id === roomId) {
        setEditableRoom(e);
        setStatusCheck(e.status);
      }
    });
  };
  const updateRoomDetails = () => {
    let payload = JSON.parse("{}");
    if (editableRoom.room_id !== undefined) {
      payload.roomId = editableRoom.room_id;
    }
    payload.accommodation_type =
      document.getElementById("acc_type") == undefined
        ? undefined
        : document.getElementById("acc_type").value;
    payload.roomName =
      document.getElementById("roomName") == undefined
        ? undefined
        : document.getElementById("roomName").value;
    payload.amount =
      document.getElementById("amount") == undefined
        ? undefined
        : document.getElementById("amount").value;
    payload.extraAmount =
      document.getElementById("extraAmount") == undefined
        ? undefined
        : document.getElementById("extraAmount").value;
    payload.persons =
      document.getElementById("daysStay") == undefined
        ? undefined
        : document.getElementById("daysStay").value;
    payload.status =
      document.getElementById("status") == undefined
        ? undefined
        : document.getElementById("status").checked;
    payload.locationId =
      document.getElementById("locationIds") == undefined
        ? undefined
        : document.getElementById("locationIds").value;
    payload.imageUrl =
      document.getElementById("imageUrl") == undefined
        ? undefined
        : document.getElementById("imageUrl").value;
    fetch(host + "/update-room-details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      response.json().then((res) => {
        closeInitModal();
        alert(res.message);
      });
      getRoomsMaster();
    });
  };

  const deleteRoomDetails = (roomId) => {
    let payload = JSON.parse("{}");
    if (roomId !== undefined) {
      payload.roomId = roomId;
    } else {
      alert("Unable to process your request");
      return;
    }

    fetch(host + "/delete-room-details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      response.json().then((res) => {
        alert(res.message);
      });
      getRoomsMaster();
    });
  };

  useEffect(() => {
    getRoomsMaster();
  }, []);

  return (
    <div>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back">
            <div className="row">
              <div style={{ width: "20%" }}>
                <b>Select Location</b>
                <select
                  id="locationIds"
                  onChange={() => {
                    getRoomsMaster();
                  }}
                >
                  <option value="1">Talakona</option>
                  <option value="2">Mamandur</option>
                </select>
              </div>
              <div style={{ width: "30%" }}></div>
              <div style={{ width: "50%" }}>
                <button
                  className="booking_buttons float_right"
                  onClick={() => initModal()}
                >
                  Add Room
                </button>
              </div>
            </div>
            <div className="row">
              <div className="table-responsive">
                <table className="table table-striped">
                  <th>Accommodation Type</th>
                  <th>Room Name</th>
                  <th>Amount</th>
                  <th>Extra Amount</th>
                  <th>No.of.Persons</th>
                  <th>Status</th>
                  <th>Action</th>

                  {roomData.map((e, i) => {
                    return (
                      <tr>
                        <td>{e.accommodation_type}</td>
                        <td>{e.room_name} </td>
                        <td>{e.amount}</td>
                        <td>{e.extra_amount}</td>
                        <td>{e.persons}</td>
                        <td>{e.status == true ? "Active" : "InActive"}</td>
                        <td>
                          {" "}
                          <i
                            className="bx bx-edit"
                            onClick={() => {
                              initModal();
                              editRoomDetails(e.room_id);
                            }}
                            id="openModal"
                          ></i>{" "}
                          <i
                            className="bx bx-trash"
                            onClick={() => {
                              deleteRoomDetails(e.room_id);
                            }}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={isShow}>
        <Modal.Header closeButton onClick={closeInitModal}>
          <Modal.Title>Edit Room Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label for="exampleInputEmail1">Type</label>
              <select
                id="acc_type"
                className="form-control billing_details_form-control"
              >
                <option
                  selected={
                    editableRoom.accommodation_type === "Loghut" ? true : false
                  }
                  key="Loghut"
                >
                  Loghut
                </option>
                <option
                  selected={
                    editableRoom.accommodation_type === "Dormitory"
                      ? true
                      : false
                  }
                  key="Dormitory"
                >
                  Dormitory
                </option>
              </select>
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Room Name</label>
              <input
                defaultValue={editableRoom.room_name}
                type="text"
                name="roomName"
                className="form-control billing_details_form-control"
                id="roomName"
                aria-describedby="Address"
                placeholder="Room Name"
              />
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Amount</label>
              <input
                defaultValue={editableRoom.amount}
                type="number"
                name="amount"
                className="form-control billing_details_form-control"
                id="amount"
                aria-describedby="Contact No"
                placeholder="Amount"
              />
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Extra Amount Per Person</label>
              <input
                defaultValue={editableRoom.extra_amount}
                type="number"
                name="amount"
                className="form-control billing_details_form-control"
                id="extraAmount"
                aria-describedby="Contact No"
                placeholder="Amount"
              />
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Number Of Persons</label>
              <select
                id="daysStay"
                className="form-control billing_details_form-control"
              >
                <option
                  selected={editableRoom.persons === 1 ? true : false}
                  key={1}
                >
                  1
                </option>
                <option
                  selected={editableRoom.persons === 2 ? true : false}
                  key={2}
                >
                  2
                </option>
                {/* <option selected={editableRoom.persons === 3 ? true : false} key={3}>3</option>
                                <option selected={editableRoom.persons === 4 ? true : false} key={4}>4</option> */}
              </select>
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Thumbnail Image URL</label>
              <input
                defaultValue={editableRoom.image_url}
                type="text"
                name="imageUrl"
                className="form-control billing_details_form-control"
                id="imageUrl"
                aria-describedby="Contact No"
                placeholder="url"
              />
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Status</label>
              <input
                className="largerCheckbox"
                checked={statusCheck}
                type="checkbox"
                id="status"
                onChange={(e) => {
                  setStatusCheck(e.target.checked);
                }}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="booking_button float_right"
            onClick={() => updateRoomDetails()}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageRooms;
