import React, { useEffect, useState } from "react";

import Header from "./Header";
import "./Accomodation.css";

import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import { Navigate, useNavigate } from "react-router-dom";

const RoomBlockingPreview = () => {
  const navigate = useNavigate();
  let host = "https://api.vanadarshani.com";
  // let host = "http://localhost:5000";
  localStorage.removeItem("bookingDetailsDataFinal");
  if (
    localStorage.getItem("access_token") == undefined ||
    localStorage.getItem("access_token") === ""
  ) {
    localStorage.removeItem("bookingDetailsData");
  }
  if (localStorage.getItem("bookingDetailsData") == undefined) {
    navigate("/Accomodation", "_self");
  }
  let bookingDetailsData = JSON.parse(
    localStorage.getItem("bookingDetailsData")
  );
  const [grandAmountFinal, setGrandAmountFinal] = React.useState(0);
  const [bookingDetailsDataFinal, setBookingDetailsDataFinal] = React.useState(
    []
  );
  const [blockingReasons, setBlockingReasons] = useState({});
  const [blockingReason, setBlockingReason] = useState("");
  let sumGrandTotalAmount = 0;
  let bookingDetailsDataFinalTmp = [];
  let seqNo = 1;
  let srNo = 0;
  let grandTotal = 0;
  let billingRequest = JSON.parse("{}");
  let captchSumValue = 0;

  bookingDetailsData.selectedRooms.map((e, i) => {
    for (let i = 0; i < bookingDetailsData.daysStay; i++) {
      let roomObj = JSON.parse(JSON.stringify(e));
      let tmpDate = new Date(bookingDetailsData.fromDate);
      tmpDate.setDate(tmpDate.getDate() + i);
      roomObj.bookingDate = tmpDate;
      roomObj.rmSeqId = seqNo;
      roomObj.selectedPersons = roomObj.persons;
      roomObj.grandTotalVal = roomObj.amount;
      bookingDetailsDataFinalTmp.push(roomObj);

      sumGrandTotalAmount = sumGrandTotalAmount + e.amount;
      seqNo++;
    }
  });

  useEffect(() => {
    setGrandAmountFinal(sumGrandTotalAmount);
    setBookingDetailsDataFinal(bookingDetailsDataFinalTmp);
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem("bookingDetailsDataFinal");
    if (storedData !== undefined && storedData !== null) {
      const bookingData = JSON.parse(storedData);
      setBookingDetailsDataFinal(bookingData);
    }
  }, []);

  if (
    localStorage.getItem("bookingDetailsDataFinal") !== undefined &&
    localStorage.getItem("bookingDetailsDataFinal") !== null
  ) {
    bookingDetailsDataFinal = JSON.parse(
      localStorage.getItem("bookingDetailsDataFinal")
    );
    setBookingDetailsDataFinal(bookingDetailsDataFinal);
  }

  const handleReasonChange = (index, reason) => {
    setBlockingReasons({
      ...blockingReasons,
      [index]: reason,
    });
  };
  const blockNow = (roomId, persons, amount) => {
    if (blockingReason.trim() === "") {
      alert("Please provide a reason for blocking the rooms.");
      return;
    }
    billingRequest.locationId = bookingDetailsData.locationId;

    billingRequest.rooms = bookingDetailsData;
    // Validation: Check if all rooms have a blocking reason
    // for (let i = 0; i < bookingDetailsDataFinal.length; i++) {
    //   if (!blockingReasons[i] || blockingReasons[i].trim() === "") {
    //     alert(`Please provide a reason for blocking room ${i + 1}`);
    //     return;
    //   }
    // }

    billingRequest.roomData = bookingDetailsDataFinal;
    // billingRequest.roomData = bookingDetailsDataFinal.map((room, index) => ({
    //   ...room,
    //   blockReason: blockingReasons[index] || "",
    // }));

    billingRequest.roomData = bookingDetailsDataFinal.map((room) => ({
      ...room,
      blockReason: blockingReason,
    }));
    localStorage.setItem("billingRequest", JSON.stringify(billingRequest));

    fetch(
      host +
        "/update-room-blocking?access_token" +
        localStorage.getItem("access_token"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(billingRequest),
      }
    )
      .then((data) => {
        console.log("Form submitted:", data);
        localStorage.removeItem("bookingDetailsData");
        localStorage.removeItem("bookingDetailsDataFinal");
        alert("The room blocking has been successfully updated.");
        navigate("/Accomodation", "_self");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back">
            <div className="center_title">Cottage Booking Details</div>
            <div className="table-responsive">
              <table className="table table-striped">
                <th>S.No</th>
                <th>Room Name</th>
                <th>Date</th>
                <th>No.of.Persons</th>
                {/* <th>Block Reason</th> */}

                {bookingDetailsDataFinal.map((e, i) => {
                  grandTotal = grandTotal + e.amount;
                  srNo++;
                  return (
                    <tr>
                      <td>{srNo}</td>
                      <td>{e.room_name} </td>
                      <td>{moment(e.bookingDate).format("yyyy-MM-DD")}</td>
                      <td>
                        {e.accommodation_type !== "Dormitory" && (
                          <select id={"daysStay_" + e.rmSeqId}>
                            <option
                              selected={e.selectedPersons === 1 ? true : false}
                              key={1}
                            >
                              1
                            </option>
                            <option
                              selected={e.selectedPersons === 2 ? true : false}
                              key={2}
                            >
                              2
                            </option>
                            {/* <option selected={e.selectedPersons === 3 ? true : false} key={3}>3</option>
                                                    <option selected={e.selectedPersons === 4 ? true : false} key={4}>4</option> */}
                          </select>
                        )}
                        {e.accommodation_type === "Dormitory" && e.persons}
                      </td>
                      {/* <td>
                        <input
                          type="text"
                          value={blockingReasons[i] || ""}
                          onChange={(e) =>
                            handleReasonChange(i, e.target.value)
                          }
                          placeholder="Enter reason"
                        />
                      </td> */}
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="blockk" style={{ marginLeft: "20%" }}>
              <label>Block Reason:</label>
              <input
                type="text"
                value={blockingReason}
                onChange={(e) => setBlockingReason(e.target.value)}
                placeholder="Enter reason"
              />
            </div>
            <div className="button-container">
              <button
                className="back_button float_left"
                onClick={() => navigate("/Accomodation", "_self")}
              >
                Back
              </button>
              <button
                className="booking_button float_right"
                onClick={() => blockNow()}
              >
                Block Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoomBlockingPreview;
