import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Header from "./Header";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import "./Accomodation.css";

const host = "https://api.vanadarshani.com";
// const host = "http://localhost:5000";

const CancellationReports = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cancellationDetails, setCancellationDetails] = useState([]);

  useEffect(() => {
    // Redirect to login if access token is missing or user is not authorized
    if (
      !localStorage.getItem("access_token") ||
      localStorage.getItem("access_token") === "" ||
      localStorage.getItem("userRole") != 1
    ) {
      navigate("/Home", "_self");
    }
  }, [navigate]);

  const handleFilter = () => {
    const formattedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : "";
    const formattedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : "";

    let url = `${host}/api/cancellation-details`;
    if (startDate && endDate) {
      url += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => setCancellationDetails(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(cancellationDetails);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CancellationDetails");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "cancellationdetails.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: "a4",
    });

    doc.setProperties({
      title: "Vanadarshani Reports",
    });

    const headingText = "Vanadarshani Reports";
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth =
      (doc.getStringUnitWidth(headingText) * fontSize) /
      doc.internal.scaleFactor;
    const textX = (pageWidth - textWidth) / 2;
    doc.setFontSize(fontSize);
    doc.text(headingText, textX, 40);

    const subtitleText = `Cancellation Details Reports`;
    const subtitleFontSize = 16;
    const subtitleWidth =
      (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
      doc.internal.scaleFactor;
    const subtitleX = (pageWidth - subtitleWidth) / 2;
    doc.setFontSize(subtitleFontSize);
    doc.text(subtitleText, subtitleX, 60);

    const tableColumn = [
      //   "Booking ID",
      "Room ID",
      "Customer ID",
      "Date",
      "Room Amount",
      "Extra Persons",
      "Subtotal",
      "Created By",
      "Cancellation Date",
      "Payment Mode",
      "Razorpay Order ID",
      "Razorpay Payment ID",
      "Refund Amount",
    ];

    const tableRows = cancellationDetails.map((detail) => [
      //   detail.booking_id,
      detail.room_id,
      detail.customer_id,
      new Date(detail.date).toLocaleDateString(),
      detail.room_amount,
      detail.extra_persons,
      detail.sub_total,
      detail.created_by,
      new Date(detail.cancellation_date).toLocaleDateString(),
      detail.payment_mode,
      detail.razorpay_order_id,
      detail.razorpay_payment_id,
      detail.Refund_amount,
    ]);

    doc.autoTable(tableColumn, tableRows, { startY: 80 });
    doc.save("cancellationdetails.pdf");
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back">
            <div className="row">
              <b className="text-center" style={{ fontSize: "20px" }}>
                Vanadarshani Cancellation Reports
              </b>
            </div>
            <br />
            <div className="filters">
              <h3>Select Date Range:</h3>
              <label>From:</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
              />
              <label>To:</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
              />
              <button
                type="button"
                className="btn-primary"
                onClick={handleFilter}
                style={{ borderRadius: "20px" }}
              >
                Submit
              </button>
            </div>
            {cancellationDetails.length > 0 ? (
              <>
                <div className="download-buttons">
                  <button
                    style={{ borderRadius: "20px" }}
                    className="btn-primary"
                    onClick={downloadExcel}
                  >
                    Download Excel
                  </button>
                  <button
                    style={{ borderRadius: "20px" }}
                    className="btn-primary"
                    onClick={downloadPDF}
                  >
                    Download PDF
                  </button>
                </div>
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      {/* <th>Booking ID</th> */}
                      <th>Room ID</th>
                      <th>Customer ID</th>
                      <th>Date</th>
                      <th>Room Amount</th>
                      <th>Extra Persons</th>
                      <th>Subtotal</th>
                      {/* <th>Created By</th> */}
                      <th>Cancellation Date</th>
                      <th>Payment Mode</th>
                      <th>Razorpay Order ID</th>
                      <th>Razorpay Payment ID</th>
                      <th>Refund Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cancellationDetails.map((detail) => (
                      <tr key={detail.booking_id}>
                        {/* <td>{detail.booking_id}</td> */}
                        <td>{detail.room_id}</td>
                        <td>{detail.customer_id}</td>
                        <td>{new Date(detail.date).toLocaleDateString()}</td>
                        <td>{detail.room_amount}</td>
                        <td>{detail.extra_persons}</td>
                        <td>{detail.sub_total}</td>
                        {/* <td>{detail.created_by}</td> */}
                        <td>
                          {new Date(
                            detail.cancellation_date
                          ).toLocaleDateString()}
                        </td>
                        <td>{detail.payment_mode}</td>
                        <td>{detail.razorpay_order_id}</td>
                        <td>{detail.razorpay_payment_id}</td>
                        <td>{detail.Refund_amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>No cancellation details found for the selected dates.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancellationReports;
