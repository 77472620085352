

import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Header from "./Header";
import "./Home.css";

const Home = () => {
  const cardData = [
    { title: "Talakona WaterFalls", backgroundColor: "green" },
    { title: "Mamandur WaterFalls", backgroundColor: "orange" },
    { title: "Jungle Safari-Kalyani Dam-Pulibonu", backgroundColor: "green" },
    { title: "AARE WaterFalls", backgroundColor: "orange" },
    { title: "Ubbalamadugu WaterFalls", backgroundColor: "green" },
    { title: "JungleBook", backgroundColor: "orange" },
    { title: "Papavinasanam", backgroundColor: "green" },
  ];

  const columns = 3;
  const cardsPerRow = 3;

  const fullRows = Math.floor(cardData.length / columns);
  const cardsInLastRow = cardData.length % columns;

  return (
    <div>
      <Navbar />
      <Header />

      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back">
            <br />
            <h1
                    className="text-center"
                    style={{ fontSize: "30px", color: "green" }}
                  >
                 HOME
                  </h1>
            <div className="container mt-5">
              {[...Array(fullRows)].map((_, rowIndex) => (
                <div key={rowIndex} className="row mb-4">
                  {[...Array(cardsPerRow)].map((_, colIndex) => (
                    <div key={colIndex} className="col-md-4">
                      <div
                        className={`card square-card ${
                          cardData[rowIndex * columns + colIndex]
                            .backgroundColor
                        }`}
                      >
                        <div className="card-body">
                          <h5 className="card-title">
                            {cardData[rowIndex * columns + colIndex].title}
                          </h5>
                          <Link
                            to={`/${cardData[
                              rowIndex * columns + colIndex
                            ].title
                              .toLowerCase()
                              .replace(/\s+/g, "")}`}
                            className={`btnt ${
                              cardData[rowIndex * columns + colIndex]
                                .backgroundColor === "green"
                                ? "btnt-orange"
                                : "btnt-green"
                            }`}
                          >
                            Know More
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <div className="row mb-4">
                <div
                  className={`col-md-4 offset-md-${Math.floor(
                    (columns - cardsInLastRow) / 3
                  )}`}
                >
                  <div
                    className={`card square-card ${
                      cardData.slice(-1)[0].backgroundColor
                    }`}
                  >
                    <div className="card-body">
                      <h5 className="card-title">
                        {cardData.slice(-1)[0].title}
                      </h5>
                      <Link
                        to={`/${cardData
                          .slice(-1)[0]
                          .title.toLowerCase()
                          .replace(/\s+/g, "")}`}
                        className={`btnt ${
                          cardData.slice(-1)[0].backgroundColor === "green"
                            ? "btnt-orange"
                            : "btnt-green"
                        }`}
                      >
                        Know More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
