import React from "react";
import Navbar from "../components/Navbar";
import Header from "./Header";
import { Link } from "react-router-dom";
import PapavinasanamImages from "./PapavinasanamImages";
const Papanasanam = () => {
  return (
    <div>
      
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div className="col-lg-1O col-md-10 col-sm-10 col-xs-10 content_back">
          <PapavinasanamImages/>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {/* <div className="links_class">
                  <br />
                  <ul className="nav nav-pills">
                    <li>
                      <Link to="/Talakona">TALAKONA</Link>
                    </li>
                    <li>
                      <Link to="/Mamamdur">MAMUNDUR FOREST</Link>
                    </li>
                    <li className="active">
                      <Link to="/Papanasanam">PAPANASANAM</Link>
                    </li>
                    <li>
                      <Link to="/Junglebook">JUNGLE BOOK IN TIRUPATI</Link>
                    </li>
                  </ul>
                </div> */}
                <div className="row1">
                  <br />
                  <h1
                    className="text-center"
                    style={{ fontSize: "30px", color: "green" }}
                  >
                    PAPAVINASANAM
                  </h1>
                  <div className="AttractionItems">
                    <br />
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="row">
                        <p>
                          It is said that Papanasam theertham stream is a sacred
                          stream which is found 3 miles away from the Tirumala
                          temple. As the name implies, If taken a shower at
                          Papanasanam one can get rid of all the sins, as it is
                          believed that this water comes from the feet of the
                          Lord. This was a natural waterfall, but the water is
                          arranged to flow from the mouth of Lions. A reservoir
                          is constructed here, which supplies water to the whole
                          of Tirumala.
                        </p>
                        <p>
                          Near this location other sacred theertham places can
                          also be found, few namely Pandavar theertham, Jabali
                          theertham, Vaikunta theertham, Kapila theertham,
                          Tumburu Puniya theertham, and Kumara darma theertham.
                        </p>

                        <br />
                        <br />
                        {/* <div className="online_booking">
                          <Link to="/Accomodation">
                            <img
                              src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/vana-images/roombooking_new.jpg"
                              className="img-responsive"
                              alt="Online Booking"
                            />
                          </Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="row" style={{marginLeft:"2px"}}>
                      <br/>
                      <h4>Contact Numbers</h4>
                      <p>For more information please contact</p>
                      <p>
                        
                        Forest Range Officer , Tirupati : 7981585207
                      </p>
                      <br />
                   
                    </div>
                    <div className="clearfix">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Papanasanam;
