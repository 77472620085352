import React from "react";
import Navbar from "../components/Navbar";
import Header from "./Header";
import { Link } from "react-router-dom";
import MamandurImages from "./MamandurImages";

const MamandurForest = () => {
  return (
    <div>
     
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div className="col-lg-1O col-md-10 col-sm-10 col-xs-10 content_back">
          <MamandurImages/>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="links_class">
                  <br />
                 
                </div>
                <div className="row1">
                  <br />
                  <h1
                    className="text-center"
                    style={{ fontSize: "30px", color: "green" }}
                  >
                    MAMANDUR FOREST
                  </h1>
                  <br />
                  <div className="AttractionItems">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="row">
                        <p>
                          The Mamandur forest located on Chennai – Kadapa High
                          way, consists of high degree of bio diversity along
                          with some highly valuable endemic and endangered
                          species. The forest is deciduous type but mixed with
                          some ever green elements. Mamandur is bountifully
                          endowed with nature in the form of hills and valleys,
                          many picturesque spots and seasonal waterfalls and
                          ponds of Eastern Ghats.
                        </p>
                        <h4>Activities</h4>
                        <p>
                          Trekking, Nature trail, Jungle safari, Wild animal
                          sighting, Jungle camping, Thirthams visit.
                        </p>
                        <h4>Facilities:</h4>
                        <p>
                          Jungle Resort (Jungle huts, Tent houses, Dormitory),
                          Harini Resource Center (Restaurant, Forest Product
                          Sales and Information Center), Rangers Bungalow
                          (Library, Photo gallery, Indoor and Out door games),
                          Guide Facilities, Watch Towers, Eco-trail paths,
                          Resting places, Ring road (Prakrutibata).
                        </p>
                        <br />
                        <br />
                       
                        <br />
                        <br />
                     
                      </div>
                    </div>
                    <div className="row">
                      
                      <h4>Contact Numbers</h4>
                      <p>
                       For information Please Contact.</p>
                       <p>
                       CBET Mamandur :  9618350557
                       <br/>

                      Deputy Range Officer : 9849083700
                        <br />
                        Forest Range Officer : 7981585207
                      </p>
                      <br />
                   
                    </div>
                    <div className="clearfix">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MamandurForest;
