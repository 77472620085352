





import React, { useEffect, useState } from 'react';
import Navbar from "./Navbar";
import Header from "./Header";
import './Accomodation.css';
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import './PrintPreviewStyles.css';

const BookingSummary = () => {
  const navigate = useNavigate();
  const [aadhaar, setAadhaar] = useState('');
  let sumGrandTotalAmount = 0;
  localStorage.removeItem('bookingDetailsDataFinal')
  if(localStorage.getItem("access_token") == undefined || localStorage.getItem("access_token") === ""){
    localStorage.removeItem('bookingDetailsData')
  }
  if(localStorage.getItem('bookingDetailsData') == undefined){
    navigate("/Accomodation", "_self");
  }
  let billingRequest = JSON.parse(localStorage.getItem('billingRequest'));
  let bookingDetailsData =  billingRequest.roomData;
  let bookingDetailsDataFinal = [];
  let seqNo = 1;
  console.log(billingRequest,"billingRequest");
  

  let srNo = 0;
  let grandTotal = 0;

  let captchSumValue = 0;


  const [logo1Loaded, setLogo1Loaded] = useState(false);
  const [logo2Loaded, setLogo2Loaded] = useState(false);
  const [isPrintMode, setIsPrintMode] = useState(false);

  const handleLogo1Load = () => {
    setLogo1Loaded(true);
  };

  const handleLogo2Load = () => {
    setLogo2Loaded(true);
  };
 
  const printSummary = async () => {
  if (!logo1Loaded || !logo2Loaded) {
    return;
  }
  setIsPrintMode(true);

  const customerName = billingRequest.name.replace(/\s+/g, ''); 
  const currentDate = moment().format('YYYY-MM-DD');
  const randomThreeDigitNumber = Math.floor(Math.random() * 900) + 100;
  const fileName = `${customerName}_${currentDate}_${randomThreeDigitNumber}.pdf`;

  const pdfOptions = {
    margin: 10,
    filename: fileName,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  };

  const content = document.getElementById("print_content");

  var printContents = content.innerHTML;
  var cancellationPolicyContent = document.querySelector(".cancel-pol-note").innerHTML;
  printContents += `<div>${cancellationPolicyContent}</div>`;

  var originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;

  setTimeout(() => {
    window.print();
    document.body.innerHTML = originalContents;
    setIsPrintMode(false);
  }, 0);
};

  
  console.log("aadhaar",aadhaar);

  return (
    <div>

    <Header />
    <div className="container" id = "print_content"> 
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back">
      <div className="logos-container">
            <img
              width="100"
              src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/logos/logo.png"
              onLoad={handleLogo1Load}
              alt="Logo 1"
              className="logo1"
            />
            <img
              width="300"
              src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/logos/logo3.png"
              onLoad={handleLogo2Load}
              alt="Logo 2"
              className="logo2"
            />
          </div><br/>

<h2 style={{textAlign:"center"}}>Receipt</h2><br/>
          
<h2 className='center_title' style={{ textAlign: "center" ,fontSize:"16px"}}>Receipt - Booking Order ID: {billingRequest.orderId}</h2>

          
{billingRequest.razorpay_status == 'captured' ? <div className='center_title'>Your payment is successfully completed, please find the room details below</div> : <div className='center_title_red'>Your payment is successfully completed, please find the room details below</div>}
<br />
          <div className="col-md-12  billing_details_for">
          <div className="form-group">
              <div style={{ float: "left",paddingRight:"5px"  }}><label for="exampleInputEmail1">Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</label></div>
              <div >{billingRequest.name} </div>
            </div>
            <br/>
            <br/>
          
            <div className="form-group" >
              <div style={{ float: "left",paddingRight:"5px" }}><label for="exampleInputEmail1">Address &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</label></div>
              <div >{billingRequest.address}</div>
            </div>
            <br/>
            <div className="form-group">
              <div style={{ float: "left",paddingRight:"5px"  }}> <label for="exampleInputEmail1">Contact No &nbsp;&nbsp;&nbsp;&nbsp;:</label></div>
              <div > {billingRequest.contactno}</div>
            </div>
            <br/>
            <div className="form-group" >
              <div style={{ float: "left",paddingRight:"5px" }}><label for="exampleInputEmail1">E-mail &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</label></div>
              <div > {billingRequest.email}</div>
            </div>
            <br/>
          
<div className="form-group" >
          <div style={{ float: "left",paddingRight:"5px" }}>
            <label for="exampleInputEmail1">Proof &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</label>
          </div>
          <div style={{ float: "left" }}>
            {billingRequest.pan ? "PAN submitted" : billingRequest.aadhaar ? "Aadhaar submitted" : "No Proof available" }
          </div>
        </div>

            <br />
            <br />

          </div>
          <div className="col-md-3 center"></div>
          <table className='table table-striped'>

            <th>S.No</th><th>Room Name</th><th>Date</th><th>Amount</th><th>No.of.Persons</th><th>Subtotal</th>
            {
              bookingDetailsData.map((e, i) => {

                grandTotal = grandTotal + (e.finalGrandTotalVal ? e.finalGrandTotalVal : e.amount);
                srNo++;
                return (<tr><td>{srNo}</td><td>{e.room_name} </td><td>{moment(e.bookingDate).format('yyyy-MM-DD')}</td><td>{e.amount}</td>
                  <td>{e.selectedPersons}</td><td> {e.finalGrandTotalVal ? e.finalGrandTotalVal : e.amount} </td></tr>)
              }
              )}
            <tr><td colSpan="4"></td><td>Grand Total</td><td>{grandTotal} /-</td></tr>

          </table>
          <button className='back_button float_left' onClick={() => navigate("/Accomodation", "_self")}>Back</button>
          <button className='booking_button float_right' onClick={() => printSummary()}>Print</button>
          </div>
          </div>

          <div className="cancel-pol-note" style={{ display: isPrintMode ? 'block' : 'none',marginLeft:"50px" }}>
        <h1 className="cancel-pol-note-h1" >Cancellation Policy </h1><br />
          <ul className="cancel-pol-notes">
            <li>Cancellation will be accepted only 48 hours before the <strong>Check-In</strong> .</li>
            <li>Cancellation requests received from 48 hours to <strong>Check-In</strong> will not be eligible for refund.</li>
            <li>50% of the booking amount will be refunded if the cancellation is made within the specified timelines. GST & other charges will not be refunded.</li>
            <li>Refunds will be processed after the cancellation is approved.</li>
            <li>Customers acknowledge and agree to this policy upon making a booking.</li>
          </ul>
        </div>
    </div>
  </div>

  );
}
export default BookingSummary;



