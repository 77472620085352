import React from "react";
import Header from "./Header";

const CancellationPolicy = () => {
  return (
    <div className="cancel-pol">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div className="col-lg-1O col-md-10 col-sm-10 col-xs-10">
            <h1 style={{textAlign:"center",paddingTop:"20px"}}>Cancellation Policy</h1>
            <div className="cancel-pols">
            <ul style={{ fontSize: "21px" }}>
              <li>Cancellation will be accepted only 48 hours before the <strong>Check-In</strong> .</li>
              <li>Cancellation requests received from 48 hours to <strong>Check-In</strong> we not be accepted.</li>
              <li>50% of the booking amount will be refunded if the cancellation is made within the specified timelines. GST & other charges will not be refunded.</li>
              <li>Refunds will be processed after the cancellation is approved.</li>
              <li>Customers acknowledge and agree to this policy upon making a booking.</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancellationPolicy;
