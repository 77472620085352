import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";

import Header from "../components/Header";

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    otp: "",
    email: "",
    password: "",
    address: "",
    city: "",
    state: "",
    idProofType: "",
    idProofDetails: "",
  });

  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    switch (name) {
      case "name":
        if (!value.trim()) {
          errorMessage = "Name is required.";
        }
        break;

      case "mobile":
        const isMobileValid = validateMobileNumber(value);
        if (!isMobileValid) {
          errorMessage =
            "Invalid mobile number. Please enter a 10-digit number.";
        }
        break;

      case "otp":
        if (!value.trim()) {
          errorMessage = "Please enter the OTP.";
        }
        break;
      case "email":
        if (!value.trim()) {
          errorMessage = "Email is required.";
        }
        break;

      case "password":
        if (!value.trim()) {
          errorMessage = "Password is required.";
        }
        break;
      case "address":
        if (!value.trim()) {
          errorMessage = "Address is required.";
        }
        break;

      case "city":
        if (!value.trim()) {
          errorMessage = "City is required.";
        }
        break;

      case "state":
        if (!value.trim()) {
          errorMessage = "State is required.";
        }
        break;

      case "idProofDetails":
        if (formData.idProofType === "Aadhar") {
          const isAadharValid = validateAadharNumber(value);
          if (!isAadharValid) {
            errorMessage =
              "Invalid Aadhar number. Please enter a 12-digit number.";
          }
        } else if (formData.idProofType === "Pancard") {
          const isPancardValid = validatePancard(value);
          if (!isPancardValid) {
            errorMessage =
              "Invalid Pancard. Please enter a 10-character combination of alphabets and numbers.";
          }
        }
        break;

      case "idProofType":
        setFormData({
          ...formData,
          idProofType: value,
          idProofDetails: "",
        });
        break;

      default:
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    setError(errorMessage);
  };

  const validateMobileNumber = (mobile) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };
  const validateAadharNumber = (aadhar) => {
    const aadharRegex = /^[0-9]{12}$/;
    return aadharRegex.test(aadhar);
  };

  const validatePancard = (pancard) => {
    const pancardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    return pancardRegex.test(pancard);
  };

  const handleSendOtp = async () => {
    try {
      const isMobileValid = validateMobileNumber(formData.mobile);

      if (!formData.name.trim()) {
        setError("Name is required.");
      } else if (!isMobileValid) {
        setError("Invalid mobile number. Please enter a 10-digit number.");
      } else {
        setError(""); // Clear previous error
        const response = await axios.post(
          "https://api.vanadarshani.com/usermobnumcheck",
          {
            username: formData.name,
            mobile_number: formData.mobile,
          }
        );

        if (response.data.status === 200) {
          setOtpSent(true);
        } else {
          setError("Mobile Number Already Exists");
        }
      }
    } catch (error) {}
    console.error("Failed to send OTP:", error.message);
  };
  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        "https://api.vanadarshani.com/userRegvfyotp",
        {
          otp: formData.otp,
          username: formData.name,
          mobile_number: formData.mobile,
        }
      );

      if (response.data.status === 200) {
        setOtpVerified(true);
      } else {
        setError("Invalid OTP");
      }
    } catch (error) {
      console.error("Failed to verify OTP:", error.message);
    }
  };

  const handleRegister = async () => {
    // Check if all required fields are filled
    const requiredFields = [
      "name",
      "mobile",
      "otp",
      "email",
      "password",
      "address",
      "city",
      "state",
      "idProofType",
    ];
    const missingFields = requiredFields.filter(
      (field) => !formData[field].trim()
    );

    if (missingFields.length > 0) {
      setError(
        `Please fill in the following fields: ${missingFields.join(", ")}`
      );
      return;
    }
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

    if (!passwordRegex.test(formData.password)) {
      setError(
        "Password must be at least 6 characters long and contain alphabets, numbers, and special characters."
      );
      return;
    }

    if (formData.idProofType === "Aadhar") {
      if (
        !formData.idProofDetails ||
        !/^\d{12}$/.test(formData.idProofDetails)
      ) {
        setError(
          "Aadhar card details must be 12 digits long and contain only numbers."
        );
        return;
      }
    } else if (formData.idProofType === "Pancard") {
      if (
        !formData.idProofDetails ||
        !/^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/.test(formData.idProofDetails)
      ) {
        setError(
          "PAN card details must be 10 characters long, including both alphabets and numbers."
        );
        return;
      }
    }

    try {
      const response = await axios.post(
        "https://api.vanadarshani.com/userRegister",
        {
          password: formData.password,
          mobile_number: formData.mobile,
          email_id: formData.email,
          name: formData.name,
          idProofDetails: formData.idProofDetails,
          idProofType: formData.idProofType,
          address: formData.address,
          city: formData.city,
          state: formData.state,
        }
      );

      if (response.data.status === 200) {
        alert("Registration successful!");
        try {
          const emailResponse = await axios.post(
            "https://api.vanadarshani.com/userCredentialEmail",
            {
              email: formData.email,
              password: formData.password,
              mobile_number: formData.mobile,
              name: formData.name,
            }
          );

          if (emailResponse.data.status === 200) {
            alert("Email with credentials sent successfully!");
            navigate("/login");
          } else {
            setError("Failed to send email with credentials");
          }
        } catch (emailError) {
          console.error("Email sending failed:", emailError.message);
          setError("Failed to send email with credentials");
        }

        // Optionally, you can navigate to another page after successful registration
        // navigate("/login");
      } else {
        setError("Failed to register");
      }
    } catch (error) {
      console.error("Registration failed:", error.message);
    }
  };

  const renderIdProofDetailsField = () => {
    if (
      formData.idProofType === "Aadhar" ||
      formData.idProofType === "Pancard"
    ) {
      return (
        <div>
          <label>
            Enter {formData.idProofType} Details:
            <input
              type="text"
              name="idProofDetails"
              value={formData.idProofDetails}
              onChange={handleChange}
              style={{ width: "100%" }}
              min="0"
              max="12"
            />
          </label>
          <br />
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Header />

      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div
            className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back"
            style={{ backgroundColor: "#e0ff97" }}
          >
            <h1
              className="text-center"
              style={{ fontSize: "25px", color: "green" }}
            >
              Register
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ width: "400px" }}>
                <form>
                  <div className="mb-3">
                    <label className="form-label">
                      Name:&nbsp;&nbsp;&nbsp;
                    </label>
                    <br />
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <br />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Mobile Number:</label>
                    <br />
                    <input
                      type="text"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                  </div>
                  {!otpSent && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSendOtp}
                    >
                      Send OTP
                    </button>
                  )}

                  {otpSent && !otpVerified && (
                    <div>
                      <div className="mb-3">
                        <label className="form-label">Enter OTP:</label>
                        <br />
                        <input
                          type="text"
                          name="otp"
                          value={formData.otp}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleVerifyOtp}
                        disabled={!formData.otp}
                      >
                        Verify OTP
                      </button>
                    </div>
                  )}

                  {otpVerified && (
                    <div>
                      <div className="mb-3">
                        <label className="form-label">Email:</label>
                        <br />
                        <input
                          type="text"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Password:</label>
                        <br />
                        <input
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Address:</label>
                        <br />
                        <input
                          type="text"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">City:</label>
                        <br />
                        <input
                          type="text"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Select ID Proof:</label>
                        <br />
                        <select
                          className="form-select"
                          name="idProofType"
                          value={formData.idProofType}
                          onChange={handleChange}
                        >
                          <option value="">Select ID Proof</option>
                          <option value="Aadhar">Aadhar</option>
                          <option value="Pancard">Pancard</option>
                        </select>
                      </div>

                      {renderIdProofDetailsField()}

                      <div className="mb-3">
                        <label className="form-label">State:</label>
                        <br />
                        <input
                          type="text"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        />
                      </div>

                      <button
                        type="button"
                        className="btn btn3"
                        onClick={handleRegister}
                      >
                        Register
                      </button>
                    </div>
                  )}

                  {error && (
                    <div className="alert alert-danger mt-3">{error}</div>
                  )}
                </form>
                <div className="mt-3">
                  <p className="mt-3">
                    If Already registered, <Link to="/login">Login here</Link>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;

// import React, { useState } from "react";
// import axios from "axios";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Link, useNavigate } from "react-router-dom";
// import Header from "../components/Header";

// const RegisterPage = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     mobile: "",
//     email: "",
//     password: "",
//     address: "",
//     city: "",
//     state: "",
//     idProofType: "",
//     idProofDetails: "",
//   });

//   const [error, setError] = useState("");
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     let errorMessage = "";

//     switch (name) {
//       case "name":
//         if (!value.trim()) {
//           errorMessage = "Name is required.";
//         }
//         break;

//       case "mobile":
//         const isMobileValid = validateMobileNumber(value);
//         if (!isMobileValid) {
//           errorMessage =
//             "Invalid mobile number. Please enter a 10-digit number.";
//         }
//         break;

//       case "email":
//         if (!value.trim()) {
//           errorMessage = "Email is required.";
//         }
//         break;

//       case "password":
//         if (!value.trim()) {
//           errorMessage = "Password is required.";
//         }
//         break;

//       case "address":
//         if (!value.trim()) {
//           errorMessage = "Address is required.";
//         }
//         break;

//       case "city":
//         if (!value.trim()) {
//           errorMessage = "City is required.";
//         }
//         break;

//       case "state":
//         if (!value.trim()) {
//           errorMessage = "State is required.";
//         }
//         break;

//       case "idProofDetails":
//         if (formData.idProofType === "Aadhar") {
//           const isAadharValid = validateAadharNumber(value);
//           if (!isAadharValid) {
//             errorMessage =
//               "Invalid Aadhar number. Please enter a 12-digit number.";
//           }
//         } else if (formData.idProofType === "Pancard") {
//           const isPancardValid = validatePancard(value);
//           if (!isPancardValid) {
//             errorMessage =
//               "Invalid Pancard. Please enter a 10-character combination of alphabets and numbers.";
//           }
//         }
//         break;

//       case "idProofType":
//         setFormData({
//           ...formData,
//           idProofType: value,
//           idProofDetails: "",
//         });
//         break;

//       default:
//     }

//     setFormData({
//       ...formData,
//       [name]: value,
//     });

//     setError(errorMessage);
//   };

//   const validateMobileNumber = (mobile) => {
//     const mobileRegex = /^[0-9]{10}$/;
//     return mobileRegex.test(mobile);
//   };

//   const validateAadharNumber = (aadhar) => {
//     const aadharRegex = /^[0-9]{12}$/;
//     return aadharRegex.test(aadhar);
//   };

//   const validatePancard = (pancard) => {
//     const pancardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
//     return pancardRegex.test(pancard);
//   };

//   const handleRegister = async () => {
//     // Check if all required fields are filled
//     const requiredFields = [
//       "name",
//       "mobile",
//       "email",
//       "password",
//       "address",
//       "city",
//       "state",
//       "idProofType",
//     ];
//     const missingFields = requiredFields.filter(
//       (field) => !formData[field].trim()
//     );

//     if (missingFields.length > 0) {
//       setError(
//         `Please fill in the following fields: ${missingFields.join(", ")}`
//       );
//       return;
//     }

//     const passwordRegex =
//       /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

//     if (!passwordRegex.test(formData.password)) {
//       setError(
//         "Password must be at least 6 characters long and contain alphabets, numbers, and special characters."
//       );
//       return;
//     }

//     if (formData.idProofType === "Aadhar") {
//       if (
//         !formData.idProofDetails ||
//         !/^\d{12}$/.test(formData.idProofDetails)
//       ) {
//         setError(
//           "Aadhar card details must be 12 digits long and contain only numbers."
//         );
//         return;
//       }
//     } else if (formData.idProofType === "Pancard") {
//       if (
//         !formData.idProofDetails ||
//         !/^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/.test(formData.idProofDetails)
//       ) {
//         setError(
//           "PAN card details must be 10 characters long, including both alphabets and numbers."
//         );
//         return;
//       }
//     }

//     try {
//       const response = await axios.post(
//         "https://api.vanadarshani.com/userRegister",
//         {
//           password: formData.password,
//           mobile_number: formData.mobile,
//           email_id: formData.email,
//           name: formData.name,
//           idProofDetails: formData.idProofDetails,
//           idProofType: formData.idProofType,
//           address: formData.address,
//           city: formData.city,
//           state: formData.state,
//           password: formData.password,
//           //         mobile_number: formData.mobile,
//           //         email_id: formData.email,
//           //         name: formData.name,
//           //         idProofDetails: formData.idProofDetails,
//           //         idProofType: formData.idProofType,
//           //         address:formData.address,
//           //         city: formData.city,
//           //         state: formData.state
//         }
//       );

//       if (response.data.status === 200) {
//         alert("Registration successful!");
//         try {
//           const emailResponse = await axios.post(
//             "https://api.vanadarshani.com/userCredentialEmail",
//             {
//               email: formData.email,
//               password: formData.password,
//               mobile_number: formData.mobile,
//               name: formData.name,
//             }
//           );

//           if (emailResponse.data.status === 200) {
//             alert("Email with credentials sent successfully!");
//             navigate("/login");
//           } else {
//             setError("Failed to send email with credentials");
//           }
//         } catch (emailError) {
//           console.error("Email sending failed:", emailError.message);
//           setError("Failed to send email with credentials");
//         }
//       } else {
//         setError("Failed to register");
//       }
//     } catch (error) {
//       console.error("Registration failed:", error.message);
//     }
//   };

//   const renderIdProofDetailsField = () => {
//     if (
//       formData.idProofType === "Aadhar" ||
//       formData.idProofType === "Pancard"
//     ) {
//       return (
//         <div>
//           <label>
//             Enter {formData.idProofType} Details:
//             <input
//               type="text"
//               name="idProofDetails"
//               value={formData.idProofDetails}
//               onChange={handleChange}
//               style={{ width: "100%" }}
//               min="0"
//               max="12"
//             />
//           </label>
//           <br />
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <div>
//       <Header />
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-2"></div>
//           <div
//             className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back"
//             style={{ backgroundColor: "#e0ff97" }}
//           >
//             <h1
//               className="text-center"
//               style={{ fontSize: "25px", color: "green" }}
//             >
//               Register
//             </h1>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//             >
//               <div style={{ width: "400px" }}>
//                 <form>
//                   <div className="mb-3">
//                     <label className="form-label">
//                       Name:&nbsp;&nbsp;&nbsp;
//                     </label>
//                     <br />
//                     <input
//                       type="text"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleChange}
//                       style={{ width: "100%" }}
//                     />
//                     <br />
//                   </div>
//                   <div className="mb-3">
//                     <label className="form-label">Mobile Number:</label>
//                     <br />
//                     <input
//                       type="text"
//                       name="mobile"
//                       value={formData.mobile}
//                       onChange={handleChange}
//                       style={{ width: "100%" }}
//                     />
//                     <br />
//                   </div>
//                   <div className="mb-3">
//                     <label className="form-label">Email:</label>
//                     <br />
//                     <input
//                       type="text"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       style={{ width: "100%" }}
//                     />
//                     <br />
//                   </div>
//                   <div className="mb-3">
//                     <label className="form-label">Password:</label>
//                     <br />
//                     <input
//                       type="password"
//                       name="password"
//                       value={formData.password}
//                       onChange={handleChange}
//                       style={{ width: "100%" }}
//                     />
//                     <br />
//                   </div>
//                   <div className="mb-3">
//                     <label className="form-label">Address:</label>
//                     <br />
//                     <input
//                       type="text"
//                       name="address"
//                       value={formData.address}
//                       onChange={handleChange}
//                       style={{ width: "100%" }}
//                     />
//                     <br />
//                   </div>
//                   <div className="mb-3">
//                     <label className="form-label">City:</label>
//                     <br />
//                     <input
//                       type="text"
//                       name="city"
//                       value={formData.city}
//                       onChange={handleChange}
//                       style={{ width: "100%" }}
//                     />
//                     <br />
//                   </div>
//                   <div className="mb-3">
//                     <label className="form-label">Select ID Proof:</label>
//                     <br />
//                     <select
//                       className="form-select"
//                       name="idProofType"
//                       value={formData.idProofType}
//                       onChange={handleChange}
//                     >
//                       <option value="">Select ID Proof</option>
//                       <option value="Aadhar">Aadhar</option>
//                       <option value="Pancard">Pancard</option>
//                     </select>
//                     <br />
//                   </div>

//                   {renderIdProofDetailsField()}

//                   <div className="mb-3">
//                     <label className="form-label">State:</label>
//                     <br />
//                     <input
//                       type="text"
//                       name="state"
//                       value={formData.state}
//                       onChange={handleChange}
//                       style={{ width: "100%" }}
//                     />
//                     <br />
//                   </div>

//                   <button
//                     type="button"
//                     className="btn btn3"
//                     onClick={handleRegister}
//                   >
//                     Register
//                   </button>
//                   {error && (
//                     <div className="alert alert-danger mt-3">{error}</div>
//                   )}
//                 </form>
//                 <div className="mt-3">
//                   <p className="mt-3">
//                     If Already registered, <Link to="/login">Login here</Link>.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RegisterPage;
