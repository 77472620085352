
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./Gallery.css";
import Navbar from "../components/Navbar";
import Header from "./Header";

const WildLife = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  const wildlifeImages = [
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-01.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-03.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-04.png",
   
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-06.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-08.png",

    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-10.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-12.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-13.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-14.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-25.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-17.png",
    
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-20.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-21.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-22.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-23.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-15.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-26.png",
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-27.png",
    
   
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-31.png",
  
    "https://vanadarshini-images.s3.ap-south-1.amazonaws.com/wild-life/Animals+Names+Updated-33.png",
  
  
  ];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  const renderImageRows = (images, imagesPerRow) => {
    const rows = [];
    for (let i = 0; i < images.length; i += imagesPerRow) {
      const rowImages = images.slice(i, i + imagesPerRow);
      const row = (
        <div className="image-row" key={i}>
          {rowImages.map((image, index) => (
            <div key={index} className="image-column">
              <div className="image-container">
                <img
                  src={image}
                  onClick={() => openLightbox(i + index)}
                  alt={`Wild Life Image ${i + index}`}
                  className="img-fluid"
                />
              </div>
            </div>
          ))}
        </div>
      );
      rows.push(row);
    }
    return rows;
  };

  return (
    <div>
    
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back">
            <div className="row1">
              <h1
                className="text-center"
                style={{ fontSize: "40px", color: "green" }}
              >
                Wild Life
              </h1>
              {renderImageRows(wildlifeImages, 3)}

              {lightboxIsOpen && (
                <Lightbox
                  mainSrc={wildlifeImages[photoIndex]}
                  nextSrc={
                    wildlifeImages[(photoIndex + 1) % wildlifeImages.length]
                  }
                  prevSrc={
                    wildlifeImages[
                      (photoIndex + wildlifeImages.length - 1) %
                        wildlifeImages.length
                    ]
                  }
                  onCloseRequest={closeLightbox}
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex + wildlifeImages.length - 1) %
                        wildlifeImages.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % wildlifeImages.length)
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WildLife;
