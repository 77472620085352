// import React, { useState } from "react";
// import Navbar from "./Navbar";
// import Header from "./Header";
// import DateTab from "./DateTab";
// import MonthTab from "./MonthTab";
// import "./Accomodation.css";

// const BlockedReports = () => {
//   const [selectedLocation, setSelectedLocation] = useState("1"); // Default to Talakona

//   const handleLocationChange = (event) => {
//     setSelectedLocation(event.target.value);
//   };

//   return (
//     <div>
//       <Header />
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-2"></div>
//           <div className="col-lg-10 content_back">
//             <iframe
//               title="VANADARSHINI"
//               className="reports-bi"
//               src="https://app.powerbi.com/view?r=eyJrIjoiZjdiM2ZhNWUtMDY0Zi00OTEwLWE4YjctMDkzMGJiODc2MDBmIiwidCI6IjM5MmFlNDIzLWFiZTktNDIxOS1iMTU5LTBhZTc3YWMxOWZjYSJ9"
//               frameborder="0"
//               allowFullScreen="true"
//             ></iframe>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BlockedReports;

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Header from "./Header";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import "./Accomodation.css";

const host = "https://api.vanadarshani.com";
// const host = "http://localhost:5000";

const BlockedReports = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [blockedRooms, setBlockedRooms] = useState([]);

  useEffect(() => {
    if (
      !localStorage.getItem("access_token") ||
      localStorage.getItem("access_token") === ""
    ) {
      navigate("/Home", "_self");
    }
    if (localStorage.getItem("userRole") != 1) {
      navigate("/Home", "_self");
    }
  }, [navigate]);

  const handleFilter = () => {
    const formattedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : "";
    const formattedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : "";

    // let url = `${host}/api/blocking-details`;
    let url = `${host}/api/blocking-data`;

    if (startDate && endDate) {
      url += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => setBlockedRooms(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(blockedRooms);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BlockedRooms");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "blockedrooms.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: "a4",
    });

    doc.setProperties({
      title: "Vanadarshani Reports",
    });

    const headingText = "Vanadarshani Reports";
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth =
      (doc.getStringUnitWidth(headingText) * fontSize) /
      doc.internal.scaleFactor;
    const textX = (pageWidth - textWidth) / 2;
    doc.setFontSize(fontSize);
    doc.text(headingText, textX, 40);

    const subtitleText = `Blocked Rooms Reports`;
    const subtitleFontSize = 16;
    const subtitleWidth =
      (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
      doc.internal.scaleFactor;
    const subtitleX = (pageWidth - subtitleWidth) / 2;
    doc.setFontSize(subtitleFontSize);
    doc.text(subtitleText, subtitleX, 60);

    const tableColumn = [
      "Room Name",
      "Blocked Date",

      "Blocked By",
      "Check-in Date",
      "Extra Persons",
      "Room Amount",
      // "Status",
      "Subtotal",
    ];
    const tableRows = [];

    blockedRooms.forEach((blockedRoom) => {
      const blockedRoomData = [
        blockedRoom.room_name,
        new Date(blockedRoom.created_ts).toLocaleDateString(),

        // blockedRoom.is_blocked,
        blockedRoom.block_reason,
        new Date(blockedRoom.checkin_date).toLocaleDateString(),
        blockedRoom.extra_persons,
        blockedRoom.room_amount,
        // blockedRoom.status,
        blockedRoom.sub_total,
      ];
      tableRows.push(blockedRoomData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 80 });
    doc.save("blockedroomsreports.pdf");
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back">
            <div className="row">
              <b className="text-center" style={{ fontSize: "20px" }}>
                Vanadarshani Blocked Reports
              </b>
            </div>
            <br />
            <div className="filters">
              <h3>select Date:</h3>
              <label>From:</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
              />
              <label>To:</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
              />
              <button
                type="button"
                className="btn-primary"
                onClick={handleFilter}
                style={{ borderRadius: "20px" }}
              >
                Submit
              </button>
            </div>
            {blockedRooms.length > 0 ? (
              <>
                <div className="download-buttons">
                  <button
                    style={{ borderRadius: "20px" }}
                    className="btn-primary"
                    onClick={downloadExcel}
                  >
                    Download Excel
                  </button>
                  <button
                    style={{ borderRadius: "20px" }}
                    className="btn-primary"
                    onClick={downloadPDF}
                  >
                    Download PDF
                  </button>
                </div>
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th>Room Name</th>
                      <th>Created Date</th>
                      <th>Blocked Reason</th>
                      {/* <th>Blocked By</th> */}
                      <th>Check-in Date</th>
                      <th>Extra Persons</th>
                      <th>Room Amount</th>
                      {/* <th>Status</th> */}
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blockedRooms.map((blockedRoom) => (
                      <tr key={blockedRoom.order_id}>
                        <td>{blockedRoom.room_name}</td>
                        <td>
                          {new Date(
                            blockedRoom.created_ts
                          ).toLocaleDateString()}
                        </td>
                        <td>{blockedRoom.block_reason}</td>
                        {/* <td>{blockedRoom.is_blocked}</td> */}
                        <td>
                          {new Date(blockedRoom.date).toLocaleDateString()}
                        </td>
                        <td>{blockedRoom.extra_persons}</td>
                        <td>{blockedRoom.room_amount}</td>
                        {/* <td>{blockedRoom.status}</td> */}
                        <td>{blockedRoom.sub_total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>No blocked rooms found for the selected dates.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockedReports;
