import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Header from "./Header";
import "./Home.css";
import "./Contact.css";

const Contact = () => {
  return (
    <div>
      <div>
        <Header />

        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10  content_back">
              <div>
                <h2
                  style={{
                    textAlign: "center",
                    fontSize: "25px",
                    color: "green",
                  }}
                >
                  Andhra Pradesh Forest Department
                </h2>
                <br />
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "22px",
                    color: "green",
                  }}
                >
                  Tirupati District
                </h3>
                <br />

                <div>
                  <h4 className="headings" style={{ fontSize: "20px" }}>
                    SUB-DFO, TIRUPATI
                  </h4>
                  <p>Mobile Number&nbsp;&nbsp;: 9440810287</p>
                  <p>mail-id&nbsp;&nbsp;:&nbsp;&nbsp;subdfotpt@gmail.com</p>
                </div>

                <div>
                  <h4 className="headings" style={{ fontSize: "20px" }}>
                    CBET TALAKONA ECO-TOURISM, BHAKARAPET
                  </h4>
                  <p>Mobile Number&nbsp;&nbsp;: +91 8919150488</p>
                  <p>
                    mail-id&nbsp;&nbsp;:&nbsp;&nbsp;fro.chamalarange@gmail.com
                  </p>
                </div>

                <div>
                  <h4 className="headings" style={{ fontSize: "20px" }}>
                    CBET MAMANDUR ECO-TOURISM, TIRUPATI
                  </h4>
                  <p>Mobile Number&nbsp;&nbsp;: 9440810303</p>
                  <p>mail-id&nbsp;&nbsp;:&nbsp;&nbsp;wlmfrotpt@gmail.com </p>
                  <p>mail-id&nbsp;&nbsp;:&nbsp;&nbsp;frosvnptpt@gmail.com</p>
                </div>

                <div>
                  <h4 className="headings" style={{ fontSize: "20px" }}>
                    AARE WATERFALLS, PUTTUR
                  </h4>
                  <p>Mobile Number&nbsp;&nbsp;: 9440810295</p>
                  <p>mail-id&nbsp;&nbsp;:&nbsp;&nbsp;froptr123@gmail.com</p>
                </div>

                <div>
                  <h4 className="headings" style={{ fontSize: "20px" }}>
                    UBBALAMADUGU WATERFALLS, SATYAVEDU
                  </h4>
                  <p>Mobile Number&nbsp;&nbsp;: 9440810293</p>
                  {/* <p>mail-id&nbsp;&nbsp;:&nbsp;&nbsp;wlmfrotpt@gmail.com </p>
                  <p>mail-id&nbsp;&nbsp;:&nbsp;&nbsp;frosvnptpt@gmail.com</p> */}
                  <p>mail-id&nbsp;&nbsp;:&nbsp;&nbsp;frostvd@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
