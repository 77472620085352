import React from 'react';
import Navbar from "../components/Navbar";
import Header from "./Header";
import './Table.css';
import UbbalamaduguImages from './UbbalamaduguImages';

const Ubbalamadugu = () => {
  return (
    <div>
 <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back">
          <UbbalamaduguImages/>
          
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <div className="links_class">
                  <br />
                 
                </div>
               
                <br />
                <h1
                  className="text-center"
                  style={{ fontSize: "30px", color: "green" }}
                >
                 UBBALAMADUGU WATERFALLS
                </h1>
                <br/>
                {/* <h1
                  className="text-center"
                  style={{ fontSize: "20px", color: "green" }}
                >
                COMPT.NO.260, KAMBAKAM RF / BEAT / SECTION OF SATYAVEDU RANGE
                </h1> */}
                {/* <br /> */}
                <div className="AttractionItems">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="row" style={{lineHeight:"28px",textAlign:"justify",justifyContent:"initial",marginLeft:"20px"}}>
                      <p>
                      A community Based Eco-Tourism concept was developed at Ubbalamadugu through GVR Dharakasthu VSS at Kambakam RF / Beat / Section of Satyavedu Range.

                      </p>
                      <p>
                      The objective of this Eco-Tourism is to inspire, inform and enable communities to turn the Eco-system into a sustainable source of livelihood, through well managed tourism. The tourism centre is abetting the famous Siddulaiah Swamy Temple inside the hills. The tourism center is developed to get the benefits of these visitors and educate them on the importance of forest and wildlife. The CBET was developed during the year 2009 under the APCFM project and named as “Ubbalamadugu CBET”. A few informative and educative models and signboards have been developed to educate the visitors. As a result on an average about 1 Lakh people visit the forest annually. It is also provides directly livelihood to more than 19 families of GVR Dharakasthu VSS and indirect livelihood to more than 500 people in Varadaiahpalem Mandal and Satyavedu Mandal.
                      </p>
                      <div>
      <h4>CBET Ubbalamadugu Location : </h4>
      <p>
        CBET Ubbalamadugu is located in Compartment No: 260, GVR Dharakasthu VSS of Kambakkam RF / Beat / Section of Satyavedu Range.
      </p>

      <h4>Location Details : </h4>
      <ul>
        <li>
          <strong>Coordinates:</strong> N: 130° 37’ 25.58”, E: 790° 50’ 48.55” (Compartment No. 260)
        </li>
        <li>
          <strong>Distance from Satyavedu:</strong> 35 Kms
        </li>
        <li>
          <strong>Distance from Varadaiahpalem:</strong> 10 Kms
        </li>
      </ul>

      <h4>Proposed Highlights : </h4>
      <p>
        It is proposed to highlight the Conservation of nature, environmental education, and ecological amelioration. 
      </p>
      <p>There is an abundant supply of water through ever-flowing freshwater streams in the hills.</p>



      <h4>Objectives : </h4>
      <ul>
        <li>To conserve the native flora and fauna.</li>
        <li>To impart environmental education to the visitors.</li>
      </ul>

     
    </div>
    <div>
      <h4>Local Communities Involved : </h4>
      <p>
        The project is maintained by VSS of Kambakam RF / Beat / Section of Satyavedu Range. A 15 member executive body was elected from the VSS to manage the CBET. Any proposals of the CBET are approved through resolutions with the signatures of all the members of CBET. A president and Vice-president are elected for the committee, and the concerned Forest Section Officer is the member secretary.
      </p>

      <p>
        The fund management of the CBET is being regulated under proper approval by the Conservator of Forests, Wildlife Management Circle, Tirupati, and by the Divisional Forest Officer, Chittoor East (WL) Division, Chittoor.
      </p>

      <h4>Existing Infrastructure : </h4>
      <ul>
        <li>Entrance Arch</li>
        <li>Ticket counter cum Resting shed at the entrance gate</li>
        <li>A lawn developed at the entrance</li>
        <li>Public conveniences - 3 Nos</li>
        <li>Improvement of Road</li>
        <li>Improvement of Parking area</li>
        <li>Cafeteria</li>
        <li>Twin towers</li>
        <li>Iron foot bridge over the stream</li>
        <li>Check Dams</li>
        <li>Gazebo Structures</li>
        <li>Entrance Board</li>
        <li>RO System</li>
        <li>Education inter predation Boards</li>
        <li>Water storage tank and Motor Shed</li>
        <li>Benches</li>
        <li>Solar lights</li>
        <li>Stone Pathways</li>
        <li>Chubutar</li>
      </ul>
    </div>

    <div>
     
      <div>
      <h4>Existing System of Entry Fee : </h4>
      <table className="entry-fee-table">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Vehicle type / Person</th>
            <th>Present rate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Each individual person</td>
            <td>Rs. 50/- per person</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Local Persons</td>
            <td>Rs. 20/- per person</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Two-wheeler</td>
            <td>Rs. 50/- per vehicle + extra Rs. 50/- per person</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Auto</td>
            <td>Rs. 100/- per vehicle + extra Rs. 50/- per person</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Four-wheeler (car, others)</td>
            <td>Rs. 300/- + extra Rs. 50/- per person</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Heavy vehicle (Bus, Lorry, etc.)</td>
            <td>Rs. 500/- + extra Rs. 50/- per person</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Professional Cameras (SLR/DSLR)</td>
            <td>Rs. 500/-</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Still Camera</td>
            <td>Rs. 150/-</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>



                      

                      
                      
                    </div>
                    
                  </div>
                 
                </div>
                <div className="clearfix">&nbsp;</div>
               
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Ubbalamadugu