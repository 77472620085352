import React, { useEffect, useState, useCallback } from "react";

import Navbar from "../components/Navbar";
import Header from "./Header";
import "./Header.css";
import "./MyBookings.css";

import moment from "moment";

import { Link, useNavigate } from "react-router-dom";

const MyBookings = () => {
  const navigate = useNavigate();
  let host = "https://api.vanadarshani.com";
  // let host = "http://localhost:5000";
  const [paginatedBookingList, setPaginatedBookingList] = useState([]);

  const [noData, setNoData] = useState();
  const [bookingData, setBookingData] = useState([]);
  const [bookingList, setBookingList] = useState([]);
  const [statusCheck, setStatusCheck] = useState(false);
  const [isShow, invokeModal] = React.useState(false);
  const [editableRoom, setEditableRoom] = React.useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const handlePageChange = (newPage) => {
    console.log("Page changed to:", newPage);
    setCurrentPage(newPage);

    const newPaginatedBookingList = bookingList.slice(
      (newPage - 1) * itemsPerPage,
      newPage * itemsPerPage
    );

    setPaginatedBookingList(newPaginatedBookingList);
  };

  const myBookingsListCallback = useCallback(() => {
    myBookingsList();
  }, []);

  useEffect(() => {
    myBookingsListCallback();
  }, [currentPage, myBookingsListCallback]);

  let userRole = localStorage.getItem("userRole");
  let billingRequest = JSON.parse("{}");
  let srNo = 0;
  if (
    localStorage.getItem("access_token") == undefined ||
    localStorage.getItem("access_token") === ""
  ) {
    navigate("/Home", "_self");
  }

  const initModal = () => {
    return invokeModal(!false);
  };
  const closeInitModal = () => {
    return invokeModal(false);
  };
  // Add this inside your component function
  useEffect(() => {
    console.log("Booking List:", bookingList);
  }, [bookingList]);

  const summaryView = (booking_group_id) => {
    fetch(
      host +
        "/getBookingSummary?bookingGroupId=" +
        booking_group_id +
        "&access_token=" +
        localStorage.getItem("access_token") +
        "&time=" +
        Math.round(new Date().getTime() / 1000),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      }
    ).then((response) => {
      response.json().then((rooms) => {
        if (rooms.length === 0) {
          console.error("No rooms found");
          return;
        }

        let bookingDetailsDataFinal = [];
        const orderId = rooms[0].order_id;
        console.log("Order ID:", orderId);

        billingRequest.locationId = rooms[0].location_id;
        billingRequest.name = rooms[0].customer_name;
        billingRequest.address = rooms[0].address;
        billingRequest.contactno = rooms[0].contact_no;
        billingRequest.email = rooms[0].email_id;
        billingRequest.pan = rooms[0].pan_number;
        billingRequest.aadhaar = rooms[0].aadhaar_number;
        billingRequest.razorpay_status = rooms[0].razorpay_status;
        billingRequest.orderId = rooms[0].booking_id;
        billingRequest.bookingId = rooms[0].order_id;

        rooms.map((row) => {
          let tmpBookingDetailsDataFinal = JSON.parse("{}");
          tmpBookingDetailsDataFinal["room_name"] = row.room_name;
          tmpBookingDetailsDataFinal["bookingDate"] = row.date;
          tmpBookingDetailsDataFinal["amount"] = row.sub_total;
          tmpBookingDetailsDataFinal["selectedPersons"] = row.extra_persons;
          tmpBookingDetailsDataFinal["finalGrandTotalVal"] = row.sub_total;

          bookingDetailsDataFinal.push(tmpBookingDetailsDataFinal);
          console.log("Row Sub Total:", row.sub_total);
        });

        billingRequest.roomData = bookingDetailsDataFinal;
        localStorage.setItem("billingRequest", JSON.stringify(billingRequest));
        localStorage.setItem("bookingDetailsData", JSON.stringify("{}"));

        navigate("/BookingSummary", { state: { orderId: rooms[0].order_id } });
      });
    });
  };

  console.log("tmpBookingDetailsDataFinal");

  const myBookingsList = async () => {
    try {
      const response = await fetch(
        host +
          "/getMyBookingsList?access_token=" +
          localStorage.getItem("access_token") +
          "&time=" +
          Math.round(new Date().getTime() / 1000) +
          "&location=" +
          document.getElementById("locationIds").value,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch bookings");
      }

      const rooms = await response.json();

      if (!Array.isArray(rooms)) {
        throw new Error("Invalid response format");
      }

      let bookingListArray = [];
      let bookingListTmp = {};

      if (rooms.length === 0) {
        setNoData("No booking details available");
      }

      rooms.forEach((row) => {
        if (bookingListTmp[row.booking_group_id] != undefined) {
          let tmp = bookingListTmp[row.booking_group_id];
          tmp.room_names =
            tmp.room_names +
            ", " +
            (row.accommodation_type + " - (" + row.days + ")");
          bookingListTmp[row.booking_group_id] = tmp;
        } else {
          row.room_names = row.accommodation_type + " - (" + row.days + ")";
          bookingListTmp[row.booking_group_id] = row;
        }

        // Add sub_total to the booking object if it's not already set
        if (!bookingListTmp[row.booking_group_id].sub_total) {
          bookingListTmp[row.booking_group_id].sub_total = row.sub_total;
        }
      });

      Object.keys(bookingListTmp).forEach((key) => {
        bookingListArray.push(bookingListTmp[key]);
      });

      const updatedBookingList = bookingListArray.map((booking) => {
        const checkOutDate = moment(booking.date);
        const currentDate = moment();
        const isDateCompleted = currentDate.isAfter(checkOutDate, "day");

        return {
          ...booking,
          cancelDisabled: booking.status === "Canceled" || isDateCompleted,
        };
      });

      setBookingList(updatedBookingList);

      const newTotalPageCount = Math.ceil(
        updatedBookingList.length / itemsPerPage
      );
      setTotalPageCount(newTotalPageCount);

      // Update the paginated list based on the current page
      const newPaginatedBookingList = updatedBookingList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );

      setPaginatedBookingList(newPaginatedBookingList);

      console.log("Total Page Count:", newTotalPageCount);
    } catch (error) {
      console.error("Error fetching bookings:", error);
      // Handle error
    }
  };

  const [totalPageCount, setTotalPageCount] = useState(1);

  const bookingSummary = () => {};

  // const cancelBooking = (bookingGroupId, bookingDate) => {
  //   const booking = bookingList.find(
  //     (booking) => booking.booking_group_id === bookingGroupId
  //   );

  //   if (!booking || booking.status === "Canceled") {
  //     console.log("Booking not found or already canceled");
  //     return;
  //   }

  //   const checkInDate = moment(bookingDate);
  //   const currentDate = moment();
  //   const hoursDifference = checkInDate.diff(currentDate, "hours");

  //   // Check if cancellation is allowed (more than 48 hours before check-in)
  //   if (hoursDifference >= 48) {
  //     if (window.confirm("Are you sure you want to cancel this booking?")) {
  //       const refundAmount = booking.sub_total / 2;

  //       fetch(
  //         `${host}/cancelBooking?bookingGroupId=${bookingGroupId}&bookingDate=${bookingDate}&access_token=${localStorage.getItem(
  //           "access_token"
  //         )}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: localStorage.getItem("access_token"),
  //           },
  //         }
  //       )
  //         .then((response) => {
  //           if (response.ok) {
  //             console.log("Booking canceled successfully");

  //             // Immediately update the UI (local state)
  //             setBookingList((prevList) =>
  //               prevList.map((booking) =>
  //                 booking.booking_group_id === bookingGroupId
  //                   ? { ...booking, status: "Canceled", cancelDisabled: true }
  //                   : booking
  //               )
  //             );

  //             alert(
  //               `Booking canceled successfully. Refund Amount: Rs. ${refundAmount}. Refund will be processed within 15 working days.`
  //             );
  //           } else {
  //             console.error("Error canceling booking:", response.statusText);
  //             alert("Cancellation failed. Please try again later.");
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error handling cancellation:", error);
  //           alert("An error occurred while canceling the booking.");
  //         });
  //     }
  //   } else {
  //     alert("Booking cannot be canceled within 48 hours of check-in.");
  //   }
  // };

  const cancelBooking = (bookingGroupId, bookingDate) => {
    const booking = bookingList.find(
      (booking) => booking.booking_group_id === bookingGroupId
    );

    if (!booking || booking.status === "Canceled") {
      console.log("Booking not found or already canceled");
      return;
    }

    const checkInDate = moment(bookingDate);
    const currentDate = moment();
    const hoursDifference = checkInDate.diff(currentDate, "hours");

    if (hoursDifference >= 48) {
      if (window.confirm("Are you sure you want to cancel this booking?")) {
        fetch(
          `${host}/cancelBooking?bookingGroupId=${bookingGroupId}&bookingDate=${bookingDate}&access_token=${localStorage.getItem(
            "access_token"
          )}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("access_token"),
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.refundAmount) {
              setBookingList((prevList) =>
                prevList.map((booking) =>
                  booking.booking_group_id === bookingGroupId
                    ? { ...booking, status: "Canceled", cancelDisabled: true }
                    : booking
                )
              );

              alert(
                `Booking canceled successfully. Refund Amount: ₹${data.refundAmount}. Refund will be processed within 15 working days.`
              );
            } else {
              alert("Cancellation failed. Please try again later.");
            }
          })
          .catch((error) => {
            console.error("Error handling cancellation:", error);
            alert("An error occurred while canceling the booking.");
          });
      }
    } else {
      alert("Booking cannot be canceled within 48 hours of check-in.");
    }
  };
  // const cancelBooking = (bookingGroupId, bookingDate) => {
  //   const booking = bookingList.find(
  //     (booking) => booking.booking_group_id === bookingGroupId
  //   );

  //   if (!booking || booking.status === "Canceled") {
  //     console.log("Booking not found or already canceled");
  //     return;
  //   }

  //   const checkInDate = moment(bookingDate);
  //   const currentDate = moment();
  //   const hoursDifference = checkInDate.diff(currentDate, "hours");

  //   if (hoursDifference >= 48) {
  //     if (window.confirm("Are you sure you want to cancel this booking?")) {
  //       fetch(
  //         `${host}/cancelBooking?bookingGroupId=${bookingGroupId}&bookingDate=${bookingDate}&access_token=${localStorage.getItem(
  //           "access_token"
  //         )}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: localStorage.getItem("access_token"),
  //           },
  //         }
  //       )
  //         .then((response) => response.json())
  //         .then((data) => {
  //           if (data.refundAmount) {
  //             // Update state to show canceled status and disable cancel button after successful response
  //             setBookingList((prevList) =>
  //               prevList.map((booking) =>
  //                 booking.booking_group_id === bookingGroupId
  //                   ? { ...booking, status: "Canceled", cancelDisabled: true }
  //                   : booking
  //               )
  //             );

  //             alert(
  //               `Booking canceled successfully. Refund Amount: ₹${data.refundAmount}. Refund will be processed within 15 working days.`
  //             );
  //           } else {
  //             alert("Cancellation failed. Please try again later.");
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error handling cancellation:", error);
  //           alert("An error occurred while canceling the booking.");
  //         });
  //     }
  //   } else {
  //     alert("Booking cannot be canceled within 48 hours of check-in.");
  //   }
  // };

  const refreahData = () => {
    myBookingsList();
  };

  useEffect(() => {
    myBookingsList();
  }, [currentPage]);
  const onChangeLocation = () => {
    // populateAvailableRoomsInCalender(moment(nextDates[0]).format("yyyy-MM-DD"));
    //checkAvailability();
  };
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-2 col-md-2 col-sm-10 col-xs-10  content_back">
            <div className="row">
              <b>Booking Details</b>
            </div>
            <br />
            <div className="row">
              <div style={{ width: "20%" }}>
                <b>Location</b>
                <select id="locationIds" onChange={onChangeLocation}>
                  <option value="1">Talakona</option>
                  <option value="2">Mamandur</option>
                </select>
              </div>

              <div style={{ width: "20%" }}></div>
              <div style={{ width: "20%" }}>
                <button
                  className="booking_button float_right"
                  onClick={() => refreahData()}
                >
                  Refresh
                </button>
              </div>
            </div>
            <div className="row" style={{ overflowX: "auto" }}>
              <table className="table table-striped responsive-table">
                <thead>
                  <tr className="data-row-head">
                    <th>Sr_No</th>
                    <th>Room_Details</th>
                    <th>CheckIn_Date</th>
                    <th>Contact_Name</th>
                    <th>Contact_No</th>
                    <th>Booking_Status</th>
                    {userRole == 1 && <th>Admin_Blocked</th>}
                    <th>Receipt</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedBookingList.map((rm, index) => (
                    <tr key={rm.booking_group_id} className="data-row-data">
                      <td style={{ textAlign: "center" }}>
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>{rm.room_names}</td>
                      <td style={{ textAlign: "center" }}>
                        {moment(rm.date).format("yyyy-MM-DD")}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {rm.customer_name}
                      </td>
                      <td style={{ textAlign: "center" }}>{rm.contact_no}</td>
                      <td style={{ textAlign: "center" }}>
                        {rm.status === 1 && (
                          <td style={{ textAlign: "center" }}>
                            {rm.razorpay_status === "captured" ? (
                              <div
                                className="booking_status_booked"
                                style={{ textAlign: "center" }}
                              >
                                Confirmed
                              </div>
                            ) : (
                              <div
                                className="booking_status_blocked"
                                style={{ textAlign: "center" }}
                              >
                                Pending
                              </div>
                            )}
                          </td>
                        )}
                        {rm.status === 2 && (
                          <td style={{ textAlign: "center" }}>
                            <div className="booking_status_blocked">
                              Cancelled
                            </div>
                          </td>
                        )}
                      </td>
                      {userRole == 1 && (
                        <td>
                          {rm.is_blocked ? (
                            <div
                              className="booking_status_blocked"
                              style={{ textAlign: "center" }}
                            >
                              Blocked
                            </div>
                          ) : (
                            <div
                              className="booking_status_booked"
                              style={{ textAlign: "center" }}
                            >
                              Booked
                            </div>
                          )}
                        </td>
                      )}
                      <td style={{ textAlign: "center" }}>
                        <i
                          className="bx bx-info-circle"
                          onClick={() => summaryView(rm.booking_group_id)}
                        ></i>
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            cancelBooking(rm.booking_group_id, rm.date)
                          }
                          disabled={rm.cancelDisabled || rm.status === 2}
                          className={rm.cancelDisabled ? "disabledButton" : ""}
                          style={{ padding: "6px" }}
                        >
                          {rm.status === "Canceled" ? "Canceled" : "Cancel"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-lg-2"></div>
            <div className="pagination">
              <button
                className="btn-next-my"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <span>{`Page ${currentPage} of ${totalPageCount}`}</span>
              <button
                className="btn-next-my"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPageCount}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyBookings;
