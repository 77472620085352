import React from "react";
import Navbar from "../components/Navbar";
import Header from "./Header";
import TalakonaImages from "./TalakonaImages";

const TalakonaWaterfalls = () => {
  return (
    <div>
      
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 content_back">
          <TalakonaImages/>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <div className="links_class">
                  <br />
                 
                </div>
               
                <br />
                <h1
                  className="text-center"
                  style={{ fontSize: "30px", color: "green" }}
                >
                  TALAKONA WATERFALLS
                </h1>
                <br />
                <div className="AttractionItems">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="row">
                      <p>
                        Talakona is an improving tourism center that is being
                        attracted by tourists a lot, which is situated near the
                        Tirupati-Madanapalli highway, in Sri Venkateswara
                        National Park in Chittoor District of Andhra Pradesh.
                        With a height of 270 feet, it is the tallest waterfall
                        in Andhra Pradesh. At a distance of 49 KM from Tirupati,
                        220 KM from Chennai, and 105 Kms from Chittoor, Talakona
                        Falls are situated at a distance of 23 KM from
                        Bakarapeta, which is the nearest town. The waterfalls
                        are placed amidst dense forest surrounded by natural
                        beauty and greenery. It is believed that the water of
                        Talakona Falls is enriched with healing powers.
                      </p>
                      <p>
                        With an animal base consisting of endangered species
                        like Slender Loris, Indian Giant Squirrel, Mouse Deer,
                        Golden Gecko, Panther, Porcupine, Chital, and Sambar.
                        Endemic species like Red Sander, Cycas beddomeii, and
                        Enteda like giant plants and medicinal plants, Talakona
                        is a good place to visit for animal lovers.
                      </p>
                      <h4>Activities:</h4>
                      <p>
                        Trekking, Waterfalls visit, Wild animal sighting, Bird
                        Watching, Jungle safari drive.
                      </p>

                      <h4>Location:</h4>
                      <p>
                        From Renigunta Airport (75 Kms). From Tirupati (67 Kms).
                      </p>
                      <h4>Facilities Available:</h4>
                      <p>
                        Log huts complex (Log huts, Dormitory, and Cafeteria),
                        Jungle Safari vehicle, Eco-trail paths,Children Playground.
                      </p>
                      <h4>CBET Talakona Cafeteria Area:</h4>
                      <p>
                        Cafeteria is available at Cottages and food is provided in an Orderly basis for information please contact 9347455419.
                      </p>
                    </div>
                    <div className="row">
                      
                      <h4>Contact Numbers</h4>
                      <p>
                        CBET Talakona Chairperson : 9347455419
                        <br />
                        Forest Range Officer Bakarapeta : 8978080419
                      </p>
                      <br />
                   
                    </div>
                  </div>
                 
                </div>
                <div className="clearfix">&nbsp;</div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalakonaWaterfalls;
