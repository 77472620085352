import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./Accomodation.css";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Navigate, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";

const PapavinasanamTokenReports = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [summaryData, setSummaryData] = useState([]);

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tokenData, setTokenData] = useState([]);
  const [table1, setTable1] = useState(false);
  const [table2, setTable2] = useState(false);
  const [table3, setTable3] = useState(false);
  const [table4, setTable4] = useState(false);
  const [totalCollection, setTotalCollection] = useState();
  const [showSummaryButton1, setShowSummaryButton1] = useState(false);
  const [showSummaryButton2, setShowSummaryButton2] = useState(false);

  let host = "https://api.vanadarshani.com";
    // let host = "http://localhost:5000";

  useEffect(() => {
    // Redirect to login if access token is missing or user is not authorized
    if (
      !localStorage.getItem("access_token") ||
      localStorage.getItem("access_token") === "" ||
      localStorage.getItem("userRole") != 1
    ) {
      navigate("/Home", "_self");
    }
  }, [navigate]);
  const getTokenReport = async (e) => {
    e.preventDefault();
    console.log(startDate, endDate, "checking values");
    setTable1(true);
    setTable2(false);
    let response = await fetch(host + "/getPapavinasanamTokenReports", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
    }).then((response) =>
      response.json().then((res) => {
        setTokenData(res);
        const totalCollection = res.reduce(
          (sum, rm) => sum + Number(rm.total),
          0
        );
        setTotalCollection(totalCollection);
        console.log(res, tokenData, "token data checking");
        console.log(totalCollection, "total collection amount");
      })
    );
  };

  

  const handleSummaryGate1Click = async () => {
    setTable1(false);
    setTable2(false);
    setTable3(true);
    setTable4(false);
    try {
      const response = await fetch(host + "/getPapavinasanamTokenSummary", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const summaryData1 = await response.json();
      setSummaryData(summaryData1);
      console.log(summaryData, "summary data for Gate No 1");
    } catch (error) {
      console.error("There was a problem with fetching summary data:", error);
    }
  };

 

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = tokenData.slice(indexOfFirstRecord, indexOfLastRecord);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const generatePDF = async () => {
    console.log("pdf clicked");

    try {
      // Fetch all token data
      const response = await fetch(host + "/getPapavinasanamTokenReports", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
      });
      console.log(response, "reposne");
      const allTokenData = await response.json();

      // Calculate the total collection
      const totalCollection = allTokenData.reduce(
        (sum, rm) => sum + rm.total,
        0
      );

      // Initialize PDF document
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "a4",
      });

      // Set PDF properties including title
      doc.setProperties({
        title: "Papavinasanam  Reports",
      });

      // Add heading with custom font size and centered horizontally
      const headingText = "Papavinasanam  Reports";
      const fontSize = 20; // Change font size here
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth =
        (doc.getStringUnitWidth(headingText) * fontSize) /
        doc.internal.scaleFactor;
      const textX = (pageWidth - textWidth) / 2;
      doc.setFontSize(fontSize);
      doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

      // Add subtitle with total collection below the main title
      const subtitleText = `Total Collection: ${totalCollection}`;
      const subtitleFontSize = 16; // Font size for the subtitle
      const subtitleWidth =
        (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
        doc.internal.scaleFactor;
      const subtitleX = (pageWidth - subtitleWidth) / 2;
      doc.setFontSize(subtitleFontSize);
      doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

      // Add table with all token data
      doc.autoTable({
        head: [
          [
            "S No",
            "Receipt Number",
            "Date",
            "Vehicle Number",
            "Name",
            // "Number of Persons",
            "Phone",
            "City",
            "Two Wheelers",
            // "Three Wheelers",
            "Four Wheelers",
            "Four Wheelers Heavy",
            "Govt Vehicle",
            // "Still Cameras",
            // "Video Cameras",
            "Total",
          ],
        ],
        body: allTokenData.map((rm) => [
          rm.id,
          rm.reciept_token,
          // moment.utc(rm.created_at).format("YYYY-MM-DD HH:mm:ss"),
          rm.created_date,
          rm.vehicle_no,
          rm.name,
        //   rm.number_of_persons,
          rm.phone,
          rm.city,
          rm.two_wheeler,
        //   rm.three_wheelers,
          rm.four_wheeler,
          rm.four_wheeler_heavy,
          rm.govt_vehicle,
        //   rm.still_camers,
        //   rm.video_camers,
          rm.total,
        ]),
        startY: 50, // Adjust startY to provide space for the subtitle
        styles: { halign: "center" },
      });

      // Save the PDF
      doc.save("papavinasanam_reports.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const generatePDF1 = async () => {
    console.log("pdf clicked");

    try {
      // Fetch all token data
      const response = await fetch(host + "/getTalakonaTokenReports1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
      });
      const allTokenData = await response.json();

      // Calculate the total collection
      const totalCollection = allTokenData.reduce(
        (sum, rm) => sum + Number(rm.totalprice),
        0
      );

      // Initialize PDF document
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "a4",
      });

      // Set PDF properties including title
      doc.setProperties({
        title: "Talokona Token Report(Gate No-2)",
      });

      // Add heading with custom font size and centered horizontally
      const headingText = "Talokona Token Report(Gate No-2)";
      const fontSize = 20; // Change font size here
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth =
        (doc.getStringUnitWidth(headingText) * fontSize) /
        doc.internal.scaleFactor;
      const textX = (pageWidth - textWidth) / 2;
      doc.setFontSize(fontSize);
      doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

      // Add subtitle with total collection below the main title
      const subtitleText = `Total Collection Amount: ${totalCollection}`;
      const subtitleFontSize = 16; // Font size for the subtitle
      const subtitleWidth =
        (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
        doc.internal.scaleFactor;
      const subtitleX = (pageWidth - subtitleWidth) / 2;
      doc.setFontSize(subtitleFontSize);
      doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

      // Add table with all token data
      doc.autoTable({
        head: [
          [
            "S No",
            "Ticket Number",
            "Date",
            "Adult",
            "Children",
            "Local Person",
            "Safari Adult",
            "Safari Children",
            "Offline Booking Quantity",
            "Offline Booking Amount",
            "Total Amount",
          ],
        ],
        body: allTokenData.map((rm) => [
          rm.id,
          rm.receiptnumbertwo,
          moment.utc(rm.created_at).format("YYYY-MM-DD HH:mm:ss"),
          rm.adult,
          rm.children,
          rm.localperson,
          rm.safariadult,
          rm.safarichildren,
          rm.offlinebookingquantity,
          rm.offlinebookingamount,
          rm.totalprice,
        ]),
        startY: 50, // Adjust startY to provide space for the subtitle
        styles: { halign: "center" },
      });

      // Save the PDF
      doc.save("talokona_token_report_gate2.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const generatePDF2 = async () => {
    console.log("pdf clicked");

    try {
      // Fetch all token data
      const response = await fetch(host + "/getPapavinasanamTokenSummary", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date1: startDate, date2: endDate }), // Sending dates in the request body
      });
      const allTokenData1 = await response.json();

      // Calculate the total collection
      const totalCollection = allTokenData1.reduce(
        (sum, summary) => sum + summary.total_sum,
        0
      );
      console.log("totallll", totalCollection);

      // Initialize PDF document
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "a4",
      });

      // Set PDF properties including title
      doc.setProperties({
        title: "Papavinasanam Token Reports",
      });

      // Add heading with custom font size and centered horizontally
      const headingText = "Papavinasanam Token Reports";
      const fontSize = 20; // Change font size here
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth =
        (doc.getStringUnitWidth(headingText) * fontSize) /
        doc.internal.scaleFactor;
      const textX = (pageWidth - textWidth) / 2;
      doc.setFontSize(fontSize);
      doc.text(headingText, textX, 20); // Adjust y-coordinate as needed

      // Add subtitle with total collection below the main title
      const subtitleText = `Total Collection: ${totalCollection}`;
      const subtitleFontSize = 16; // Font size for the subtitle
      const subtitleWidth =
        (doc.getStringUnitWidth(subtitleText) * subtitleFontSize) /
        doc.internal.scaleFactor;
      const subtitleX = (pageWidth - subtitleWidth) / 2;
      doc.setFontSize(subtitleFontSize);
      doc.text(subtitleText, subtitleX, 40); // Adjust y-coordinate as needed

      // Add table with all token data
      doc.autoTable({
        head: [
          [
            "Two Wheelers",
            "Two Wheelers Amount",
            // "Three Wheelers",
            // "Three Wheelers Amount",
            "Four Wheelers",
            "Four Wheelers Amount",
            "Four Wheelers Heavy",
            "Four Wheelers Heavy Amount",
            "Govt Vehicle",
            "Govt Vehicle Amount",
            // "Still Cameras",
            // "Still Cameras Amount",
            // "Video Cameras",
            // "Video Cameras Amount",
            "Total Sum",
            "Year",
            "Month",
            "Day",
          ],
        ],
        body: allTokenData1.map((summary) => [
          summary.two_wheelers,
          summary.two_wheelers_amt,
        //   summary.three_wheelers,
        //   summary.three_wheelers_amt,
          summary.four_wheelers,
          summary.four_wheelers_Amt,
          summary.four_wheeler_heavy,
          summary.four_wheeler_heavy_Amt,
        //   summary.still_camers,
        //   summary.still_camers_amt,
        //   summary.video_camers,
        //   summary.video_camers_amt,
        summary.Govt_vehicle,
        summary.Govt_vehicle_amt,

          summary.total_sum,
          summary.year,
          summary.month,
          summary.day,
        ]),
        startY: 50, // Adjust startY to provide space for the subtitle
        styles: { halign: "center" },
      });

      // Save the PDF
      doc.save("Papavinasanam_Token_Reports.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

 

  return (
    <div>
      <div className="col-lg-2 col">
        <Header />
      </div>
      <div className="container-fluid col-lg-10 col ">
        <div className="col-lg-12 row">
          <div className="col-lg-12"></div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  content_back">
            <div className="row">
              <b className="text-center" style={{ fontSize: "20px" }}>
                Papavinasanam Token Reports
              </b>
            </div>
            <br />
            <div className="row">
              <div style={{ width: "20%" }}>
                {/* <h1 className="text-center" style={{ textAlign: "center" }}>
                  Gate 1
                </h1> */}
                <form className="form-group" onSubmit={getTokenReport}>
                  <div className="d-flex align-items-center">
                    {/* <div className="col-lg-12 mx-2">
                      <label>Gate No 1</label>
                    </div> */}
                    <div className="col-lg-12 mx-2">
                      <label for="selectCheckin_Date">Start Date</label>
                      <input
                        style={{ marginLeft: "10px" }}
                        type="date"
                        id="selectCheckin_Date"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <br />
                    <div className="col-lg-12 mx-2">
                      <label for="selectCheckout_Date">End Date</label>
                      <input
                        style={{ marginLeft: "10px" }}
                        type="date"
                        id="selectCheckout_Date"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <br />
                    <button type="submit" className="btn btn-primary mx-2">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary mx-4"
                      style={{ width: "auto" }}
                      onClick={generatePDF}
                    >
                      Token
                      <br /> Download
                    </button>
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary mx-2"
                      style={{ width: "auto" }}
                      onClick={handleSummaryGate1Click} // Call handleSummaryGate1Click when the button is clicked
                    >
                      View <br />
                      summary
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary mx-1"
                      style={{ width: "auto" }}
                      onClick={generatePDF2} // Call handleSummaryGate1Click when the button is clicked
                    >
                      download <br />
                      summary
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="row" style={{ overflowX: "auto" }}>
              {table1 == true ? (
                <div>
                  <div className="row" style={{ overflowX: "auto" }}>
                    <table className="table table-striped responsive-table">
                      <thead>
                        <tr className="data-row-head">
                          <th>S No</th>
                          <th>Ticket Number</th>
                          <th>Date</th>
                          <th>Vehicle Number</th>
                          <th>Name</th>
                          {/* <th>Number of Persons</th> */}
                          <th>Phone</th>
                          <th>City</th>
                          <th>Two Wheeler</th>
                          {/* <th>Three Wheelers</th> */}
                          <th>Four Wheeler</th>
                          <th>Four Wheeler Heavy</th>
                          <th>Govt_vehicle</th>
                          {/* <th>Still Camers</th>
                          <th>Video Camers</th> */}
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRecords.map((rm, index) => (
                          <tr key={rm.id} className="data-row-data">
                            <td style={{ textAlign: "center" }}>{rm.id}</td>
                            <td style={{ textAlign: "center" }}>
                              {rm.reciept_token}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {/* {moment(rm.created_date).local()
                                .format("YYYY-MM-DD HH:mm:ss")} */}
                                {rm.created_date}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rm.vehicle_no}
                            </td>
                            <td style={{ textAlign: "center" }}>{rm.name}</td>
                            {/* <td style={{ textAlign: "center" }}>
                              {rm.number_of_persons}
                            </td> */}
                            <td style={{ textAlign: "center" }}>{rm.phone}</td>
                            <td style={{ textAlign: "center" }}>{rm.city}</td>
                            <td style={{ textAlign: "center" }}>
                              {rm.two_wheeler}
                            </td>
                            {/* <td style={{ textAlign: "center" }}>
                              {rm.three_wheelers}
                            </td> */}
                            <td style={{ textAlign: "center" }}>
                              {rm.four_wheeler}
                            </td>
                            {/* <td style={{ textAlign: "center" }}>
                              {rm.four_wheeler_Amt}
                            </td> */}
                            <td style={{ textAlign: "center" }}>
                              {rm.four_wheeler_heavy}
                            </td>
                            {/* <td style={{ textAlign: "center" }}>
                              {rm.four_wheeler_heavy_Amt}
                            </td> */}
                            <td style={{ textAlign: "center" }}>
                              {rm.govt_vehicle}
                            </td>
                            {/* <td style={{ textAlign: "center" }}>
                              {rm.bus_truck}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rm.still_camers}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rm.video_camers}
                            </td> */}
                            <td style={{ textAlign: "center" }}>{rm.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <p style={{ direction: "rtl" }}>
                      <strong>Total Collection Amount :</strong>{" "}
                      {totalCollection}
                    </p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link"
                        >
                          Previous
                        </button>
                      </li>
                      <span>{`Page ${currentPage} of ${Math.ceil(
                        tokenData.length / recordsPerPage
                      )}`}</span>
                      <li
                        className={`page-item ${
                          currentPage ===
                          Math.ceil(tokenData.length / recordsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {table2 == true ? (
                <div>
                  <div className="row" style={{ overflowX: "auto" }}>
                    <table className="table table-striped responsive-table">
                      <thead>
                        <tr className="data-row-head">
                          <th>S No</th>
                          <th>Ticket Number</th>
                          <th>Date</th>
                          <th>Adult</th>
                          <th>Children</th>
                          <th>Local Persons</th>
                          <th>Safari Adult</th>
                          <th>Safari Children</th>
                          <th>Offline Booking Quantity</th>
                          <th>Offline Booking Amount'</th>
                          <th>Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRecords.map((rm, index) => (
                          <tr key={rm.id} className="data-row-data">
                            <td style={{ textAlign: "center" }}>{rm.id}</td>
                            <td style={{ textAlign: "center" }}>
                              {rm.receiptnumbertwo}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {moment(rm.created_at).tz("Asia/Kolkata")
                                .format("YYYY-MM-DD HH:mm:ss")}{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>{rm.adult}</td>
                            <td style={{ textAlign: "center" }}>
                              {rm.children}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rm.localperson}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rm.safariadult}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rm.safarichildren}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rm.offlinebookingquantity}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rm.offlinebookingamount}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {rm.totalprice}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <p style={{ direction: "rtl" }}>
                      <strong>Total Collection Amount :</strong>{" "}
                      {totalCollection}
                    </p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link"
                        >
                          Previous
                        </button>
                      </li>
                      <span>{`Page ${currentPage} of ${Math.ceil(
                        tokenData.length / recordsPerPage
                      )}`}</span>
                      <li
                        className={`page-item ${
                          currentPage ===
                          Math.ceil(tokenData.length / recordsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {table3 == true ? (
                <div>
                  <div className="row" style={{ overflowX: "auto" }}>
                    <table className="table table-striped responsive-table">
                      {/* <thead>
                        <tr className="data-row-head">
                          <th>S No</th>
                          <th>two_wheelers</th>
                          <th>two_wheelers_amt</th>
                          <th>three_wheelers</th>
                          <th>three_wheelers_amt</th>
                          <th>LMV</th>
                          <th>lmv_amt</th>
                          <th>HMV</th>
                          <th>HMV_AMT</th>
                          <th>still_camers</th>
                          <th>still_camers_amt</th>
                          <th>video_camers</th>
                          <th>video_camers_amt</th>
                          <th>total_sum</th>
                          <th>date</th>
                        </tr>
                      </thead> */}

                      <thead>
                        <tr>
                          {/* <th>SNO</th> */}
                          <th>Year</th>
                          <th>Month</th>
                          <th>Day</th>
                          <th>Two Wheeler</th>
                          <th>Two Wheeler Amount</th>
                          <th>Four Wheelers</th>
                          <th>Four Wheelers Amount</th>
                          <th>Four Wheeler Heavy</th>
                          <th>Four Wheeler Heavy Amount</th>
                          <th>Govt Vehicle</th>
                          <th>Govt Vehicle Amount</th>
                          {/* <th>Still Cameras</th>
                          <th>Still Cameras Amount</th>
                          <th>Video Cameras</th>
                          <th>Video Cameras Amount</th> */}
                          <th>Total Sum</th>
                          {/* <th>Year</th>
                          <th>Month</th>
                          <th>Day</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {summaryData.map((summary) => (
                          <tr key={summary.Created_date}>
                            {/* <td>{}</td> */}
                            <td>{summary.year}</td>
                            <td>{summary.month}</td>
                            <td>{summary.day}</td>
                            <td>{summary.two_wheelers}</td>
                            <td>{summary.two_wheelers_amt}</td>
                            <td>{summary.four_wheelers}</td>
                            <td>{summary.four_wheelers_Amt}</td>
                            <td>{summary.four_wheeler_heavy}</td>
                            <td>{summary.four_wheeler_heavy_Amt}</td>
                            <td>{summary.Govt_vehicle}</td>
                            <td>{summary.Govt_vehicle_amt}</td>
                            {/* <td>{summary.still_camers}</td>
                            <td>{summary.still_camers_amt}</td>
                            <td>{summary.video_camers}</td>
                            <td>{summary.video_camers_amt}</td> */}
                            <td>{summary.total_sum}</td>
                            {/* <td>{summary.year}</td>
                            <td>{summary.month}</td>
                            <td>{summary.day}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* <p style={{ direction: "rtl" }}>
                      <strong>Total Collection Amount :</strong>{" "}
                      {summaryData.totalCollection}
                    </p> */}
                  </div>
                  <div className="d-flex justify-content-end">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link"
                        >
                          Previous
                        </button>
                      </li>
                      <span>{`Page ${currentPage} of ${Math.ceil(
                        tokenData.length / recordsPerPage
                      )}`}</span>
                      <li
                        className={`page-item ${
                          currentPage ===
                          Math.ceil(tokenData.length / recordsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {table4 == true ? (
                <div>
                  <div className="row" style={{ overflowX: "auto" }}>
                    <table className="table table-striped responsive-table">
                      <thead>
                        <tr className="data-row-head">
                          <th>year</th>
                          <th>month</th>
                          <th>day</th>
                          <th>Adult</th>
                          <th>Adult amount</th>
                          {/* <th>Children</th> */}
                          <th>Local Persons</th>
                          <th>Local Persons amount</th>
                          <th>Safari Adult</th>
                          <th>safari Adult amount</th>
                          <th>Safari Children</th>
                          <th>safari children amount</th>
                          {/* <th>Offline Booking Quantity</th> */}
                          <th>offline booking quantity sum</th>
                          {/* <th>Offline Booking Amount</th> */}
                          <th>offline booking quantitysum</th>
                          <th>Total Amount</th>
                          {/* <th>created_at</th> */}
                          {/* <th>year</th>
                          <th>month</th>
                          <th>day</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {summaryData.map((summary1) => (
                          <tr key={summary1.id} className="data-row-data">
                            {/* <td style={{ textAlign: "center" }}>
                              {moment(summary1.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}{" "}
                            </td> */}
                            <td style={{ textAlign: "center" }}>
                              {summary1.year}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.month}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.day}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.adult}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.adult_amt}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.localperson}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.localperson_amt}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.safariadult}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.safariadult_AMT}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.safarichildren}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.safarichildren_amt}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.offlinebookingquantity_sum}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.offlinebookingamount_sum}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.totalprice_sum}
                            </td>
                            {/* <td style={{ textAlign: "center" }}>
                              {summary1.created_at}
                            </td> */}
                            {/* <td style={{ textAlign: "center" }}>
                              {summary1.year}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.month}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {summary1.day}
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* <p style={{ direction: "rtl" }}>
                      <strong>Total Collection Amount :</strong>{" "}
                      {totalCollection}
                    </p> */}
                  </div>
                  <div className="d-flex justify-content-end">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link"
                        >
                          Previous
                        </button>
                      </li>
                      <span>{`Page ${currentPage} of ${Math.ceil(
                        tokenData.length / recordsPerPage
                      )}`}</span>
                      <li
                        className={`page-item ${
                          currentPage ===
                          Math.ceil(tokenData.length / recordsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PapavinasanamTokenReports;