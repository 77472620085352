import React from "react";
import "./Style.css";
import VisitorCounter from "./VisitorCounter";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div></div>
      <div className="container" style={{ marginTop: "30px" }}>
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div className="col-lg-1O col-md-10 col-sm-10 col-xs-10 content_back footer-bg">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 colxs-12">
                <img
                  src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/logos/logo3.png"
                  className="img-responsive"
                />
                <center>
                  <span>
                    <strong>Forest Dept, Kapila Theertham, Tirupati.</strong>
                  </span>
                </center>
                <div id="Logo1">
                  <img
                    src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/logos/logo1.png"
                    className="img-responsive"
                  />
                </div>
                <div id="Logo2">
                  <img
                    src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/logos/logo2.png"
                    className="img-responsive"
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="list-pages">
                  <ul>
                    <li>
                      <Link to="/Home">Home</Link>
                    </li>

                    <li>
                      <Link to="/Accomodation">Accommodation</Link>
                    </li>
                    <li>
                      <Link to="/Wild-Life">WildLife Gallery</Link>
                    </li>
                    <li>
                      <Link to="/How-to-Reach">How To Reach</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className="list-pages">
                  <ul>
                    <li>
                      <Link to="/talakonawaterfalls">Talakona</Link>
                    </li>
                    <li>
                      <Link to="/mamandurwaterfalls">Mamandur</Link>
                    </li>
                    <li>
                      <Link to="/junglesafari-kalyanidam-pulibonu">
                        Pulibonu
                      </Link>
                    </li>
                    <li>
                      <Link to="/aarewaterfalls">Aare Waterfalls</Link>
                    </li>
                    <li>
                      <Link to="/ubbalamaduguwaterfalls">Ubbalamadugu</Link>
                    </li>
                    <li>
                      <Link to="/junglebook">Jungle Book</Link>
                    </li>
                    <li>
                      <Link to="/papavinasanam">Papavinasanam</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-sm-12 termcond">
                  <Link
                    to="terms-conditions"
                    className="termcond-colors"
                    target="_blank"
                  >
                    <strong>
                      <button>Terms & Conditions</button>
                    </strong>
                  </Link>
                  <span>&nbsp;</span>
                  <Link
                    to="privacy-policy"
                    className="termcond-colors"
                    target="_blank"
                  >
                    <strong>
                      <button>Privacy Policy</button>
                    </strong>
                  </Link>
                  <span>&nbsp;</span>{" "}
                  <Link
                    to="cancellation-policy"
                    className="termcond-colors"
                    target="_blank"
                  >
                    <strong>
                      <button>Cancellation Policy</button>
                    </strong>
                  </Link>
                  <span>&nbsp;</span>{" "}
                  <Link
                    to="general-rules"
                    className="termcond-colors"
                    target="_blank"
                  >
                    <strong>
                      <button>General Rules</button>
                    </strong>
                  </Link>
                </div>
              </div>
            </div>
            <div class="row">
              {/* <div class="col-md-12">
                <div>
                  <p class="pt-5 text-left">
                    <a href="https://www.sssbi.com/">
                      <img
                        src="images/footer_logo.gif"
                        alt=""
                        style={{ width: "80px", height: "80px" }}
                      />
                    </a>
                  </p>
                </div>
                <div>
                  <label>Powered By</label>
                  <a href="https://www.srinivasansoftwaresolutions.com/">
                    <h3>Srinivasan Software Solutions Pvt. Ltd</h3>
                  </a>
                  <p class="online"></p>
                </div>
              </div> */}
              <div class="col-md-12 footer-content">
                <div>
                  <p class="pt-5 text-center">
                    <a href="https://srinivasansoftwaresolutions.com/">
                      <img
                        src="images/footer_logo.gif"
                        alt=""
                        style={{ width: "80px", height: "80px" }}
                      />
                    </a>
                  </p>
                </div>
                <div>
                  <label>Powered By</label>
                  <a
                    href="https://srinivasansoftwaresolutions.com/"
                    style={{ marginLeft: "10px" }}
                  >
                    <h3 style={{ marginLeft: "30px" }}>
                      Srinivasan Software Solutions Pvt. Ltd
                    </h3>
                  </a>
                  <p class="online"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisitorCounter />
    </div>
  );
};

export default Footer;
