import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const StartPage = () => {
  useEffect(() => {
    const redirect = () => {
      window.location.assign("/Home");
    };

    setTimeout(redirect, 3000);
  }, []);

  return (
    <div
      style={{
        backgroundImage: "url('images/bannernew.gif')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", 
        backgroundPosition: "center", 
        height: "100vh", 
        textAlign: "center",
        paddingTop: "0%",
        marginTop: "0%",
      }}
    >
      <style>{`
        body {
          margin: 0;
          padding: 0;
        }
      `}</style>
      <span
        style={{
          fontFamily: "arial",
          fontSize: "50px",
          fontWeight: "bold",
          color: "#ff7800",
        }}
      >
        <br />
        <br />
        WELCOME TO
      </span>
      <br />
      <br />
      <div style={{ textAlign: "right", width: "100%" }}>
       
        <br />
        <br />
        <br />
        <br />
        <div
          style={{
            fontFamily: "arial",
            textAlign: "center",
            width: "100%",
            
            paddingTop: "0%",
            marginTop: "0%",
          }}
        >
          <br />
          <Link to="/Home" style={{ fontSize: "50px", color: "#ff7800" }}>
            www.seshachalavanadarshani.com
          </Link>
          <br />
        </div>
      </div>
    </div>
  );
};

export default StartPage;
