import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.js";

import Home from "./components/Home";
import Footer from "./components/Footer";

import Accomodation from "./components/Accomodation";

import WildLife from "./components/WildLife";

import StartPage from "./components/StartPage";
import TalakonaWaterfalls from "./components/TalakonaWaterFalls";
import MamandurForest from "./components/Mamamdur";
import Papanasanam from "./components/Papanasanam";
import JungleBook from "./components/JungleBook";
import RouteMap from "./components/RouteMap";

import TermsConditions from "./components/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";

import CancellationPolicy from "./components/CancellationPolicy";
import GeneralRules from "./components/GeneralRules";

import Contact from "./components/Contact";

import LoginPage from "./components/Login";
import RegisterPage from "./components/Register";
import Pulibonu from "./components/Pulibonu";
import AareWaterfalls from "./components/AareWaterfalls";
import Ubbalamadugu from "./components/Ubbalamadugu";
import RoomBookingPreview from "./components/RoomBookingPreview";
import BookingSummary from "./components/BookingSummary";

import ForgotPassword from "./components/ForgotPassword";
import ManageRooms from "./components/ManageRooms";
import RoomBlockingPreview from "./components/RoomBlockingPreview";
import MyBookings from "./components/MyBookings";

import Reports from "./components/Reports";
import BookedReports from "./components/BookedReports";
import BlockedReports from "./components/BlockedReports";
import TalakonaTokenReports from "./components/TalokonaTokenReports";

import TalokonaTokenReports from "./components/TalakonaGateone";
import TalakonaGateTwo from "./components/TalakonaGateTwo";
import CancelledReports from "./components/CancelledReports";
import PapavinasanamTokenReports from "./components/PapavinasanamTokenReports";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };
  localStorage.removeItem("nextDates");

  return (
    <Router>
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Reports" element={<Reports />} />
        <Route path="/Booked-Reports" element={<BookedReports />} />
        <Route path="/Blocked-Reports" element={<BlockedReports />} />
        <Route path="/Cancelled-Reports" element={<CancelledReports />} />

        <Route path="/talakonawaterfalls" element={<TalakonaWaterfalls />} />

        <Route path="/manageRooms" element={<ManageRooms />} />
        <Route path="/Accomodation" element={<Accomodation />} />
        <Route path="/RoomBlockingPreview" element={<RoomBlockingPreview />} />

        <Route path="/Wild-Life" element={<WildLife />} />

        <Route path="/mamandurwaterfalls" element={<MamandurForest />} />

        <Route path="/papavinasanam" element={<Papanasanam />} />
        <Route path="/junglebook" element={<JungleBook />} />
        <Route path="/ubbalamaduguwaterfalls" element={<Ubbalamadugu />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/How-to-Reach" element={<RouteMap />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cancellation-policy" element={<CancellationPolicy />} />
        <Route path="/general-rules" element={<GeneralRules />} />

        <Route path="/Contact" element={<Contact />} />

        <Route path="/MyBookings" element={<MyBookings />} />
        <Route
          path="/Talakona-token-reports"
          element={<TalakonaTokenReports />}
        />
        <Route path="/talakona-gate-one" element={<TalokonaTokenReports />} />
        <Route path="/talakona-gate-two" element={<TalakonaGateTwo />} />
        <Route path="/papavinasanam-reports" element={<PapavinasanamTokenReports />} />

        <Route path="/login" element={<LoginPage />} />
        <Route path="/registration" element={<RegisterPage />} />
        <Route
          path="/junglesafari-kalyanidam-pulibonu"
          element={<Pulibonu />}
        />
        <Route path="/aarewaterfalls" element={<AareWaterfalls />} />
        <Route path="/ubbalamaduguwaterfalls" element={<Ubbalamadugu />} />
        <Route path="/roomBookingPreview" element={<RoomBookingPreview />} />
        <Route path="/bookingSummary" element={<BookingSummary />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
