import React from 'react';
import './Style.css';
import Footer from './Footer';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
const RouteMap = () => {
  return (
    <div>
      <Header/>
     
    <div className="container ">
     <div className='row'>
            <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'></div>
          
    <div className="col-lg-1O col-md-10 col-sm-10 col-xs-10 content_back">
<div className="row ">
			<div className="col">
     

		<div className="map ">
    <div> <h1 className="text-center" style={{fontSize:"40px",color:"green"}}>Route Map</h1></div> 
    <h2 className="map-head">Andhra Pradesh Forest Department :</h2>
            <iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6520.490451013481!2d79.41843622167498!3d13.651265659729564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0000000000000000%3A0xf3de961cb55205d8!2sAP+Forest+Department!5e0!3m2!1sen!2sin!4v1456826472588" width="100%" height="450"   allowfullscreen="">
                </iframe><br/>
                <h2 className="map-head">Talakona :</h2>
			<iframe  className="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.4770605123813!2d79.21119722608051!3d13.810368062523343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0000000000000000%3A0x40505ef79b330fa4!2sTalakona+Waterfalls!5e0!3m2!1sen!2sin!4v1462622609393" width="100%" height="450"  allowfullscreen="">
                </iframe><br/>
                <h2 className="map-head">Mamandur :</h2>
			<iframe  className="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.553324552217!2d79.45966286442706!3d13.745471601144441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4d342f8194bc41%3A0x35114eb2fc6bf046!2sMamanduru%2C+Andhra+Pradesh+517619!5e0!3m2!1sen!2sin!4v1462622469514" width="100%" height="450"  allowfullscreen="">
                </iframe><br/>
                <h2 className="map-head">
Pulibonu-Jungle Safari Kalyani Dam :</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.001613013723!2d79.26664647382296!3d13.657665599468558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb2b61535aed07f%3A0x27e948d1b1abde3f!2sKalyani%20Dam!5e0!3m2!1sen!2sus!4v1702901983564!5m2!1sen!2sus" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                <br/>
                <h2 className="map-head">Ubbalamadugu :</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15511.515927929036!2d79.83354131215019!3d13.604202600696178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4d6ea4fdda9ce1%3A0xb64f97329717627e!2sUbbalamadugu%20Waterfalls!5e0!3m2!1sen!2sus!4v1702902133402!5m2!1sen!2sus" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                <br/>
                <h2 className="map-head"> AareWaterfalls :</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15520.281613004154!2d79.75373525541993!3d13.469757699999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4d67bf8161e7d3%3A0xc44f048fe5ecfd3!2sAare%201st%20Waterfall!5e0!3m2!1sen!2sus!4v1702902271871!5m2!1sen!2sus" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  
                <br/>
                <h2 className="map-head">Junglebook :</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.0425266064262!2d79.41591196895598!3d13.655177070214869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4d4af7263ec1ad%3A0xa69a4f24d404ec54!2sJungle%20Book%20%26%20Park!5e0!3m2!1sen!2sus!4v1702902347602!5m2!1sen!2sus" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                <br/>
                <h2 className="map-head">Papavinasanam :</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.976580316325!2d79.34097197382418!3d13.719867698034584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb2cafa57c970e9%3A0x322f5634bcbede70!2sPapavinasanam%20Dam!5e0!3m2!1sen!2sus!4v1702902435044!5m2!1sen!2sus" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                <br/>

                
		</div>
		
		{/* </div> */}
		</div>
		</div>  
        </div>
      
        </div></div>
      </div>
  )
}

export default RouteMap
