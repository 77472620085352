


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

const MonthTab = ({ selectedLocation }) => {
  // let host = "http://localhost:5000";  
  let host = "https://api.vanadarshani.com";
  const [selectedMonth, setSelectedMonth] = useState("");
  const [bookedRooms, setBookedRooms] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [noData, setNoData] = useState("");

  const fetchReportsData = async (month, locationId) => {
    try {
      if (!month) {
        setBookedRooms([]);
        setTotalAmount(0);
        return;
      }

      // Fetch booked rooms data
      const response = await axios.get(`${host}/get-booked-rooms-by-month`, {
        params: {
          month: month,
          location: locationId,
        },
      });

      // Fetch room data
      const roomResponse = await axios.get(`${host}/get-rooms`, {
        params: {
          location: locationId,
        },
      });

      const roomDataMap = {};
      roomResponse.data.forEach(room => {
        roomDataMap[room.room_id] = {
          room_name: room.room_name,
          amount: room.amount,
        };
      });

      // Combine room data with booked rooms data
      const bookedRoomsData = response.data.map(booking => ({
        ...booking,
        room_details: roomDataMap[booking.room_id],
      }));

      setBookedRooms(bookedRoomsData);

      // Calculate total amount
      const total = bookedRoomsData.reduce((acc, booking) => acc + booking.room_details.amount, 0);
      setTotalAmount(total);

      localStorage.setItem('bookedRooms', JSON.stringify(bookedRoomsData));
    } catch (error) {
      console.error(error);
    }
  };

  const handleMonthSelection = (month) => {
    setSelectedMonth(month);
    fetchReportsData(month, selectedLocation);
  };

  useEffect(() => {
    // Initial fetch when the component mounts
    fetchReportsData(selectedMonth, selectedLocation);
  }, [selectedMonth, selectedLocation]);

  return (
    <div>
      <div className="row">
        <div style={{ width: "20%" }}>
          <b>Monthly Reports</b>
        </div>
        <div style={{ width: "30%" }}>
          <input
            type="month"
            value={selectedMonth}
            onChange={(e) => handleMonthSelection(e.target.value)}
          />
        </div>
        <div style={{ width: "50%" }}></div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>SrNo</th>
                  <th>Room Name</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {bookedRooms.map((booking, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{booking.room_details.room_name}</td>
                    <td>{moment(booking.date).format("YYYY-MM-DD")}</td>
                    <td>{booking.room_details.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div>Total Amount: {totalAmount}</div>
        </div>
      </div>
      <div className="col-lg-2"></div>
      <div className="col-lg-8 text-center">{noData}</div>
      <div className="col-lg-2"></div>
    </div>
  );
};

export default MonthTab;
