import React from "react";
import Navbar from "../components/Navbar";
import Header from "./Header";
import { Link } from "react-router-dom";
import JungleBookImages from "./JungleBookImages";

const JungleBook = () => {
  return (
    <div>
      
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div className="col-lg-1O col-md-10 col-sm-10 col-xs-10 content_back">
          <JungleBookImages/>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {/* <div className="links_class">
                  <br />
                  <ul className="nav nav-pills">
                    <li>
                      <Link to="/Talakona">TALAKONA</Link>
                    </li>
                    <li>
                      <Link to="/Mamumdur">MAMUNDUR FOREST</Link>
                    </li>
                    <li>
                      <Link to="/Papanasanam">PAPANASANAM</Link>
                    </li>
                    <li className="active">
                      <Link to="/Junglebook">JUNGLE BOOK IN TIRUPATI</Link>
                    </li>
                  </ul>
                </div> */}
                <div className="row1">
                  <br />
                  <h1
                    className="text-center"
                    style={{ fontSize: "30px", color: "green" }}
                  >
                    JUNGLE BOOK IN TIRUPATI
                  </h1>
                  <br />
                  <div className="AttractionItems">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="row">
                        <p>
                          Jungle book is natural forest area made into a secured
                          park by forest department in Tirupati. To make people
                          closer to benefits by having direct contact with
                          nature. Jungle book is exactly present at Kapil
                          tertham in Tirupati and can be reached easily.
                        </p>

                        <h4>ATTRACTIONS:</h4>
                        <p>
                          Peacock house, Tree Top Trail, Naturest, Deer crawl,
                          Insectorium, Rock thrills, Mineral kingdom,
                          Malwadigundam, Birds eye view, Birds paradise, Kids
                          corner, Bamboo tunnel, Boating etc.,
                        </p>
                        <h4>ENTRY FEE:</h4>
                        <p>
                          Rs.3/- per person; Boating charges: Rs.10/- per
                          person.
                        </p>
                        <br />
                        {/* <img
                          src="https://vanadarshini-images.s3.ap-south-1.amazonaws.com/jungle-book/jungle-book-attraction-for-tourists-in-tirupathi.jpg"
                          className="img-responsive img-thumbnail"
                          width="80%"
                          alt="Jungle Book Attraction"
                        /> */}
                        <br />
                        <br />
                        
                      </div>
                    </div>
                    <div className="clearfix">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JungleBook;
